import { Point } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { FC, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { GeometryType } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { MeasureCoordinateDef, measureCoordinateStyleFn } from './MeasureCoordinate'

type DrawMeasureCoordinateInteractionProps = {
  onMeasureCoordinateCreated: (newMeasureCoordinate: MeasureCoordinateDef) => void
}

export const DrawMeasureCoordinateInteraction: FC<DrawMeasureCoordinateInteractionProps> = ({
  onMeasureCoordinateCreated,
}) => {
  const { kmapInstance: kmap } = useMapContext()
  const [isMounted, setIsMounted] = useState(false)
  const [id] = useState(v4())

  const drawInteraction = useMemo(() => {
    if (!isMounted) return null
    const interaction = new Draw({
      type: GeometryType.POINT,
      maxPoints: 1,
      stopClick: true,
      style: measureCoordinateStyleFn(kmap),
    })

    kmap.enableSnap(true)

    interaction.on('drawend', (e: DrawEvent) => {
      const coord = (e.feature.getGeometry() as Point).getCoordinates()
      onMeasureCoordinateCreated({ id, point: coord, type: 'coordinate' })
      stop()
    })
    return interaction
  }, [kmap, isMounted])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    return () => {
      stop()
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [isMounted])

  const stop = () => {
    if (kmap && drawInteraction) {
      drawInteraction.abortDrawing()
      kmap.removeInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'auto'
    }
  }

  return null
}

export default DrawMeasureCoordinateInteraction
