import React, { MutableRefObject } from 'react'

import { makeStyles } from '@mui/styles'
import { GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { isFunction } from 'lodash'

import { OptionValuePropType } from '../../autocomplete/AutoCompleteSelectBase'
import AutoCompleteSelectWithGroup, {
  AutoCompleteSelectWithGroupValueType,
} from '../../autocomplete/AutoCompleteSelectWithGroup'
import AutocompleteComponent from '../../autocomplete/Autocomplete'
import { CustomEditComponentProps, isValueInValid, setEditCellValue } from '../Utils'
import { AsyncSelectOption, AutocompleteAsync, AutocompleteAsyncProps } from '../../autocomplete/AutocompleteAsync'
import { InlineDataGridSelectOption } from './InlineDataGridSelectOption'

const useStyles = makeStyles(() => ({
  selectStyle: {
    height: '100%',
    width: '100%',
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '& > div': {
      '& > div': {
        '& > fieldset': {
          border: 'none',
        },
      },
    },
  },
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: 0,
  },
}))

export type InlineDataGridSelectOptionType<T extends OptionValuePropType> =
  | {
      options: (input: string) => Promise<AsyncSelectOption[]>
      optionById: (id: string | number) => Promise<AsyncSelectOption>
      group?: false
    }
  | {
      options: AsyncSelectOption[]
      optionById?: null
      group?: false
    }
  | {
      options: AutoCompleteSelectWithGroupValueType<T>[]
      optionById?: null
      group: true
      getOptionsFromServer?: (value: string) => void
    }

export type InlineDataGridSelectProps<
  Row extends GridValidRowModel = GridValidRowModel,
  ValueType extends OptionValuePropType = string
> = CustomEditComponentProps<Row> & {
  selectOptionConfig: InlineDataGridSelectOptionType<ValueType>
  isZeroInvalid?: boolean
  readOnly?: AutocompleteAsyncProps['readOnly']
  startAdornment?: AutocompleteAsyncProps['startAdornment']
  renderOption?: AutocompleteAsyncProps['renderOption']
  overrideOnChange?: (
    apiRef: MutableRefObject<GridApiPro>,
    newValue: unknown,
    id: number | string,
    field: string
  ) => void
}

export const InlineDataGridSelect = ({
  selectOptionConfig,
  props,
  autoFocus,
  required,
  readOnly,
  isZeroInvalid,
  disabled,
  overrideOnChange,
  startAdornment,
  renderOption = (props, option) => (
    <InlineDataGridSelectOption
      {...props}
      key={`item-${option.value}`}
      itemId={option.value}
      itemLabel={option.label}
    />
  ),
}: InlineDataGridSelectProps) => {
  const apiRef = useGridApiContext()

  const classes = useStyles()

  const handleValueChange = async (newValue: AsyncSelectOption | null) => {
    if (overrideOnChange) overrideOnChange(apiRef, newValue?.value, props.id, props.field)
    else setEditCellValue(apiRef, props.id, props.field, newValue?.value)
  }

  if (selectOptionConfig.group) {
    return (
      <AutoCompleteSelectWithGroup
        value={props.value}
        values={selectOptionConfig.options}
        handleChange={(_e, value) => handleValueChange({ value } as AsyncSelectOption)}
        getOptionsFromServer={selectOptionConfig.getOptionsFromServer}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        variant="outlined"
        error={(required && isValueInValid(props.value, isZeroInvalid)) || props.error}
        styles={{
          root: classes.selectStyle,
        }}
      />
    )
  } else if (isFunction(selectOptionConfig.options)) {
    return (
      <AutocompleteAsync
        fetchOptions={selectOptionConfig.options}
        fetchOptionById={selectOptionConfig.optionById!}
        autoFocus={autoFocus}
        variant="outlined"
        readOnly={readOnly}
        onChange={handleValueChange}
        disabled={disabled}
        required={required}
        value={props.value}
        styles={{
          root: classes.selectStyle,
        }}
        error={(required && isValueInValid(props.value, isZeroInvalid)) || props.error}
        renderOption={renderOption}
        startAdornment={startAdornment}
      />
    )
  } else {
    return (
      <AutocompleteComponent
        autoFocus={autoFocus}
        displayField={'label'}
        readOnly={readOnly}
        errorMessage={(required && isValueInValid(props.value, isZeroInvalid)) || props.error ? 'Invalid' : undefined}
        isLoading={false}
        items={selectOptionConfig.options as AsyncSelectOption[]}
        name={props.field}
        renderOption={renderOption}
        onChange={handleValueChange}
        startAdornment={startAdornment}
        required={required}
        filterOptions={(options, state) => options.filter((opt) => getIfIncludedInFilter(opt, state.inputValue))}
        selectedItem={props.value}
        showErrorMessage={false}
        isDisabled={disabled}
        valueField={'value'}
        variant="outlined"
        styles={{
          root: classes.selectStyle,
        }}
      />
    )
  }
}

const getIfIncludedInFilter = (option: AsyncSelectOption, input: string) => {
  const optionLabelLower = option.label.toLowerCase()
  const optionValueLower = option.value.toString().toLowerCase()
  const inputToLower = input.toLowerCase()

  return optionLabelLower.includes(inputToLower) || optionValueLower.includes(inputToLower)
}
