import { Coordinate } from 'ol/coordinate'
import { EventsKey } from 'ol/events'
import Feature from 'ol/Feature'
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { fromLonLat, toLonLat } from 'ol/proj'
import { getLength } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo, useState } from 'react'

import { assertIsStyle, Collection, ILonLat, labelStyleLight } from '@griegconnect/krakentools-kmap'

import { useMooringContext } from '../MooringPlanContext'
import { formatDistanceInMeters } from '../utils/formatDistanceInMeters'
import { midPoint } from '../utils/geometry'
import { selectedMooringLineStyle } from './DrawMooringLineInteraction'

type MooringLineProps = {
  from: ILonLat
  to: ILonLat
  vesselId: string | number
  selected: boolean
  id: string
  editable?: boolean
  onChange: (from: ILonLat, to: number) => void
}

const mooringLineStyle = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
})

const mooringLineDisabledStyle = new Style({
  stroke: new Stroke({ color: 'rgba(0,0,0,0.38)', width: 4 }),
})

const mooringLineAdornmentStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
  geometry: (feature) => {
    const coords = (feature.getGeometry() as LineString)?.getCoordinates?.()
    return coords ? new MultiPoint(coords) : undefined
  },
  zIndex: 2000,
})

const MooringLine: FC<React.PropsWithChildren<MooringLineProps>> = ({
  from,
  to,
  vesselId,
  selected,
  id,
  editable,
  onChange,
}) => {
  const { mooringLayer, kmapInstance } = useMooringContext()
  const originalGeometry = new LineString([fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])])
  const getStyle = () => (editable ? [mooringLineAdornmentStyle, mooringLineStyle] : mooringLineDisabledStyle)
  const [changeEventKey, setChangeEventKey] = useState<EventsKey | undefined>()
  const [modifyEndEventKey, setModifyEndEventKey] = useState<EventsKey | undefined>()

  const feature = useMemo(() => {
    const line = new LineString([fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])])
    const lineFeature = new Feature({
      geometry: line,
    })
    lineFeature.set('mooringLineForVessel', vesselId)
    lineFeature.set('id', id)
    lineFeature.set('type', 'mooringLine')
    lineFeature.set('selectable', !!editable)
    lineFeature.setStyle(getStyle())
    mooringLayer.getSource()?.addFeature(lineFeature)
    return lineFeature
  }, [])

  const labelFeature = useMemo(() => {
    const label = new Feature({
      geometry: new Point(fromLonLat(midPoint([from.lon, from.lat], [to.lon, to.lat]))),
    })
    label.set('mooringLineForVessel', vesselId)
    label.set('id', id)
    const labelStyle = labelStyleLight.clone()
    labelStyle.setZIndex(200)
    label.setStyle(labelStyle)
    mooringLayer.getSource()?.addFeature(label)
    return label
  }, [])

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = feature.getGeometry() as LineString
    const geom = labelFeature.getGeometry()
    if (geom instanceof Point) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('labelFeature geometry is not a Point')
    }
    const style = assertIsStyle(labelFeature.getStyle())
    style.getText()?.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
  }

  useEffect(() => {
    const lineStringGeometry = feature.getGeometry() as LineString
    const coords = [fromLonLat([from.lon, from.lat]), fromLonLat([to.lon, to.lat])]
    lineStringGeometry.setCoordinates(coords)
    updateLabel(coords[0], coords[1])
  }, [from, to])

  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.resetSnapCollection()
    kmapInstance.addToSnapCollection(feature)
    mooringLayer.setZIndex(3000)
    const f = mooringLayer.getSource()?.getFeatureById(vesselId)
    if (f && f instanceof Feature) {
      const vesselCoords = (f.getGeometry() as Polygon)?.getCoordinates()[0]
      let snappedToVertexIndex: number | false = false
      setChangeEventKey(
        feature.getGeometry()?.on('change', (event) => {
          const lineStringGeometry = event.target as LineString
          const coords = lineStringGeometry.getCoordinates()
          const snapPointCursor = coords[coords.length - 1]

          vesselCoords?.forEach((vertex, index) => {
            if (Math.abs(vertex[0] - snapPointCursor[0]) < 0.1 && Math.abs(vertex[1] - snapPointCursor[1]) < 0.1) {
              snappedToVertexIndex = index
              originalGeometry.setCoordinates(coords)
            }
          })

          updateLabel(coords[0], coords[1])
        })
      )

      setModifyEndEventKey(
        modifyInteraction.on('modifyend', () => {
          if (snappedToVertexIndex) {
            const geom = feature.getGeometry()
            if (geom) {
              const first = toLonLat(geom.getFirstCoordinate())
              onChange({ lon: first[0], lat: first[1] }, snappedToVertexIndex)
            }
          } else {
            feature.getGeometry()?.setCoordinates(originalGeometry.getCoordinates())
          }
        })
      )
      feature.setStyle([selectedMooringLineStyle, mooringLineAdornmentStyle])
      kmapInstance.addInteraction(modifyInteraction)
    } else {
      console.warn('MooringLine: vessel feature not found, or not a Feature')
    }
  }

  const disableEdit = () => {
    feature.setStyle(getStyle())
    kmapInstance.removeInteraction(modifyInteraction)
    kmapInstance.removeFromSnapCollection(feature)
    mooringLayer.setZIndex(100)
    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([feature]),
      insertVertexCondition: () => false,
      style: selectedMooringLineStyle,
    })
    return modify
  }, [])

  useEffect(() => {
    if (modifyInteraction && editable) {
      if (!selected) {
        disableEdit()
      } else {
        enableEdit()
      }
    }
  }, [selected, modifyInteraction, kmapInstance])

  useEffect(
    () => () => {
      disableEdit()
      mooringLayer.getSource()?.removeFeature(feature)
      mooringLayer.getSource()?.removeFeature(labelFeature)
      if (modifyInteraction) {
        kmapInstance.removeInteraction(modifyInteraction)
      }
    },
    []
  )

  return null
}

export default MooringLine
