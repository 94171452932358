import { unByKey } from 'ol/Observable'
import { EventsKey } from 'ol/events'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useMapContext } from './MapContext'

export const DeferredMapComponents = (props: PropsWithChildren) => {
  const { kmapInstance } = useMapContext()
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    let eventKey: EventsKey
    if (kmapInstance) {
      eventKey = kmapInstance.getOlMap().once('rendercomplete', () => {
        if (!isReady) {
          setIsReady(true)
        }
      })
    }
    return () => {
      if (kmapInstance && eventKey) {
        unByKey(eventKey)
      }
    }
  }, [kmapInstance, isReady])

  return isReady ? <>{props.children}</> : null
}
