import { useApiServices } from '../../setup/api/ApiServiceProvider'
import { isAxiosError } from '../utils'

export const useMooringPlanServices = () => {
  const { mooringPlanApi } = useApiServices()

  const getMooringPlan = async (mooringPlanId: string, abortSignal?: AbortSignal) => {
    const result = await mooringPlanApi.getMooringPlan(mooringPlanId, abortSignal)
    if (isAxiosError(result)) return false

    return result
  }

  return { getMooringPlan }
}
