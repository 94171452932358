import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import { Box, List, ListItem, Slider, Typography, ListItemSecondaryAction } from '@mui/material'
import clsx from 'clsx'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { useRecoilState } from 'recoil'
import { useMapContext } from '../../MapContext'
import { enableLiveViewAtom, vesselOpacityAtom } from '../../atoms/liveViewAtoms'
import { LayerControlCategory } from './LayerControlCategory'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingTop: theme.spacing(1),
    },
    listHeader: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    listIcon: {
      minWidth: theme.spacing(3.5),
    },
    title: {
      paddingLeft: theme.spacing(3.5),
    },
    text: {
      opacity: 0.6,
    },
    sliderTitle: {
      paddingLeft: theme.spacing(1.5),
    },
    check: {
      padding: theme.spacing(0.5),
      marginBottom: 28,
    },
    slider: { marginRight: theme.spacing(1), marginTop: theme.spacing(3.5) },
    actions: {
      right: theme.spacing(1),
    },
  })
)

export type LiveLayerControlProps = {}

export const LiveLayerControl: React.FC<React.PropsWithChildren<LiveLayerControlProps>> = (props) => {
  const classes = useStyles()
  const { mapIdentifierSlug: slug } = useMapContext()
  const [vesselOpacity, setVesselOpacity] = useRecoilState(vesselOpacityAtom(slug))
  const [enabled, setEnabled] = useRecoilState(enableLiveViewAtom(slug))
  const onCheckHandler = () => {
    setEnabled((state) => {
      return !state
    })
  }

  return (
    <LayerControlCategory title="Live vessels">
      <List disablePadding={true} component="div" className={classes.list}>
        <ListItem style={{ paddingBottom: 0, paddingTop: 0, height: 60 }}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              color: 'textPrimary',
              component: 'div',
            }}
            primary={
              <Box>
                <Typography className={clsx(classes.text, classes.sliderTitle)}>Transparency</Typography>
                <div style={{ height: 33 }}>
                  {enabled && (
                    <Slider
                      color="primary"
                      size="small"
                      min={0}
                      max={100}
                      value={vesselOpacity * 100}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => {
                        return Number(value).toFixed(0)
                      }}
                      onChange={(evt, val) => setVesselOpacity(Number((Number(val) / 100).toFixed(2)))}
                    />
                  )}
                </div>
              </Box>
            }
          />
          <ListItemSecondaryAction className={classes.actions}>
            <Checkbox checked={enabled} value={''} onChange={onCheckHandler} className={classes.check} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </LayerControlCategory>
  )
}
