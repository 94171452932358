import { createTheme, ThemeOptions, Theme, Palette } from '@mui/material/styles'
import type { SimplePaletteColorOptions, PaletteMode } from '@mui/material'
import LightTheme from './light/theme'
import DarkTheme from './dark/theme'
import LinkColorLightTheme from './light/colors/link-color'
import LinkColorDarkTheme from './dark/colors/link-color'
import { alpha } from '@mui/material/styles'

export const Tools = [ 'kraken', 'explore', 'plan', 'port', 'predict', 'security', 'terminal', 'traffic' ] as const

export type Tool = typeof Tools[number]

export type ToolColorOptions = {
  [tool in Tool]: SimplePaletteColorOptions
}

declare module '@mui/material/styles/components' {
  interface Components {
    // When we include @mui/x-data-grid-pro we can use type defs from
    // import type {} from '@mui/x-data-grid-pro/themeAugmentation';
    // https://mui.com/x/react-data-grid/getting-started/#typescript
    MuiDataGrid?: {
      defaultProps?: {
        headerHeight?: number
      }
      styleOverrides?: {
        root?: {
          border?: string
          padding?: string
        }
        columnSeparator?: {
          display?: string
          color?: string
        }
        cell: {
          borderBottom: string
        }
        columnHeaders?: {
          borderBottom?: string
          paddingBottom?: number
          '.MuiDataGrid-withBorder'?: {
            borderRight?: string
          }
        }
      }
    }
  }
}

declare module '@mui/material' {
  interface SimplePaletteColorOptions {
    /**
     * Only used in MuiSnackbarContent
     * Snackbars is dark in light mode, and light in dark mode.
     * We need better contrast for the buttons
     *
     * @type {string}
     * @memberof SimplePaletteColorOptions
     */
    contrastPrimary?: string
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    palette: Palette & {
      link?: {
        main: string
      }
      text: {
        primary: string
        secondary: string
        disabled: string
        hint?: string
      }
    }
    kraken?: {
      tools: ToolColorOptions
    }
  }

  interface ThemeOptions {
    kraken?: {
      tools: ToolColorOptions
    }
  }
}

type ThemeCacheKey = `${Tool}-${PaletteMode}`
const themeCache: Partial<Record<ThemeCacheKey, Theme>> = {}

export default function createKrakenTheme(tool: Tool, paletteType: PaletteMode, cacheKeyPrefix: string = ''): Theme {
  let themeCacheKey = `${cacheKeyPrefix}${tool}-${paletteType}` as const
  let theme = themeCache[themeCacheKey]
  if (theme) {
    return theme
  }

  let paletteTypeOptions: ThemeOptions
  let linkColor: string
  let textHintColor: string
  let snackbarInvertedTextPrimaryColor: string
  let snackbarInvertedBorderColor: string
  let snackbarInvertedBorderHoverColor: string
  switch (paletteType) {
    case 'dark':
      paletteTypeOptions = DarkTheme
      linkColor = LinkColorDarkTheme.main
      textHintColor = 'rgba(255, 255, 255, 0.5)'
      snackbarInvertedBorderColor = 'rgba(0, 0, 0, 0.16)'
      snackbarInvertedBorderHoverColor = 'rgba(0, 0, 0, 0.5)'
      snackbarInvertedTextPrimaryColor = LightTheme.palette!.text!.primary!
      break
    case 'light':
      paletteTypeOptions = LightTheme
      linkColor = LinkColorLightTheme.main
      textHintColor = 'rgba(0, 0, 0, 0.5)'
      snackbarInvertedBorderColor = 'rgba(255, 255, 255, 0.16)'
      snackbarInvertedBorderHoverColor = 'rgba(255, 255, 255, 0.5)'
      snackbarInvertedTextPrimaryColor = DarkTheme.palette!.text!.primary!

      break
    default:
      throw new Error('Unknown palette type')
  }

  let toolOptions = {
    palette: {
      primary: paletteTypeOptions.kraken?.tools?.[tool],
      secondary: paletteTypeOptions.kraken?.tools?.[tool],
      link: {
        main: linkColor,
      },
      text: {
        hint: textHintColor,
      },
    } as Partial<ThemeOptions>,
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            '&:hover:not($disabled):not($focused):not($error)': {
              backgroundColor: paletteTypeOptions.kraken?.tools?.[tool].dark,
            },
          },
          containedSecondary: {
            '&:hover:not($disabled):not($focused):not($error)': {
              backgroundColor: paletteTypeOptions.kraken?.tools?.[tool].dark,
            },
          },
        },
      },
      // IMPORTANT:
      // MuiSnackbar is dark in light mode, and light in dark mode. We need to tweak Buttons used inside MuiSnackbarContent
      MuiSnackbarContent: {
        styleOverrides: {
          action: {
            '& .MuiButton-outlinedInherit': {
              color: snackbarInvertedTextPrimaryColor + '!important',
              borderColor: snackbarInvertedBorderColor,
              '&:hover': {
                borderColor: snackbarInvertedBorderHoverColor,
              }
            },
            '& .MuiButton-outlinedPrimary': {
              color: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary,
              borderColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.8)
                : undefined,

              '&:hover': {
                borderColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary,
                backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                  ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.1)
                  : undefined,
              },
            },
            '& .MuiButton-containedPrimary': {
              color: paletteTypeOptions.palette?.text?.primary,
              backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary,
              '&:hover': {
                backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                  ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.8)
                  : undefined,
              },
              '&:selected': {
                backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                  ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.84)
                  : undefined,
              },
              '&:disabled': {
                backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                  ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.7)
                  : undefined,
              },
              '&:disabledBackground': {
                backgroundColor: paletteTypeOptions.kraken?.tools?.[tool]?.contrastPrimary
                  ? alpha(paletteTypeOptions.kraken?.tools?.[tool].contrastPrimary!, 0.88)
                  : undefined,
              },
            },
            '& .MuiIconButton-colorPrimary': {
              color: paletteTypeOptions.kraken?.tools?.[tool]?.contrastText,
            },
          },
        },
      },
    },
  }

  return (themeCache[themeCacheKey] = createTheme(paletteTypeOptions, toolOptions))
}
