import { Application } from '@griegconnect/krakentools-react-tenants'
import { ApplicationRef } from '../ApplicationOptionsContext'
import { useConfig } from '../hooks/useConfig'
import { useUserInterface } from '../hooks/useUserInterface'
import { getApplicationLogo } from '../utils/getApplicationLogo'

type AppSwitcherGridItemProps = Pick<Application.Data, 'designBundle' | 'title' | 'applicationUrl' | 'description'>

const AppSwitcherGridItem = (props: AppSwitcherGridItemProps) => {
  const { palette } = useUserInterface()
  const { cdn } = useConfig()
  return (
    <a
      href={props.applicationUrl}
      style={{
        textDecoration: 'none',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: 1,
        marginBottom: 6,
      }}
      title={props.description}
    >
      <img
        src={`${getApplicationLogo(
          (props.designBundle as unknown) as ApplicationRef,
          palette,
          'url',
          cdn.designHostUrl
        )}`}
        height={48}
        width={48}
        style={{ marginBottom: 4, marginLeft: 4, marginRight: 4 }}
      />{' '}
      <span
        style={{
          color: 'gray',
          fontSize: 11,
          height: 16,
          whiteSpace: 'nowrap',
          width: 72,
          overflow: 'hidden',
          display: 'inline-block',
          textOverflow: 'ellipsis',
        }}
      >
        {props.title}
      </span>
    </a>
  )
}

export default AppSwitcherGridItem
