import { SnackbarKey, useSnackbar as useNotistackHook, SharedProps } from 'notistack'
import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { globalAlertAtom, GlobalAlertTypes } from '../atoms/globalInfoAtom'
import ErrorAlert from '../ui/alerts/ErrorAlert'
import InfoAlert from '../ui/alerts/InfoAlert'
import SuccessAlert from '../ui/alerts/SuccessAlert'
import WarningAlert from '../ui/alerts/WarningAlert'

export type AlertTypes = 'info' | 'error' | 'warning' | 'success'
export type AlertMessage = string | React.ReactNode

type EnqueueOptions = { key?: SnackbarKey; autoHideDuration?: SharedProps['autoHideDuration'] }

type UseAlerts = {
  setGlobal: (message: string, type: GlobalAlertTypes, closeable: boolean, action?: React.ReactNode) => void
  closeGlobal: () => void
  enqueue: (message: AlertMessage, type: AlertTypes, options?: EnqueueOptions) => SnackbarKey
  close: (key?: SnackbarKey) => void
}

export const useAlerts = (): UseAlerts => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackHook()
  const setGlobalAlert = useSetRecoilState(globalAlertAtom)

  const closeGlobalHandler = useCallback(() => {
    setGlobalAlert(null)
  }, [setGlobalAlert])

  const setGlobalHandler = useCallback(
    (message: string, type: GlobalAlertTypes, closeable: boolean, action?: React.ReactNode) => {
      setGlobalAlert({ message: message, type: type, closeable: closeable, action })
    },
    [setGlobalAlert]
  )

  const enqueueHandler = useCallback(
    (message: AlertMessage, alert: AlertTypes, options?: EnqueueOptions): SnackbarKey => {
      switch (alert) {
        case 'info':
          return enqueueSnackbar(message, {
            ...options,
            content: (key) => <InfoAlert message={message} alertKey={key} />,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          })
        case 'error':
          return enqueueSnackbar(message, {
            ...options,
            content: (key) => <ErrorAlert message={message} alertKey={key} />,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          })
        case 'warning':
          return enqueueSnackbar(message, {
            ...options,
            content: (key) => <WarningAlert message={message} alertKey={key} />,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          })
        case 'success':
          return enqueueSnackbar(message, {
            ...options,
            content: (key) => <SuccessAlert message={message} alertKey={key} />,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          })
        default:
          throw new Error('Invalid alert type provided in useAlerts.')
      }
    },
    [enqueueSnackbar]
  )

  const closeHandler = useCallback(
    (key?: SnackbarKey) => {
      closeSnackbar(key)
    },
    [closeSnackbar]
  )

  return {
    enqueue: enqueueHandler,
    close: closeHandler,
    setGlobal: setGlobalHandler,
    closeGlobal: closeGlobalHandler,
  }
}
