import { FC, useState } from 'react'

import {
  Close as CloseIcon,
  MapPin as MapPinIcon,
  Measure as MeasureIcon,
  MeasureAngle as MeasureAngleIcon,
  MeasureArea as MeasureAreaIcon,
  MeasureLine as MeasureLineIcon,
} from '@griegconnect/krakentools-react-icons'
import { Box, Button, IconButton, Paper, Typography, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { MeasureProvider } from '../MeasuringContext'
import { MapPopperButton } from '../shared/MapPopperButton'
import DrawMeasureAngleInteraction from '../shared/Measure/DrawMeasureAngleInteraction'
import DrawMeasureAreaInteraction from '../shared/Measure/DrawMeasureAreaInteraction'
import DrawMeasureCoordinateInteraction from '../shared/Measure/DrawMeasureCoordinateInteraction'
import DrawMeasureLineInteraction from '../shared/Measure/DrawMeasureLineInteraction'
import MeasureAngle, { MeasureAngleDef } from '../shared/Measure/MeasureAngle'
import MeasureArea, { MeasureAreaDef } from '../shared/Measure/MeasureArea'
import MeasureCoordinate, { MeasureCoordinateDef } from '../shared/Measure/MeasureCoordinate'
import MeasureLine, { MeasureLineDef } from '../shared/Measure/MeasureLine'
import { SnackbarKey, useSnackbar } from 'notistack'
import { useHotkeys } from 'react-hotkeys-hook'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'
import { MapTranslucentPaper } from '../shared/MapTranslucentPaper'

type MapButtonPanelRegion = 'bottom' | 'left' | 'top' | 'right'
const useStyles = makeStyles((theme: Theme) => ({
  measureSnackbar: {
    marginTop: '70px',
  },
  mobilePaper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    bottom: 0,
    left: 0,
    height: '50vh',
    width: '100vw',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
  },
  small: {
    left: '50%',
    right: 'auto',
    position: 'absolute',
    transform: 'translateX(-50%) !important',
    maxWidth: 'calc(100vw - 96px)',
  },
  clearSnackBar: {
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 280,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  wideButton: {
    width: '100%',
    justifyContent: 'flex-start',
    '& > MuiSvgIcon-root': {
      marginLeft: 0,
    },
  },
  bottomBox: {
    width: '300px',
    left: 'calc(50vw - 211px)',
    pointerEvents: 'none',
  },
  bottomBoxPaper: {
    padding: theme.spacing(2),
    pointerEvents: 'all',
  },
}))

export type MeasureObject = MeasureLineDef | MeasureAreaDef | MeasureAngleDef | MeasureCoordinateDef
type MeasureTool = 'measureLength' | 'measureArea' | 'measureAngle' | 'measureCoordinate'

export const MeasureToolControl: FC<{ region?: MapButtonPanelRegion }> = ({ region }) => {
  const classes = useStyles()
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey>()
  const [open, setOpen] = useState(false)
  const [drawMode, setDrawMode] = useState<MeasureTool | null>(null)
  const [measureObjects, setMeasureObjects] = useState<MeasureObject[]>([])
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const theme = useTheme()
  const mqSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useHotkeys(
    'delete',
    (event: KeyboardEvent) => {
      if (selectedId) {
        event.preventDefault()
        const newMeasureObjects = measureObjects.filter((m) => m.id !== selectedId)
        setMeasureObjects(newMeasureObjects)
        if (newMeasureObjects.length === 0) {
          closeSnackbar(snackbarKey)
        }
      }
    },
    [selectedId]
  )

  const handleStartMeasurement = (measureWhat: MeasureTool) => () => {
    setDrawMode(measureWhat)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleStop = () => {
    setMeasureObjects([])
    closeSnackbar()
  }

  const handleAddMeasureObject = (object: MeasureObject) => {
    setDrawMode(null)
    const newObjects = [...measureObjects, object]
    setMeasureObjects(newObjects)
    if (measureObjects.length === 0) {
      closeSnackbar(snackbarKey)
      const key = enqueueSnackbar('Measurements', {
        className: clsx(classes.measureSnackbar, mqSmall ? classes.small : ''),
        autoHideDuration: null,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        action: () => (
          <Button
            variant="outlined"
            size="small"
            onClick={handleStop}
            sx={{
              color: theme.palette.mode === 'light' ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.87)',
              borderColor: theme.palette.mode === 'light' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)',
            }}
          >
            Clear
          </Button>
        ),
      })
      setSnackbarKey(key)
    }
  }

  const renderDesktop = () => (
    <MapTranslucentPaper className={classes.paper} styleVariant="static">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
        id="foo"
      >
        <Typography variant="h6">Measure</Typography>
        <IconButton onClick={handleToggle} className={classes.closeButton} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box overflow="auto">{renderActions()}</Box>
    </MapTranslucentPaper>
  )
  const renderDrawMode = () => {
    switch (drawMode) {
      case 'measureLength':
        return <DrawMeasureLineInteraction onMeasureLineCreated={handleAddMeasureObject} />
      case 'measureArea':
        return <DrawMeasureAreaInteraction onMeasureAreaCreated={handleAddMeasureObject} />
      case 'measureAngle':
        return <DrawMeasureAngleInteraction onMeasureAngleCreated={handleAddMeasureObject} />
      case 'measureCoordinate':
        return <DrawMeasureCoordinateInteraction onMeasureCoordinateCreated={handleAddMeasureObject} />
      default:
        break
    }
  }
  const renderActions = () => (
    <>
      <Button
        className={classes.wideButton}
        onClick={handleStartMeasurement('measureLength')}
        startIcon={<MeasureLineIcon />}
      >
        Length
      </Button>
      <Button
        className={classes.wideButton}
        onClick={handleStartMeasurement('measureArea')}
        startIcon={<MeasureAreaIcon />}
      >
        Area
      </Button>
      <Button
        className={classes.wideButton}
        onClick={handleStartMeasurement('measureAngle')}
        startIcon={<MeasureAngleIcon />}
      >
        Angle
      </Button>
      <Button
        className={classes.wideButton}
        onClick={handleStartMeasurement('measureCoordinate')}
        startIcon={<MapPinIcon />}
      >
        Find Coordinate
      </Button>
    </>
  )

  const handleChange = (id: string) => (updatedArea: MeasureObject) => {
    const objects = [...measureObjects].filter((o) => o.id !== id)
    objects.push(updatedArea)
    setMeasureObjects(objects)
  }

  const renderMeasureObjects = () => {
    return measureObjects.map((obj) => {
      switch (obj.type) {
        case 'distance':
          return (
            <MeasureLine key={obj.id} line={obj} onChange={handleChange(obj.id)} selected={obj.id === selectedId} />
          )
        case 'area':
          return (
            <MeasureArea key={obj.id} area={obj} onChange={handleChange(obj.id)} selected={obj.id === selectedId} />
          )
        case 'angle':
          return (
            <MeasureAngle key={obj.id} angle={obj} onChange={handleChange(obj.id)} selected={obj.id === selectedId} />
          )
        case 'coordinate':
          return (
            <MeasureCoordinate
              key={obj.id}
              point={obj}
              onChange={handleChange(obj.id)}
              selected={obj.id === selectedId}
            />
          )
      }
    })
  }

  return (
    <MeasureProvider
      onClick={(id) => {
        setSelectedId(id)
      }}
    >
      {drawMode && renderDrawMode()}
      {renderMeasureObjects()}
      <MapPopperButton
        icon={<MeasureIcon />}
        open={open}
        onClose={handleToggle}
        content={renderDesktop()}
        title="Measure"
        tooltipText="Measurement tools"
        mobileContent={
          <Box overflow="auto" padding={4}>
            {renderActions()}
          </Box>
        }
        region={region}
      />
    </MeasureProvider>
  )
}
