import { assertIsStyle, colorPalette, KrakenMap, labelStyle } from '@griegconnect/krakentools-kmap'
import { Collection, Feature } from 'ol'
import { EventsKey } from 'ol/events'
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { getLength, getArea } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo } from 'react'
import { formatDistanceInMeters } from '../../utils/formatDistanceInMeters'
import { midPoint } from '../../utils/geometry'
import { useMeasuringContext } from '../../MeasuringContext'
import { useMapContext } from '../../MapContext'
import OlVectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import OlFeature from 'ol/Feature'
import { Coordinate } from 'ol/coordinate'

export const dotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
})

export type MeasureAreaDef = {
  type: 'area'
  id: string
  area: Coordinate[][]
}

type MeasureAreaProps = {
  area: MeasureAreaDef
  selected: boolean
  onChange: (area: MeasureAreaDef) => void
}

export const distanceDecorateArea = (
  id: string,
  geometry: Polygon,
  layer: OlVectorLayer<any>,
  colorPalette: colorPalette
) => {
  const source = layer.getSource() as VectorSource
  const labels = source.getFeatures().filter((f) => f.get('measureId') === id)
  let distanceLabels = labels.filter((l) => l.get('type') === 'distanceLabel')
  let areaLabelFeature = labels.find((l) => l.get('type') === 'areaLabel')
  const coordinates = geometry.getCoordinates()[0]
  const centroid = KrakenMap.getCentroid({ type: 'Polygon', coordinates: [coordinates] })

  if (distanceLabels.length >= coordinates.length) {
    distanceLabels.forEach((dl) => source.removeFeature(dl))
    distanceLabels = []
  }

  for (let i = 0; i < coordinates.length - (coordinates.length < 4 ? 2 : 1); i++) {
    const length = getLength(new LineString([coordinates[i], coordinates[i + 1]]))
    let labelFeature = distanceLabels.find((l) => l.get('labelIndex') === i)
    if (!labelFeature) {
      labelFeature = new OlFeature({
        geometry: new Point(midPoint(coordinates[i], coordinates[i + 1])),
      })

      labelFeature.set('measureId', id)
      labelFeature.set('type', 'distanceLabel')
      labelFeature.set('labelIndex', i)
      labelFeature.set('snappable', true)
      source.addFeature(labelFeature)
    } else {
      ;(labelFeature.getGeometry() as Point).setCoordinates(midPoint(coordinates[i], coordinates[i + 1]))
    }
    let style
    if (colorPalette !== labelFeature.get('colorPalette')) {
      style = labelStyle(colorPalette).clone()
      labelFeature.set('colorPalette', colorPalette)
    } else {
      style = assertIsStyle(labelFeature.getStyle() || labelStyle(colorPalette).clone())
    }

    const text = style.getText()
    text?.setText(formatDistanceInMeters(length, 1))
    labelFeature.setStyle(style)
  }

  const area = getArea(geometry)
  let areaString
  if (area > 0.001) {
    if (area > 100000) {
      areaString = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km²'
    } else {
      areaString = Math.round(area * 100) / 100 + ' ' + 'm²'
    }
    if (!areaLabelFeature) {
      areaLabelFeature = new OlFeature({
        geometry: new Point(centroid.geometry.coordinates),
      })
      areaLabelFeature.set('measureId', id)
      areaLabelFeature.set('type', 'areaLabel')
      source.addFeature(areaLabelFeature)
    } else {
      ;(areaLabelFeature.getGeometry() as Point).setCoordinates(centroid.geometry.coordinates)
    }
    let style
    if (colorPalette !== areaLabelFeature.get('colorPalette')) {
      style = labelStyle(colorPalette).clone()
      areaLabelFeature.set('colorPalette', colorPalette)
    } else {
      style = assertIsStyle(areaLabelFeature.getStyle() || labelStyle(colorPalette).clone())
    }

    const text = style.getText()
    text?.setText(areaString)
    areaLabelFeature.setStyle(style)
  }
}

export const editMeasureAreaStyle = [
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: 'white',
      }),
      stroke: new Stroke({
        color: 'rgba(0,0,0,0.3)',
      }),
    }),
    geometry: (feature) => {
      const geom = feature.getGeometry()
      if (!geom) return
      switch (true) {
        case geom instanceof Polygon:
          return new MultiPoint((geom as Polygon).getCoordinates()[0])
        case geom instanceof Point:
          return new MultiPoint((geom as Point).getCoordinates())
        case geom instanceof LineString:
          return new MultiPoint((geom as LineString).getCoordinates())
      }
    },
    zIndex: 2000,
  }),
  new Style({
    stroke: new Stroke({
      color: 'rgba(24, 160, 251, 1)',
      lineDash: [5, 5],
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(24, 160, 251, 0.08)',
    }),
  }),
]

export const measureAreaStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 255, 0.64)',
    lineDash: [5, 5],
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 0, 255, 0.08)',
  }),
  zIndex: 150,
})

export const MeasureArea: FC<MeasureAreaProps> = ({ area, selected, onChange }) => {
  const { measuringLayer } = useMeasuringContext()
  const { kmapInstance } = useMapContext()

  const measureAreaFeature = useMemo(() => {
    const feature = new Feature({
      geometry: new Polygon([]),
    })
    feature.set('type', 'measureArea')
    feature.set('selectable', true)
    feature.set('measureId', area.id)
    feature.set('snappable', true)
    feature.setStyle(measureAreaStyle)
    measuringLayer.getSource()?.addFeature(feature)
    return feature
  }, [])

  const colorPaletteEventKey = useMemo(
    () =>
      kmapInstance.on('colorPalette-changed', (colorPalette: colorPalette) => {
        const geom = measureAreaFeature.getGeometry()
        if (geom) {
          distanceDecorateArea(area.id, geom, measuringLayer, colorPalette)
        }
      }),
    [area, measuringLayer, selected, measureAreaFeature]
  )

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([measureAreaFeature]),
      insertVertexCondition: () => true,
      style: [...editMeasureAreaStyle, dotStyle],
    })
    return modify
  }, [])

  let changeEventKey: EventsKey | undefined
  let modifyEndEventKey: EventsKey | undefined

  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.addToSnapCollection(measureAreaFeature)
    kmapInstance.resetSnapCollection()
    measuringLayer.setZIndex(3000)
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === area.id)
      ?.forEach((f) => assertIsStyle(f.getStyle()).setZIndex?.(5000))
    changeEventKey = measureAreaFeature.getGeometry()?.on('change', (event) => {
      const geometry = event.target as Polygon
      distanceDecorateArea(area.id, geometry, measuringLayer, kmapInstance.getCurrentColorPalette())
    })

    measureAreaFeature.setStyle([dotStyle, ...editMeasureAreaStyle])

    modifyEndEventKey = modifyInteraction.on('modifyend', () => {
      const geom = measureAreaFeature.getGeometry()
      const coords = geom?.getCoordinates()
      if (coords) {
        onChange({ ...area, area: coords })
      }
    })

    kmapInstance.addInteraction(modifyInteraction)
  }

  const disableEdit = () => {
    measureAreaFeature.setStyle(measureAreaStyle)
    kmapInstance.removeInteraction(modifyInteraction)
    measuringLayer.setZIndex(100)
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === area.id)
      ?.forEach((f) => assertIsStyle(f.getStyle()).setZIndex(100))
    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  useEffect(() => {
    const geom = measureAreaFeature.getGeometry()
    if (geom) {
      geom.setCoordinates(area.area)
      distanceDecorateArea(area.id, geom, measuringLayer, kmapInstance.getCurrentColorPalette())
    }
  }, [area.area])

  useEffect(() => {
    return () => {
      disableEdit()
      measuringLayer.getSource()?.forEachFeature((f) => {
        if (f.get('measureId') === area.id) {
          measuringLayer.getSource()?.removeFeature(f)
        }
      })
      measuringLayer.getSource()?.removeFeature(measureAreaFeature)
      kmapInstance.un(colorPaletteEventKey)
    }
  }, [])

  useEffect(() => {
    if (selected) {
      enableEdit()
    } else {
      disableEdit()
    }
  }, [selected, modifyInteraction])

  return null
}

export default MeasureArea
