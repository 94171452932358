import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { headerAtom } from '../atoms/headerAtom'
import { tenantsAtom } from '../atoms/tenantsAtom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      maxWidth: 400,
    },
    paper: {
      width: '100%',
      padding: theme.spacing(3, 2),
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    description: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    lastListItem: {
      borderBottom: 'none',
    },
    refreshButtonLoading: {
      marginTop: theme.spacing(1.5),
    },
    refreshButton: {
      margin: `${theme.spacing(2)} auto 0px`,
      display: 'block',
    },
    dialog: {
      zIndex: `${theme.zIndex.appBar - 1} !important` as unknown as number,
      top: `${theme.mixins.toolbar.minHeight}px !important`,
    },
  })
)

export interface INoActiveTenantProps {
  onSelect: (ref: string) => void
  type?: 'modal' | 'page'
}

const NoActiveTenant: React.FC<React.PropsWithChildren<INoActiveTenantProps>> = ({ onSelect, type }) => {
  const classes = useStyles()
  const tenants = useRecoilValue(tenantsAtom)
  const [headerState, setHeaderState] = useRecoilState(headerAtom)

  useEffect(() => {
    setHeaderState({ ...headerState, hideTenantSelector: true })

    return () => setHeaderState({ ...headerState, hideTenantSelector: false })
  }, [])

  const selectHandler = (ref: string) => {
    setTimeout(() => {
      onSelect(ref)
    }, 250)
  }

  const createTenantList = tenants.map((tenant, index) => {
    return (
      <ListItem
        key={`${index}-${tenant.ref}`}
        button={true}
        className={index === tenants.length - 1 ? classes.lastListItem : ''}
        onClick={() => selectHandler(tenant.ref)}
        divider={true}
      >
        <ListItemText>{tenant.name}</ListItemText>
      </ListItem>
    )
  })

  const Content = () => (
    <>
      {tenants ? (
        <>
          <Typography variant="h2" className={classes.title}>
            Select company
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" component="div" className={classes.description}>
            {tenants.length > 0 && <p>Please select the company you want to work on.</p>}
            {tenants.length === 0 && (
              <>
                <p>
                  You are missing permissions to view this application. If you think you have been granted the necessary
                  permissions, you can press "reload application" to try again.
                </p>
                <p>If problem persist, please contact your administrator.</p>
              </>
            )}
          </Typography>
          {tenants.length > 0 && <List>{createTenantList}</List>}
          {tenants.length === 0 && (
            <Button
              className={classes.refreshButton}
              color="primary"
              variant="contained"
              onClick={() => document.location.reload()}
            >
              Reload application
            </Button>
          )}
        </>
      ) : (
        <>
          <Typography variant="h6" gutterBottom={false}>
            No companies found
          </Typography>
          <Typography color="textSecondary" variant="subtitle1" gutterBottom={true}>
            No companies found for this user.
          </Typography>
        </>
      )}
    </>
  )

  return type === 'modal' ? (
    <Dialog open scroll="paper" className={classes.dialog}>
      <DialogContent dividers>
        <Content />
      </DialogContent>
    </Dialog>
  ) : (
    <Grid container={true} direction="column" justifyContent="center" alignItems="center">
      <Grid className={classes.gridItem} item={true} xs={11} sm={7} md={5} lg={4}>
        <Paper className={classes.paper} elevation={3}>
          <Content />
        </Paper>
      </Grid>
    </Grid>
  )
}

NoActiveTenant.defaultProps = {
  type: 'page',
}

export default NoActiveTenant
