import { GpsFixedOn as GpsFixedOnIcon, GpsFixedOff as GpsFixedOffIcon } from '@griegconnect/krakentools-react-icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC } from 'react'
import { MapPopperButton } from '../shared/MapPopperButton'
import { MapButtonPanelRegion } from '../shared/MapButtonPanel'
import { useMapContext } from '../MapContext'
import { followedVesselSelector } from '../atoms/liveViewAtoms'
import { gpsPositionEnabledAtom } from '../atoms/mapConfigAtoms'

export const GpsControl: FC<React.PropsWithChildren<{ region?: MapButtonPanelRegion }>> = ({ region }) => {
  const { kmapInstance: instance } = useMapContext()
  const theme = useTheme()
  const isMobilOrTablet = useMediaQuery(theme.breakpoints.down('xl'))
  const followedVessel = useRecoilValue(followedVesselSelector(instance.mapElementId))
  const [gpsEnabled, setGpsEnabled] = useRecoilState(gpsPositionEnabledAtom(instance.mapElementId))

  const onGpsToggle = () => {
    setGpsEnabled((prev) => !prev)
  }

  return followedVessel === null || !isMobilOrTablet ? (
    <MapPopperButton
      icon={gpsEnabled ? <GpsFixedOnIcon /> : <GpsFixedOffIcon />}
      onClose={onGpsToggle}
      region={region}
      tooltipText="Enable GPS position tracking"
    />
  ) : null
}

export default GpsControl
