import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useRecoilValue } from 'recoil'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { headerTitleAtom } from '../atoms/headerTitleAtom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
  })
)

export const TopBarTitle = () => {
  const classes = useStyles()
  const titleState = useRecoilValue(headerTitleAtom)
  const { name } = useApplicationOptions()

  return titleState.hide ? null : (
    <Typography className={classes.title} variant="h6" noWrap>
      {titleState.component ?? titleState.title ?? name}
    </Typography>
  )
}

export default TopBarTitle
