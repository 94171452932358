import React, { ChangeEvent } from 'react'

import { makeStyles } from '@mui/styles'
import { GridEditInputCell, useGridApiContext } from '@mui/x-data-grid-pro'

import { CustomEditComponentProps, isValueInValid, setEditCellValue } from '../Utils'

const useStyles = makeStyles(() => ({
  disabledStyle: {
    '& .Mui-disabled': {
      backgroundColor: '#EAEAEA',
    },
  },
}))

export const InlineDataGridInput = ({ props, required, disabled, endAdornment }: CustomEditComponentProps) => {
  const apiRef = useGridApiContext()

  const classes = useStyles()

  const handleValueChange = async (_event: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    setEditCellValue(apiRef, props.id, props.field, newValue)
  }

  return (
    <GridEditInputCell
      {...props}
      className={classes.disabledStyle}
      required={required}
      disabled={disabled}
      error={required ? isValueInValid(props.value) : undefined}
      onValueChange={handleValueChange}
      endAdornment={endAdornment}
    />
  )
}
