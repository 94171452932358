import React, { useState } from 'react'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { SignOut } from '@griegconnect/krakentools-react-icons'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Modal, PaletteMode, Slide } from '@mui/material'
import ProfileForm from './profile-form'
import { SnackbarProvider } from 'notistack'
import ProfileIcon from './profile-icon'

const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    zIndex: theme.zIndex.snackbar,
  },
  popper: {
    zIndex: theme.zIndex.appBar + 1,
  },
  paper: {
    minWidth: 200,
    maxHeight: '80vh',
    overflow: 'auto',
  },
  edit: {
    marginBottom: theme.spacing(2),
    display: 'block',
  },
  login: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  box: {
    padding: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    outline: 'none',
  },
  modalBg: {
    width: '100%',
    height: '100%',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  switchRoot: {
    marginLeft: 0,
  },
  switchLabel: {
    marginRight: theme.spacing(1),
  },
}))

export type Profile = {
  fullName?: string
  email: string
  phone?: string | undefined
  picture?: string
  locale?: string | null
}
// IETF BCP 47 language tag
// https://en.wikipedia.org/wiki/IETF_language_tag
// Only add languages that are fully supported by one or more applications.
export type LanguageTag = 'en-GB' | 'fi-FI' | 'nb-NO' | 'sv-SE'

export type ProfileMenuProps = {
  profile: Profile
  /**
   * One or more languages to display in the language selector.
   */
  languages?: LanguageTag[]
  disableEditProfile?: boolean
  onProfileSave: (profile: Profile) => Promise<void>
  onProfileImageSave: (profileImage: string) => void
  onLogin?: () => void
  onLogout?: () => void
  paletteType?: PaletteMode
  onChangePaletteMode?: (paletteType: PaletteMode) => void
  className?: string
}

export const ProfileMenu: React.FC<React.PropsWithChildren<ProfileMenuProps>> = (props) => {
  const {
    profile,
    disableEditProfile,
    onProfileSave,
    onProfileImageSave,
    onLogout,
    paletteType: theme,
    onChangePaletteMode,
    languages,
    children,
    className,
  } = props

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openProfileForm, setOpenProfileForm] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return
    } else {
      setAnchorEl(null)
    }
  }

  const handleOpenProfileForm = () => {
    setAnchorEl(null)
    setOpenProfileForm(true)
  }

  const changePaletteModeHandler = (darkmode: boolean) => onChangePaletteMode?.(darkmode ? 'dark' : 'light')
  const logoutHandler = () => onLogout?.()

  const getName = (() => {
    if (profile) {
      if (profile.fullName) {
        return profile.fullName
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  })()

  const open = Boolean(anchorEl)
  return (
    <>
      {profile && (
        <div className={className}>
          <ProfileIcon onClick={handleClick} fullName={getName} imageUrl={profile.picture} />
          <Popper
            open={open}
            anchorEl={anchorEl}
            className={classes.popper}
            disablePortal={false}
            modifiers={[
              {
                name: 'flip',
              },
              {
                name: 'preventOverflow',
              },
              {
                name: 'arrow',
              },
            ]}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper elevation={3} className={classes.paper}>
                {(getName || profile.email) && (
                  <>
                    <div className={classes.box}>
                      {getName && <Typography variant="body1">{getName}</Typography>}
                      {profile.email && (
                        <Typography variant="body2" gutterBottom>
                          {profile.email}
                        </Typography>
                      )}
                    </div>
                    <Divider />
                  </>
                )}
                {theme && (
                  <>
                    <div className={classes.box}>
                      <FormControlLabel
                        classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                        labelPlacement="start"
                        control={
                          <Switch
                            size="small"
                            checked={theme === 'dark'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              changePaletteModeHandler(event.target.checked)
                            }
                            style={{ marginLeft: 0 }}
                            name="darkmode"
                            color="primary"
                          />
                        }
                        label="Dark mode"
                      />
                    </div>
                    <Divider />
                  </>
                )}
                <div className={classes.box}>
                  {children}
                  {disableEditProfile !== true && (
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                      disableElevation={true}
                      onClick={handleOpenProfileForm}
                      className={classes.edit}
                    >
                      Edit profile
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disableElevation={true}
                    endIcon={<SignOut />}
                    onClick={logoutHandler}
                  >
                    Sign out
                  </Button>
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
      <SnackbarProvider
        style={{ width: 180 }}
        maxSnack={2}
        className={classes.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Modal
          className={classes.modal}
          open={openProfileForm}
          onClose={() => setOpenProfileForm(false)}
          aria-labelledby="profile-modal-title"
          aria-describedby="profile-modal-description"
        >
          <Slide in={openProfileForm} timeout={300} direction="up">
            <div className={classes.modalBg}>
              <ProfileForm
                open={openProfileForm}
                onClose={() => setOpenProfileForm(false)}
                profile={profile}
                onProfileImgSave={onProfileImageSave}
                onFormSave={async (form) => {
                  await onProfileSave(form)
                  setOpenProfileForm(false)
                }}
                languages={languages}
              />
            </div>
          </Slide>
        </Modal>
      </SnackbarProvider>
    </>
  )
}

export default ProfileMenu
