import { useConfig, useHttp } from '@griegconnect/krakentools-react-kraken-app'
import { createContext, useContext, useMemo } from 'react'
import { default as MooringPlanApi, default as mooringPlanApi } from '../../apis/mooring-plan-api/MooringPlanApi'
import PortGatewayApi from '../../apis/port-gateway-api/PortGatewayApi'

export type ApiServiceProviderProps = {
  children: React.ReactNode
  tenantRef: string | undefined
}

export type ApiServiceContextValues = {
  mooringPlanApi: mooringPlanApi
  gateWayApi: PortGatewayApi
}

export const ApiServicesContext = createContext<ApiServiceContextValues | null>(null)

export const useApiServices = () => {
  const context = useContext(ApiServicesContext)
  if (!context) {
    throw new Error('useApiServices must be used within a ApiServiceProvider')
  }
  return context
}

export const ApiServiceProvider = ({ children, tenantRef }: ApiServiceProviderProps) => {
  const { httpClient } = useHttp()
  const config = useConfig()

  const createUrl = (url: string, tenantRef?: string) => `${url}/companies/${tenantRef}`

  const mooringPlanApi = useMemo(() => httpClient(createUrl(config.api.mooringPlanApiUrl, tenantRef)), [
    config.api.mooringPlanApiUrl,
    httpClient,
    tenantRef,
  ])

  const gateWayApi = useMemo(() => httpClient(createUrl(`${config.api.gatewayApiBaseUrl}/gateway`, tenantRef)), [
    config.api.gatewayApiBaseUrl,
    httpClient,
    tenantRef,
  ])

  const apiServices: ApiServiceContextValues = useMemo(() => {
    return {
      mooringPlanApi: new MooringPlanApi(mooringPlanApi),
      gateWayApi: new PortGatewayApi(gateWayApi),
    }
  }, [gateWayApi, mooringPlanApi])

  return <ApiServicesContext.Provider value={apiServices}>{children}</ApiServicesContext.Provider>
}
