import { SnackbarWrapper } from '@griegconnect/krakentools-react-kraken-app'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { RoutePaths } from '../../RoutePaths'
import { LocalStorageAuthProvider } from './LocalStorageAuthProvider'
import ThemeWrapper from './ThemeWrapper'
import { MapRoute } from './modules/MapRoute'
import { MooringPlan } from './modules/MooringPlan'

export const LocalStorageApp = () => {
  return (
    <LocalStorageAuthProvider>
      <ThemeWrapper>
        <SnackbarWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<MapRoute />} />
              <Route path={RoutePaths.mooringPlan} element={<MooringPlan />} />
            </Routes>
          </BrowserRouter>
        </SnackbarWrapper>
      </ThemeWrapper>
    </LocalStorageAuthProvider>
  )
}
