import { assertIsStyle, colorPalette, labelStyle, Spatial } from '@griegconnect/krakentools-kmap'
import { Collection, Feature } from 'ol'
import { EventsKey } from 'ol/events'
import { Circle, LineString, MultiPoint, Point, Polygon } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { getLength } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo } from 'react'
import { formatDistanceInMeters } from '../../utils/formatDistanceInMeters'
import { midPoint } from '../../utils/geometry'
import { useMeasuringContext } from '../../MeasuringContext'
import { useMapContext } from '../../MapContext'
import OlVectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import OlFeature from 'ol/Feature'
import { fromCircle } from 'ol/geom/Polygon'
import { useState } from 'react'
import { Coordinate } from 'ol/coordinate'

export const dotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
    stroke: new Stroke({
      color: 'rgba(0,0,0,0.3)',
    }),
  }),
})

const editSectorStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(24, 160, 251, 1)',
  }),
  zIndex: 500,
})

const sectorStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 255, 0.64)',
  }),
  zIndex: 500,
})

export const editMeasureAngleStyle = [
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: 'white',
      }),
      stroke: new Stroke({
        color: 'rgba(0,0,0,0.3)',
      }),
    }),
    geometry: (feature) => {
      const geom = feature.getGeometry()
      if (!geom) return

      switch (true) {
        case geom instanceof Polygon:
          return new MultiPoint((geom as Polygon).getCoordinates()[0])
        case geom instanceof Point:
          return new MultiPoint((geom as Point).getCoordinates())
        case geom instanceof LineString:
          return new MultiPoint((geom as LineString).getCoordinates())
      }
    },
    zIndex: 2000,
  }),
  new Style({
    stroke: new Stroke({
      color: 'rgba(24, 160, 251, 1)',
      lineDash: [5, 5],
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(24, 160, 251, 0.08)',
    }),
  }),
]

export const measureAngleStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(255, 0, 255, 0.64)',
    lineDash: [5, 5],
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(255, 0, 255, 0.08)',
  }),
  zIndex: 1000,
})

export type MeasureAngleDef = {
  type: 'angle'
  id: string
  points: Coordinate[]
}

type MeasureAngleProps = {
  angle: MeasureAngleDef
  selected: boolean
  onChange: (angle: MeasureAngleDef) => void
}

export const distanceDecorateAngle = (
  id: string,
  geometry: LineString,
  layer: OlVectorLayer<any>,
  colorPalette: colorPalette,
  inEdit?: boolean
) => {
  const source = layer.getSource() as VectorSource
  const labels = source.getFeatures().filter((f) => f.get('measureId') === id)
  let distanceLabels = labels.filter((l) => l.get('type') === 'distanceLabel')
  let angleLabels = labels.filter((l) => l.get('type') === 'angleLabel')
  let sectorFeature = labels.find((l) => l.get('type') === 'angleSector')
  const coordinates = geometry.getCoordinates()
  const isEqualPoint = (p1: number[], p2: number[]) => p1[0] === p2[0] && p1[1] === p2[1]

  if (distanceLabels.length >= coordinates.length) {
    distanceLabels.forEach((dl) => source.removeFeature(dl))
    distanceLabels = []
  }
  for (let i = 0; i < coordinates.length - 1; i++) {
    // Length-labels
    const length = getLength(new LineString([coordinates[i], coordinates[i + 1]]))
    let labelFeature = distanceLabels.find((l) => l.get('labelIndex') === i)
    if (!labelFeature) {
      labelFeature = new OlFeature({
        geometry: new Point(midPoint(coordinates[i], coordinates[i + 1])),
      })
      labelFeature.set('measureId', id)
      labelFeature.set('type', 'distanceLabel')
      labelFeature.set('labelIndex', i)
      source.addFeature(labelFeature)
    } else {
      ;(labelFeature.getGeometry() as Point).setCoordinates(midPoint(coordinates[i], coordinates[i + 1]))
    }
    let style: Style
    if (colorPalette !== labelFeature.get('colorPalette')) {
      style = labelStyle(colorPalette).clone()
      labelFeature.set('colorPalette', colorPalette)
    } else {
      style = assertIsStyle(labelFeature.getStyle() || labelStyle(colorPalette).clone())
    }

    const text = style.getText()
    text?.setText(formatDistanceInMeters(length, 1))
    labelFeature.setStyle(style)

    // Angle labels
    if (i > 0 && coordinates[i + 1] && !isEqualPoint(coordinates[i], coordinates[i + 1])) {
      const [x0, y0] = coordinates[i - 1]
      const [x1, y1] = coordinates[i]
      const [x2, y2] = coordinates[i + 1]
      const l1 = getLength(new LineString([coordinates[i - 1], coordinates[i]]))
      const l2 = getLength(new LineString([coordinates[i], coordinates[i + 1]]))
      const dx0 = x1 - x0
      const dy0 = y1 - y0
      const dx1 = x2 - x1
      const dy1 = y2 - y1

      const midPoint = [(x0 + x2) / 2, (y0 + y2) / 2]

      const rad = Math.atan2(dx0 * dy1 - dx1 * dy0, dx0 * dx1 + dy0 * dy1)
      const deg = 180 + (rad / Math.PI) * 180
      const extDeg = deg > 180 ? deg : 360 - deg
      const intDeg = deg > 180 ? 360 - deg : deg
      const radMid = ((deg / 2) * Math.PI) / 180
      const radXaxisOffset = Math.atan2(y1 - y0, x1 - x0)
      const labels = angleLabels.filter((l) => l.get('labelIndex') === i)

      let label1: OlFeature<Point>
      let label2: OlFeature<Point>

      if (labels.length === 2) {
        label1 = labels[0] as OlFeature<Point>
        label2 = labels[1] as OlFeature<Point>
      } else {
        label1 = new OlFeature<Point>({
          geometry: new Point(midPoint),
        })
        label2 = new OlFeature<Point>({
          geometry: new Point(midPoint),
        })
        label1.set('measureId', id)
        label2.set('measureId', id)
        label1.set('type', 'angleLabel')
        label2.set('type', 'angleLabel')
        label1.set('labelIndex', i)
        label2.set('labelIndex', i)
        // const angleLabelStyle = labelStyle(colorPalette).getText().clone()
        source.addFeatures([label2, label1])
      }

      if (colorPalette !== label1.get('colorPalette') || !label1.getStyle()) {
        const angleLabelStyle = style.clone()
        const angleLabelText = angleLabelStyle.getText()
        if (angleLabelText) {
          angleLabelText.setOffsetX(0)
          angleLabelText.setOffsetY(0)
          angleLabelText.setScale(1.1)
          angleLabelText.setTextBaseline('middle')
          angleLabelText.setTextAlign('center')
        }

        label1.setStyle(angleLabelStyle.clone())
        label2.setStyle(angleLabelStyle.clone())
        labelFeature.set('colorPalette', colorPalette)
      }

      const text1 = assertIsStyle(label1.getStyle()).getText()
      const text2 = assertIsStyle(label2.getStyle()).getText()
      if (text1 && text2) {
        text1.setText((deg < 180 ? intDeg.toFixed(1) : extDeg.toFixed(1)) + '°')
        text2.setText((deg < 180 ? extDeg.toFixed(1) : intDeg.toFixed(1)) + '°')

        text1.setOffsetX(30 * Math.sin(radMid + radXaxisOffset - Math.PI / 2))
        text1.setOffsetY(30 * Math.cos(radMid + radXaxisOffset - Math.PI / 2))

        text2.setOffsetX(30 * Math.sin(radMid + radXaxisOffset - Math.PI * 1.5))
        text2.setOffsetY(30 * Math.cos(radMid + radXaxisOffset - Math.PI * 1.5))
      }
      label1.getGeometry()?.setCoordinates(coordinates[i])
      label2.getGeometry()?.setCoordinates(coordinates[i])
      const radius = Math.min(l1, l2) / 3
      const pointCount = 360
      const pointMultiplier = 4
      const sectorPoints = fromCircle(
        new Circle(coordinates[i], radius),
        pointCount * pointMultiplier,
        Spatial.toRadians(0.5) + rad + radXaxisOffset
      )
        .getCoordinates()[0]
        .slice(
          deg < 180 ? pointCount * pointMultiplier - pointMultiplier * deg - 1 : 0,
          deg < 180 ? pointCount * pointMultiplier : pointCount * pointMultiplier - pointMultiplier * deg - 2
        )

      sectorPoints.push(coordinates[i])
      if (!sectorFeature) {
        sectorFeature = new OlFeature<Polygon>({
          geometry: new Polygon([sectorPoints]),
        })
        sectorFeature.set('measureId', id)
        sectorFeature.set('labelIndex', i)
        sectorFeature.set('type', 'angleSector')
        source.addFeature(sectorFeature)
      } else {
        ;(sectorFeature.getGeometry() as Polygon).setCoordinates([sectorPoints])
      }
      sectorFeature.setStyle(inEdit ? editSectorStyle : sectorStyle)
    }
  }
}

export const MeasureAngle: FC<MeasureAngleProps> = ({ angle, selected, onChange }) => {
  const { measuringLayer } = useMeasuringContext()
  const { kmapInstance } = useMapContext()
  const [viewParams, setViewParams] = useState(kmapInstance.getViewParams())

  const moveEndEventKey = useMemo(
    () =>
      kmapInstance.on('moveend', () => {
        setViewParams(kmapInstance.getViewParams())
      }),
    []
  )

  const measureAngleFeature = useMemo(() => {
    const feature = new Feature({
      geometry: new LineString([]),
    })
    feature.set('type', 'measureAngle')
    feature.set('selectable', true)
    feature.set('snappable', true)
    feature.set('measureId', angle.id)
    feature.setStyle(measureAngleStyle)
    measuringLayer.getSource()?.addFeature(feature)
    return feature
  }, [])

  const colorPaletteEventKey = useMemo(
    () =>
      kmapInstance.on('colorPalette-changed', (colorPalette: colorPalette) => {
        const geom = measureAngleFeature.getGeometry()
        if (geom) {
          distanceDecorateAngle(angle.id, geom, measuringLayer, colorPalette, selected)
        }
      }),
    [angle, measuringLayer, selected, measureAngleFeature]
  )

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([measureAngleFeature]),
      insertVertexCondition: () => false,
      deleteCondition: () => false,
      style: [...editMeasureAngleStyle, dotStyle],
    })
    return modify
  }, [])

  let changeEventKey: EventsKey | undefined
  let modifyEndEventKey: EventsKey | undefined
  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.addToSnapCollection(measureAngleFeature)
    kmapInstance.resetSnapCollection()
    measuringLayer.setZIndex(3000)
    changeEventKey = measureAngleFeature.getGeometry()?.on('change', (event) => {
      const geometry = event.target as LineString
      distanceDecorateAngle(angle.id, geometry, measuringLayer, kmapInstance.getCurrentColorPalette(), selected)
    })

    measureAngleFeature.setStyle([dotStyle, ...editMeasureAngleStyle])
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === angle.id)
      ?.forEach((f) => {
        const style = f.getStyle()
        if (style instanceof Style) {
          style.setZIndex(5000)
        }
      })
    modifyEndEventKey = modifyInteraction.on('modifyend', () => {
      const geom = measureAngleFeature.getGeometry()
      const coords = geom?.getCoordinates()
      if (coords) {
        onChange({ ...angle, points: coords })
      }
    })

    kmapInstance.addInteraction(modifyInteraction)
  }

  const disableEdit = () => {
    measureAngleFeature.setStyle(measureAngleStyle)
    kmapInstance.removeInteraction(modifyInteraction)
    measuringLayer.setZIndex(100)
    measuringLayer
      .getSource()
      ?.getFeatures()
      ?.filter((f) => f.get('measureId') === angle.id)
      ?.forEach((f) => {
        const style = f.getStyle()
        if (style instanceof Style) {
          style.setZIndex(100)
        }
      })
    const geom = measureAngleFeature.getGeometry()
    if (geom) {
      distanceDecorateAngle(angle.id, geom, measuringLayer, kmapInstance.getCurrentColorPalette(), selected)
    }
    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  useEffect(() => {
    const geom = measureAngleFeature.getGeometry()
    if (geom) {
      geom.setCoordinates(angle.points)
      distanceDecorateAngle(angle.id, geom, measuringLayer, kmapInstance.getCurrentColorPalette(), selected)
    }
  }, [angle.points, viewParams, selected])

  useEffect(() => {
    return () => {
      disableEdit()
      measuringLayer.getSource()?.forEachFeature((f) => {
        if (f.get('measureId') === angle.id) {
          measuringLayer.getSource()?.removeFeature(f)
        }
      })
      measuringLayer.getSource()?.removeFeature(measureAngleFeature)
      kmapInstance.un(moveEndEventKey)
      kmapInstance.un(colorPaletteEventKey)
    }
  }, [])

  useEffect(() => {
    if (selected) {
      enableEdit()
    } else {
      disableEdit()
    }
  }, [selected, modifyInteraction])
  return null
}

export default MeasureAngle
