import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMeasureAngle = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M20 18h-2A13 13 0 0 0 6 6.05V4a1 1 0 0 0-2 0v12h2V8.05A11 11 0 0 1 16 18H8v2h12a1 1 0 0 0 0-2Z" />,
    <path d="M6 17H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z" />
  )
export default SvgMeasureAngle
