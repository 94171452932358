import { Coordinate } from 'ol/coordinate'
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react'

import { StaticVessel as StaticVesselClass, StaticVesselStyle, ILonLat } from '@griegconnect/krakentools-kmap'

import { useMooringContext } from '../MooringPlanContext'
import { fromLonLat } from 'ol/proj'

type StaticVesselProps = {
  length: number
  width: number
  position: ILonLat
  rotation?: number
  onChange: (vesselId: string | number, position: Coordinate, rotation: number, isColliding?: boolean) => void
  editable?: boolean
  vesselId: string | number
  style: StaticVesselStyle
  selected?: boolean
  label?: ReactNode
}

export const StaticVessel: FC<React.PropsWithChildren<StaticVesselProps>> = ({
  length,
  width,
  position,
  rotation = 0,
  editable,
  vesselId,
  style,
  selected = false,
  onChange,
  label,
}) => {
  const { kmapInstance, mooringLayer } = useMooringContext()
  const ref = useRef<HTMLDivElement>(null)
  const staticVessel = useMemo(() => {
    const vessel = new StaticVesselClass(
      kmapInstance,
      length,
      width,
      fromLonLat([position.lon, position.lat]),
      rotation,
      vesselId,
      onChange,
      style,
      !!editable,
      mooringLayer
    )
    return vessel
  }, [kmapInstance, vesselId])
  useEffect(
    () => () => {
      if (staticVessel) {
        staticVessel.destroy()
      }
    },
    [staticVessel]
  )

  useEffect(() => {
    if (onChange) {
      staticVessel.setOnChangeListener(onChange)
    }
  }, [onChange])

  useEffect(() => {
    staticVessel.setPosition(fromLonLat([position.lon, position.lat]))
  }, [position])

  useEffect(() => {
    staticVessel.setRotation(rotation)
  }, [rotation])

  useEffect(() => {
    if (!editable) {
      staticVessel.setSelected(false)
      return
    }
    if (staticVessel) {
      staticVessel.setSelected(selected)
      return
    }
  }, [selected, editable])

  useEffect(() => {
    if (label) {
      if (ref.current) {
        staticVessel.setLabel(ref.current)
      }
    } else {
      staticVessel.removeLabelOverlay()
    }
  }, [ref, label])

  useEffect(
    () => () => {
      staticVessel.destroy()
    },
    [staticVessel]
  )

  return label ? (
    <div style={{ display: 'none' }}>
      <div ref={ref}>{label}</div>
    </div>
  ) : null
}
