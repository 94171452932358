import { useApiServices } from '../../setup/api/ApiServiceProvider'
import { isAxiosError } from '../utils'

export const usePortGatewayServices = () => {
  const { gateWayApi } = useApiServices()

  const getQuayVisit = async (quayVisitId: number, abortSignal?: AbortSignal) => {
    const result = await gateWayApi.getQuayVisit(quayVisitId, abortSignal)
    if (isAxiosError(result)) return false

    return result
  }

  return { getQuayVisit }
}
