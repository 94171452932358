import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { GriegHttpClientProvider } from '@griegconnect/krakentools-react-http'

import { Loading } from '../../components/Loading'
import { MissingAuth } from '../../components/MissingAuth'
import { LocalStorageKeys } from '../../localStorageKeys'
import { ApiServiceProvider } from '../api/ApiServiceProvider'

type LocalStorageAuthProps = {
  accessToken: string | null
  tenantId: string | null
  tenantRef: string | null
}

const LocalStorageAuthContext = createContext<LocalStorageAuthProps>({
  accessToken: null,
  tenantId: null,
  tenantRef: null,
})

export const useLocalStorageAuth = () => useContext(LocalStorageAuthContext)

export const LocalStorageAuthProvider = ({ children }: PropsWithChildren) => {
  const [auth, setAuth] = useState<LocalStorageAuthProps>({ accessToken: null, tenantId: null, tenantRef: null })

  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const accessToken = localStorage.getItem(LocalStorageKeys.accessToken)
    const tenantId = localStorage.getItem(LocalStorageKeys.tenantId)
    const tenantRef = localStorage.getItem(LocalStorageKeys.tenantRef)

    if (accessToken && tenantId && tenantRef) {
      setAuth({ accessToken, tenantId, tenantRef })
    }

    setInitialized(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialized) <Loading />

  if (!auth.accessToken || !auth.tenantId) return <MissingAuth />

  return (
    <LocalStorageAuthContext.Provider
      value={{ accessToken: auth.accessToken, tenantId: auth.tenantId, tenantRef: auth.tenantRef }}
    >
      <GriegHttpClientProvider onGetBearerToken={async () => auth.accessToken!}>
        <ApiServiceProvider tenantRef={auth.tenantRef || ''}>{children}</ApiServiceProvider>
      </GriegHttpClientProvider>
    </LocalStorageAuthContext.Provider>
  )
}
