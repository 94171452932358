import { Feature } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { LineString, Point, Polygon } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { getLength } from 'ol/sphere'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { assertIsStyle, labelStyleLight } from '@griegconnect/krakentools-kmap'

import { GeometryType } from '../'
import { useMooringContext } from '../MooringPlanContext'
import { formatDistanceInMeters } from '../utils/formatDistanceInMeters'
import { midPoint } from '../utils/geometry'

type DrawMooringLineInteractionProps = {
  onMooringLineCreated: (startPoint: Coordinate, vesselVertex: number, vesselId: string | number, id: string) => void
}

export const selectedMooringLineStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(24, 160, 251, 1)',
    width: 4,
  }),
})

export const notSnappedDotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'white',
    }),
  }),
})

export const snappedDotStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'green',
    }),
  }),
})

export const DrawMooringLineInteraction: FC<React.PropsWithChildren<DrawMooringLineInteractionProps>> = ({
  onMooringLineCreated,
}) => {
  const { kmapInstance: kmap, mooringLayer } = useMooringContext()
  const [isMounted, setIsMounted] = useState(false)
  let clicklistenerId: string
  const labelFeature = useMemo(() => {
    const label = new Feature({
      geometry: new Point([]),
    })
    label.setStyle(labelStyleLight.clone())
    mooringLayer.getSource()?.addFeature(label)
    return label
  }, [])

  const updateLabel = (from: Coordinate, to: Coordinate) => {
    const lineStringGeometry = new LineString([from, to])
    const geom = labelFeature.getGeometry()
    if (geom) {
      geom.setCoordinates(midPoint(from, to))
    } else {
      console.warn('No geometry on label feature')
    }
    assertIsStyle(labelFeature.getStyle())
      .getText()
      ?.setText(formatDistanceInMeters(getLength(lineStringGeometry)))
  }

  const drawInteraction = useMemo(() => {
    if (!isMounted) return null
    let snapped: number | false = false
    let snappedToVesselId: number | string = 0
    let shouldAddNewPoint = true
    const interaction = new Draw({
      type: GeometryType.LINE_STRING,
      maxPoints: 2,
      stopClick: true,
      style: () => {
        if (snapped) {
          return [selectedMooringLineStyle, snappedDotStyle]
        } else {
          return [selectedMooringLineStyle, notSnappedDotStyle]
        }
      },
      condition: () => shouldAddNewPoint,
    })

    kmap.enableSnap(true)

    interaction.on('drawstart', (e: DrawEvent) => {
      snapped = false
      shouldAddNewPoint = false
      kmap.addToSnapCollection(e.feature)
      kmap.resetSnapCollection()
      const features = mooringLayer.getSource()?.getFeatures()
      const vessels = features?.filter((f) => f.get('type') === 'vessel')
      const vesselGeoms = vessels
        ?.filter((v) => v.get('selectable'))
        .map((v) => ({
          id: v.get('id'),
          geometry: (v.getGeometry() as Polygon)?.getCoordinates()[0],
        }))
      if (!vesselGeoms) return
      e.feature.getGeometry()?.on('change', (event) => {
        const res = kmap.getViewParams().resolution
        let isSnapped = false
        const line = event.target as LineString
        const coords = line.getCoordinates()
        const snapPointCursor = coords[coords.length - 1]
        const limit = 20 * res
        vesselGeoms.forEach((vessel) => {
          let snapScore = Infinity
          vessel.geometry?.forEach((point, index) => {
            const score = Math.abs(point[0] - snapPointCursor[0]) + Math.abs(point[1] - snapPointCursor[1])
            if (score < snapScore && score < limit) {
              snapScore = score
              snapped = index
              snappedToVesselId = vessel.id
              isSnapped = true
            }
          })
        })
        if (!isSnapped) snapped = false
        updateLabel(coords[0], coords[1])
      })
      clicklistenerId = kmap.on('map-click', () => {
        if (snapped) {
          onMooringLineCreated(
            (e.feature.getGeometry() as LineString).getFirstCoordinate(),
            snapped,
            snappedToVesselId,
            v4()
          )
          stop()
        }
      })
    })
    interaction.on('drawabort', () => {
      kmap.un(clicklistenerId)
    })
    return interaction
  }, [kmap, isMounted])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    return () => {
      stop()
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [isMounted])

  const stop = () => {
    if (kmap && drawInteraction) {
      kmap.un(clicklistenerId)
      drawInteraction.abortDrawing()
      kmap.removeInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'auto'
      mooringLayer.getSource()?.removeFeature(labelFeature)
    }
  }

  return null
}
