import { Map } from '../../../modules/map/Map'
import { useLocalStorageAuth } from '../LocalStorageAuthProvider'

export const MapRoute = () => {
  const { accessToken, tenantId } = useLocalStorageAuth()

  if (!accessToken || !tenantId) return null

  return <Map accessToken={accessToken} tenantId={tenantId} />
}
