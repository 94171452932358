import { MapProvider, mapCenterSelector, mapZoomLevelSelector } from '@griegconnect/krakentools-react-kmap'
import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useMooringPlanServices } from '../../apis/mooring-plan-api/MooringPlanServices'
import { MooringPlanDto } from '../../apis/mooring-plan-api/dtos/MooringPlanDto'
import { usePortGatewayServices } from '../../apis/port-gateway-api/PortGatewayServices'
import { MooringPlanComponent } from './components/MooringPlanComponent'

type MooringPlanMapProps = {
  tenantId: string
  accessToken: string
}

export const getZoomLevel = (length: number | null) => {
  if (!length) return 5

  if (length < 25) return 18.2
  if (length < 50) return 18
  if (length < 100) return 16
  if (length < 200) return 15

  return 15
}

export const MooringPlanMap = ({ tenantId, accessToken }: MooringPlanMapProps) => {
  const config = useConfig()

  const mapIdentifierSlug = `port-go-map-mooring-${tenantId}`

  const params = useParams()
  const { getMooringPlan } = useMooringPlanServices()
  const { getQuayVisit } = usePortGatewayServices()

  const setZoomLevel = useSetRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const setViewCenter = useSetRecoilState(mapCenterSelector(mapIdentifierSlug))

  const [mooringPlan, setMooringPlan] = useState<MooringPlanDto | null>(null)

  useEffect(() => {
    const init = async () => {
      const id = params.id

      const result = id ? await getMooringPlan(id) : false
      if (!result) return

      setViewCenter({
        lat: result.mooredPosition.lat,
        lon: result.mooredPosition.lon,
      })

      setZoomLevel(getZoomLevel(result.length))

      const vessel = await getQuayVisit(result.quayVisitId)

      setMooringPlan({
        ...result,
        vesselName: vessel ? vessel.vesselName : null,
        arrivalTime: vessel ? vessel.arrivalTime : null,
        departureTime: vessel ? vessel.departureTime : null,
      })
    }
    init()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MapProvider
      mapIdentifierSlug={mapIdentifierSlug}
      activeTenantId={tenantId}
      useStorage={false}
      monitorApiBaseUrl={config.api.monitorApiBaseUrl}
      getToken={async () => accessToken}
    >
      {mooringPlan && <MooringPlanComponent mooringPlan={mooringPlan} />}
    </MapProvider>
  )
}
