export const formatDistanceInMeters = (n: number, decimals: number = 2): string => {
  if (n < 0.1) {
    return (n * 1000).toLocaleString(undefined, { maximumFractionDigits: decimals }) + 'mm'
  } else if (n < 1) {
    return (n * 100).toLocaleString(undefined, { maximumFractionDigits: decimals }) + 'cm'
  } else if (n < 1000) {
    return n.toLocaleString(undefined, { maximumFractionDigits: decimals }) + 'm'
  } else if (n > 1000) {
    return (n / 1000).toLocaleString(undefined, { maximumFractionDigits: decimals }) + 'km'
  } else {
    return n.toLocaleString(undefined, { maximumFractionDigits: decimals }) + 'm'
  }
}