import { MeasureObject, MooringPlan } from '@griegconnect/krakentools-react-kmap'
import { MeasureObjectDto, MooringPlanDto } from '../../../apis/mooring-plan-api/dtos/MooringPlanDto'
import VesselLabel from './VesselLabel'

type MooringPlanComponentProps = {
  mooringPlan: MooringPlanDto
}

export const MooringPlanComponent = ({ mooringPlan }: MooringPlanComponentProps) => {
  return (
    <MooringPlan
      mooredVessels={[
        {
          id: mooringPlan.id,
          length: mooringPlan.length,
          mooredPosition: mooringPlan.mooredPosition,
          rotation: mooringPlan.rotation,
          width: mooringPlan.width,
          isNeighbour: mooringPlan.isNeighbor,
          label: (
            <VesselLabel
              arrivalTime={mooringPlan.arrivalTime}
              departureTime={mooringPlan.departureTime}
              vesselLength={mooringPlan.length}
              vesselWidth={mooringPlan.width}
              vesselName={mooringPlan.vesselName}
            />
          ),
          mooringLines: mooringPlan.mooringLines,
        },
      ]}
      measureObjects={mooringPlan.measureObjects.map(dtoToDef)}
      showLabels
      disableInteractions
    />
  )
}

export const dtoToDef = (dto: MeasureObjectDto): MeasureObject => {
  const { id, type, value } = dto
  switch (type) {
    case 'distance':
      return { id, type, from: value.from, to: value.to }
    case 'area':
      return { id, type, area: value }
    case 'angle':
      return { id, type, points: value }
    case 'coordinate':
      return { id, type, point: value }
  }
}
