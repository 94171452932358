import { Coordinate } from 'ol/coordinate'
import { LineString, MultiPoint, Point, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { toLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react'

import { ILonLat } from '@griegconnect/krakentools-kmap'

import { MeasureObject } from '../controls/MeasureToolControl'
import { useMapContext } from '../MapContext'
import { MeasureProvider } from '../MeasuringContext'
import { MooringPlanContext } from '../MooringPlanContext'
import { DrawMooringLineInteraction } from './DrawMooringLineInteraction'
import DrawMeasureAngleInteraction from './Measure/DrawMeasureAngleInteraction'
import DrawMeasureAreaInteraction from './Measure/DrawMeasureAreaInteraction'
import DrawMeasureCoordinateInteraction from './Measure/DrawMeasureCoordinateInteraction'
import DrawMeasureLineInteraction from './Measure/DrawMeasureLineInteraction'
import MeasureAngle from './Measure/MeasureAngle'
import MeasureArea from './Measure/MeasureArea'
import MeasureCoordinate from './Measure/MeasureCoordinate'
import MeasureLine from './Measure/MeasureLine'
import MooringLine from './MooringLine'
import { StaticVessel } from './StaticVessel'
import { Feature } from 'ol'

export type MooringLine = {
  id: string
  startPoint: ILonLat
  vertexIndex: number
}

export type MooredVessel = {
  id: string | number
  length: number
  width: number
  mooredPosition: ILonLat
  rotation: number
  mooringLines?: MooringLine[]
  isNeighbour?: boolean
  label?: ReactNode
}
export type DrawTypes = 'mooringLine' | MeasureObject['type'] | null

type MooringPlanProps = {
  mooredVessels: MooredVessel[]
  measureObjects?: MeasureObject[]
  disableInteractions?: boolean
  drawMode?: DrawTypes
  onMooringLineCreated?: (startPoint: ILonLat, vesselVertexIndex: number, vesselId: string | number, id: string) => void
  onMeasureObjectCreated?: (newMeasureObject: MeasureObject) => void
  onChangeMooredVessels?: (mooredVessels: MooredVessel[], haveCollision: boolean) => void
  onChangeMeasureObjects?: (measureObjects: MeasureObject[]) => void
  onClicked?: (selectedItem: SelectedMooringPlanItem | null) => void
  selectedItem?: SelectedMooringPlanItem | null
  showLabels?: boolean
}

export type MooredVesselWithGeometry = MooredVessel & {
  geometry?: Coordinate[]
}

const mooredVesselStyle = {
  fillColor: 'rgba(143, 143, 143, 0.5) ',
  strokeColor: 'white',
  strokeWidth: 1,
}
const neighbouringVesselStyle = {
  fillColor: 'rgba(143, 143, 143, 0.38)',
  strokeColor: 'rgba(228,228,228,1)',
  strokeWidth: 1,
}

export type SelectedMooringPlanItem = {
  type: DrawTypes | 'vessel'
  id: string | number
}

export const MooringPlan: FC<React.PropsWithChildren<MooringPlanProps>> = ({
  mooredVessels,
  measureObjects,
  drawMode,
  onMooringLineCreated,
  onMeasureObjectCreated,
  onChangeMooredVessels,
  onChangeMeasureObjects,
  onClicked,
  selectedItem,
  showLabels,
  disableInteractions,
}) => {
  const { kmapInstance } = useMapContext()
  const [mooringLayerClickEventId, setMooringLayerClickEventId] = useState<string>('')
  const [isColliding, setIsColliding] = useState(false)
  const mapClickEventId = useMemo(
    () =>
      onClicked &&
      kmapInstance.on('backgroundLayer_click', (evt) => {
        onClicked(null)
      }),
    [kmapInstance]
  )

  const mooringLayer = useMemo(() => {
    const layer = new VectorLayer<VectorSource<Feature<Polygon | LineString | Point>>>({
      source: new VectorSource(),
      updateWhileInteracting: true,
      updateWhileAnimating: true,
      zIndex: 100,
      style: [
        new Style({
          stroke: new Stroke({
            color: 'black',
            width: 4,
            lineCap: 'round',
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: '#3399CC',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
          }),
          geometry: (feature) => {
            const coords = (feature.getGeometry() as LineString)?.getCoordinates?.()
            return coords ? new MultiPoint(coords) : undefined
          },
        }),
      ],
    })
    layer.set('type', 'mooringLayer')
    kmapInstance.addOlLayer(layer)
    return layer
  }, [])

  const measuringLayer = useMemo(() => {
    const layer = new VectorLayer<VectorSource<Feature<LineString>>>({
      source: new VectorSource(),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      zIndex: 3000,
      style: [
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 4,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: '#3399CC',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
          }),
        }),
      ],
    })
    kmapInstance.addOlLayer(layer)
    return layer
  }, [])

  const handleVesselChanged = (
    vesselId: string | number,
    position: Coordinate,
    rotation: number,
    isColliding?: boolean
  ) => {
    const newMooredVessels = mooredVessels.map((v) => {
      if (v.id === vesselId) {
        const lonlat = toLonLat(position)
        v.mooredPosition = { lon: lonlat[0], lat: lonlat[1] }
        v.rotation = rotation
      }
      return v
    })
    onChangeMooredVessels?.(newMooredVessels, !!isColliding)
    setIsColliding(!!isColliding)
  }

  const handleAddMeasureObject = (object: MeasureObject) => {
    onMeasureObjectCreated?.(object)
  }

  const handleMooringLineCreated = (
    startPoint: Coordinate,
    vesselVertexIndex: number,
    vesselId: string | number,
    id: string
  ) => {
    const lonLat = toLonLat(startPoint)
    onMooringLineCreated?.({ lon: lonLat[0], lat: lonLat[1] }, vesselVertexIndex, vesselId, id)
  }

  const renderDrawModes = () => {
    switch (drawMode) {
      case null:
        return null
      case 'mooringLine':
        return <DrawMooringLineInteraction onMooringLineCreated={handleMooringLineCreated} />
      case 'distance':
        return <DrawMeasureLineInteraction onMeasureLineCreated={handleAddMeasureObject} />
      case 'area':
        return <DrawMeasureAreaInteraction onMeasureAreaCreated={handleAddMeasureObject} />
      case 'angle':
        return <DrawMeasureAngleInteraction onMeasureAngleCreated={handleAddMeasureObject} />
      case 'coordinate':
        return <DrawMeasureCoordinateInteraction onMeasureCoordinateCreated={handleAddMeasureObject} />
      default:
        break
    }
  }

  useEffect(
    () => () => {
      if (kmapInstance) {
        kmapInstance.removeOlLayer(mooringLayer)
        kmapInstance.removeOlLayer(measuringLayer)
        kmapInstance.un(mooringLayerClickEventId)
        if (mapClickEventId) {
          kmapInstance.un(mapClickEventId)
        }
      }
    },
    [kmapInstance]
  )

  useEffect(() => {
    if (mooringLayerClickEventId) {
      kmapInstance.un(mooringLayerClickEventId)
    }

    const eventId = kmapInstance.on('mooringLayer_click', (evt) => {
      if (evt.feature.properties.type && drawMode === null) {
        onClicked?.({ type: evt.feature.properties.type, id: evt.feature.properties.id })
      }
    })
    setMooringLayerClickEventId(eventId)
    return () => {
      kmapInstance.un(mooringLayerClickEventId)
    }
  }, [drawMode])

  const handleChangeMooringLine = (vessel: MooredVessel, mooringLine: MooringLine, from: ILonLat, toIndex: number) => {
    onChangeMooredVessels?.(
      mooredVessels.map((v) => {
        if (v === vessel) {
          const lines: MooringLine[] | undefined = v.mooringLines?.map((l) => {
            if (l.id === mooringLine.id) {
              return {
                startPoint: from,
                vertexIndex: toIndex,
                id: mooringLine.id,
              }
            } else {
              return l
            }
          })
          return { ...vessel, mooringLines: lines }
        } else {
          return v
        }
      }),
      isColliding
    )
  }
  const onMeasureItemClicked = (id: string | null) => {
    if (id) {
      const item = measureObjects?.find((object) => object.id === id)
      if (item) {
        onClicked?.({ type: item.type, id })
      }
    }
  }

  const renderMeasureObject = (measureObject: MeasureObject) => {
    switch (measureObject.type) {
      case 'distance':
        return (
          <MeasureLine
            key={'measureLine_' + measureObject.id}
            line={measureObject}
            selected={selectedItem?.type === 'distance' && selectedItem.id === measureObject.id}
            onChange={(newLine) => {
              const measureline = measureObjects?.find((item) => item.id === measureObject.id)
              if (measureline) {
                onChangeMeasureObjects?.([
                  ...(measureObjects?.filter((ml) => ml.id !== measureObject.id) || []),
                  newLine,
                ])
              }
            }}
          />
        )
      case 'angle':
        return (
          <MeasureAngle
            key={'measureAngle_' + measureObject.id}
            angle={measureObject}
            selected={selectedItem?.type === 'angle' && selectedItem.id === measureObject.id}
            onChange={(newLine) => {
              const measureline = measureObjects?.find((item) => item.id === measureObject.id)
              if (measureline) {
                onChangeMeasureObjects?.([
                  ...(measureObjects?.filter((ml) => ml.id !== measureObject.id) || []),
                  newLine,
                ])
              }
            }}
          />
        )
      case 'coordinate':
        return (
          <MeasureCoordinate
            key={'measureCoordinate_' + measureObject.id}
            point={measureObject}
            selected={selectedItem?.type === 'coordinate' && selectedItem.id === measureObject.id}
            onChange={(newLine) => {
              const measureline = measureObjects?.find((item) => item.id === measureObject.id)
              if (measureline) {
                onChangeMeasureObjects?.([
                  ...(measureObjects?.filter((ml) => ml.id !== measureObject.id) || []),
                  newLine,
                ])
              }
            }}
          />
        )
      case 'area':
        return (
          <MeasureArea
            key={'measureArea_' + measureObject.id}
            area={measureObject}
            selected={selectedItem?.type === 'area' && selectedItem.id === measureObject.id}
            onChange={(newLine) => {
              const measureline = measureObjects?.find((item) => item.id === measureObject.id)
              if (measureline) {
                onChangeMeasureObjects?.([
                  ...(measureObjects?.filter((ml) => ml.id !== measureObject.id) || []),
                  newLine,
                ])
              }
            }}
          />
        )
      default:
        return null
    }
  }
  return (
    <MooringPlanContext.Provider value={{ mooringLayer: mooringLayer, kmapInstance }}>
      <MeasureProvider onClick={onMeasureItemClicked}>
        {renderDrawModes()}
        {mooredVessels.map((vessel, index) => (
          <Fragment key={'vessel_' + vessel.id + '_' + index}>
            <StaticVessel
              length={vessel.length}
              width={vessel.width}
              vesselId={vessel.id}
              position={vessel.mooredPosition}
              rotation={vessel.rotation}
              onChange={handleVesselChanged}
              editable={!vessel.isNeighbour && drawMode === null && !disableInteractions}
              style={vessel.isNeighbour ? neighbouringVesselStyle : mooredVesselStyle}
              selected={selectedItem?.type === 'vessel' && selectedItem.id === vessel.id && drawMode === null}
              label={(showLabels && vessel.label) || null}
            />
            {vessel.mooringLines?.map((mooringLine) => {
              const f = mooringLayer.getSource()?.getFeatureById(vessel.id)

              if (f instanceof Feature) {
                const coord = (f?.getGeometry() as Polygon)?.getCoordinates()[0][mooringLine.vertexIndex]
                if (!coord) return null
                const lonlat = toLonLat(coord)
                return (
                  <MooringLine
                    editable={!vessel.isNeighbour}
                    key={'mooringLine_vessel_' + vessel.id + '_line_' + mooringLine.id}
                    from={mooringLine.startPoint}
                    to={{ lon: lonlat[0], lat: lonlat[1] }}
                    vesselId={vessel.id}
                    selected={selectedItem?.type === 'mooringLine' && selectedItem.id === mooringLine.id}
                    id={mooringLine.id}
                    onChange={(from, to) => {
                      handleChangeMooringLine(vessel, mooringLine, from, to)
                    }}
                  />
                )
              } else {
                console.warn('Could not find vessel with id: ' + vessel.id)
                return null
              }
            })}
          </Fragment>
        ))}
        {measureObjects?.map(renderMeasureObject)}
      </MeasureProvider>
    </MooringPlanContext.Provider>
  )
}
