import { LineString } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { FC, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { GeometryType } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { useMeasuringContext } from '../../MeasuringContext'
import { distanceDecorateAngle, dotStyle, editMeasureAngleStyle, MeasureAngleDef } from './MeasureAngle'

type DrawMeasureAngleInteractionProps = {
  onMeasureAngleCreated: (area: MeasureAngleDef) => void
}

export const DrawMeasureAngleInteraction: FC<DrawMeasureAngleInteractionProps> = ({ onMeasureAngleCreated }) => {
  const { kmapInstance: kmap } = useMapContext()
  const { measuringLayer } = useMeasuringContext()
  const [isMounted, setIsMounted] = useState(false)
  const [id] = useState(v4())

  const drawInteraction = useMemo(() => {
    if (!isMounted) return null
    const interaction = new Draw({
      type: GeometryType.LINE_STRING,
      stopClick: true,
      maxPoints: 3,
      style: [...editMeasureAngleStyle, dotStyle],
    })

    kmap.enableSnap(true)
    interaction.on('drawstart', (e: DrawEvent) => {
      kmap.addToSnapCollection(e.feature)
      kmap.resetSnapCollection()
      e.feature.getGeometry()?.on('change', (event) => {
        distanceDecorateAngle(id, event.target, measuringLayer, kmap.getCurrentColorPalette(), true)
      })
    })
    interaction.on('drawend', (e: DrawEvent) => {
      const coords = (e.feature.getGeometry() as LineString).getCoordinates()
      onMeasureAngleCreated({ type: 'angle', points: coords, id: v4() })
      stop()
    })
    return interaction
  }, [kmap, isMounted])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    return () => {
      stop()
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [isMounted])

  const stop = () => {
    if (kmap && drawInteraction) {
      drawInteraction.abortDrawing()
      measuringLayer
        .getSource()
        ?.getFeatures()
        .filter((f) => f.get('measureId') === id)
        .forEach((f) => measuringLayer.getSource()?.removeFeature(f))
      kmap.removeInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'auto'
    }
  }

  return null
}

export default DrawMeasureAngleInteraction
