import { Close as CloseIcon } from '@griegconnect/krakentools-react-icons'
import { Dialog, DialogContent, DialogTitle } from '@griegconnect/krakentools-react-ui'
import { IconButton } from '@mui/material'

type AppSwitcherDialogProps = {
  open: boolean
  children?: React.ReactNode
  onClose: (event: {} | MouseEvent) => void
}

const AppSwitcherDialog = (props: AppSwitcherDialogProps) => {
  return (
    <Dialog
      open={props.open}
      sx={{ '.MuiDialog-container': { alignItems: 'center' } }}
      onClose={(_e, reason) => {
        if (reason === 'escapeKeyDown') {
          props.onClose(_e)
        }
      }}
    >
      <DialogTitle variant="h6" sx={{ padding: '16px' }}>
        Switch to:
        <IconButton
          aria-label="close"
          onClick={(e) => {
            props.onClose(e)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 280, padding: 2 }}>{props.children}</DialogContent>
    </Dialog>
  )
}

export default AppSwitcherDialog
