import { atom } from 'recoil'

export type LeftDrawerAtomType = {
  isOpen: boolean
  disable: boolean
}

export const leftDrawerAtom = atom<LeftDrawerAtomType>({
  key: 'leftDrawerState',
  default: {
    isOpen: false,
    disable: false,
  },
})

export type LeftDrawerConfigurationType = {
  showApplicationSwitcher: boolean
}

export const leftDrawerConfigurationAtom = atom<LeftDrawerConfigurationType>({
  key: 'leftDrawerConfigurationState',
  default: {
    showApplicationSwitcher: true,
  },
})
