import { atom } from 'recoil'

export type HeaderAtomType = {
  hide: boolean
  hideTabsBar: boolean
  hideTenantSelector: boolean
  hideSearch: boolean
  hideNotifications: boolean
}

export const headerAtom = atom<HeaderAtomType>({
  key: 'headerState',
  default: {
    hide: false,
    hideTabsBar: false,
    hideTenantSelector: false,
    hideSearch: true,
    hideNotifications: true,
  },
})
