import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgMap = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="m9.031 19.064-4.66 1.864A1 1 0 0 1 3 20V6a1 1 0 0 1 .629-.928l4.995-1.999a.995.995 0 0 1 .698-.02l5.647 1.882 4.66-1.863A1 1 0 0 1 21 4v14a1 1 0 0 1-.629.928l-4.994 1.998a1.001 1.001 0 0 1-.693.022M8 5.478l-3 1.2v11.846l3-1.2V5.477Zm6 13.136V6.72l-4-1.334V17.28l4 1.334Zm2-.09 3-1.2V5.477l-3 1.2v11.846Z" />,
    <path d="M14.68 20.947 9.03 19.064l5.649 1.883Z" />
  )
export default SvgMap
