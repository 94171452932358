import { useSearchParams } from 'react-router-dom'

export enum MapSearchParams {
	lon = 'lon',
	lat = 'lat',
	zoom = 'zoom',
}

export const useMapSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams()

	const getParams = () => {
		const lat = Number(searchParams.get(MapSearchParams.lat))
		const lon = Number(searchParams.get(MapSearchParams.lon))
		const zoom = Number(searchParams.get(MapSearchParams.zoom))

		return { lat, lon, zoom }
	}

	const setParams = ({ lat, lon, zoom }: { lat: number; lon: number; zoom: number }) => {
		const compareParams = getParams()

		if (compareParams.lat === lat && compareParams.lon === lon && compareParams.zoom === zoom) return

		setSearchParams({
			[MapSearchParams.lat]: lat.toString(),
			[MapSearchParams.lon]: lon.toString(),
			[MapSearchParams.zoom]: zoom.toString(),
		})
	}

	return { getParams, setParams }
}
