import { Polygon } from 'ol/geom'
import { Draw } from 'ol/interaction'
import { DrawEvent } from 'ol/interaction/Draw'
import { FC, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { GeometryType } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { useMeasuringContext } from '../../MeasuringContext'
import { distanceDecorateArea, dotStyle, editMeasureAreaStyle, MeasureAreaDef } from './MeasureArea'

type DrawMeasureAreaInteractionProps = {
  onMeasureAreaCreated: (area: MeasureAreaDef) => void
}

export const DrawMeasureAreaInteraction: FC<DrawMeasureAreaInteractionProps> = ({ onMeasureAreaCreated }) => {
  const { kmapInstance: kmap } = useMapContext()
  const { measuringLayer } = useMeasuringContext()
  const [isMounted, setIsMounted] = useState(false)
  const [id] = useState(v4())

  const drawInteraction = useMemo(() => {
    if (!isMounted) return null
    const interaction = new Draw({
      type: GeometryType.POLYGON,
      stopClick: true,
      style: [...editMeasureAreaStyle, dotStyle],
    })

    kmap.enableSnap(true)
    interaction.on('drawstart', (e: DrawEvent) => {
      kmap.addToSnapCollection(e.feature)
      kmap.resetSnapCollection()
      e.feature.getGeometry()?.on('change', (event) => {
        distanceDecorateArea(id, event.target, measuringLayer, kmap.getCurrentColorPalette())
      })
    })
    interaction.on('drawend', (e: DrawEvent) => {
      const coords = (e.feature.getGeometry() as Polygon).getCoordinates()
      onMeasureAreaCreated({ type: 'area', area: coords, id: v4() })
      stop()
    })
    return interaction
  }, [kmap, isMounted])

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
    return () => {
      stop()
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && drawInteraction) {
      kmap.addInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'none'
    }
  }, [isMounted])

  const stop = () => {
    if (kmap && drawInteraction) {
      drawInteraction.abortDrawing()
      measuringLayer
        .getSource()
        ?.getFeatures()
        .filter((f) => f.get('measureId') === id)
        .forEach((f) => measuringLayer.getSource()?.removeFeature(f))
      kmap.removeInteraction(drawInteraction)
      kmap.getOlMap().getTargetElement().style.cursor = 'auto'
    }
  }

  return null
}

export default DrawMeasureAreaInteraction
