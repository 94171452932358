import { Box, Divider, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles'
import React, { useContext, useState } from 'react'
import Carousel, { CarouselElement } from './components/carousel/carousel'
import SignUp, { SignUpCallbackType } from './components/sign-up/sign-up'
import styles from './grieg-onboarding.module.scss'
import { Mobile } from '@griegconnect/krakentools-react-icons'

export type SignInMethods = 'sms' | 'username-password'
export type GriegOnboardingProps = {
  carouselElements: CarouselElement[]
  /**
   * This option should only be used if you need to override the defaults
   */
  signInMethods?: SignInMethods[]
  children: React.ReactNode
  disableCreateAccount?: boolean
  forceSignup?: boolean
  initVisible?: boolean
  /**
   * Only use if you want to override the default flow.
   *
   */
  onClose?: () => Promise<void> | void
  onLogin?: () => Promise<void>
  onSmsLogin?: () => Promise<void>
  smsDescription?: string
  onSignUp: SignUpCallbackType
  privacyPolicyUrl: string
  signUpFields: string[]
  termsOfServiceUrl: string
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#65bbff',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
})

export type Onboarding = {
  setOnboardingVisibility: (display: boolean) => void
  displayOnboarding: boolean
}

export const GriegOnboardingContext = React.createContext<Onboarding | null>(null)

export const useOnboarding = () => useContext(GriegOnboardingContext)!

export const GriegOnboardingProvider = (props: GriegOnboardingProps) => {
  const {
    carouselElements,
    termsOfServiceUrl,
    privacyPolicyUrl,
    onSmsLogin,
    onLogin,
    onSignUp,
    signUpFields,
    children,
  } = props
  const [displayOnboarding, setDisplayOnboarding] = useState(props.initVisible ?? false)
  const [showSignUp, setShowSignUp] = useState(props.forceSignup ?? false)

  const closeSignUp = () => {
    if (props.onClose) {
      props.onClose()
    } else {
      setShowSignUp(false)
    }
  }

  const setOnboardingVisibility = (display: boolean) => {
    setDisplayOnboarding(display)
  }

  return (
    <GriegOnboardingContext.Provider
      value={{
        setOnboardingVisibility,
        displayOnboarding,
      }}
    >
      {displayOnboarding === true && (
        <ThemeProvider theme={theme}>
          <div className={styles.onboardingWrapper} id="onboarding">
            {carouselElements.length > 0 && <Carousel elements={carouselElements} />}
            <div className={styles.actionWrapper}>
              <>
                <div style={{ marginBottom: 8 }}>
                  {onLogin && (
                    <UsernamePasswordLogin
                      onLogin={onLogin}
                      disableCreateAccount={props.disableCreateAccount}
                      onShowSignup={() => setShowSignUp(true)}
                    />
                  )}
                  {onLogin && onSmsLogin && <LoginDivider />}
                  {onSmsLogin && <SMSLogin onSmsLogin={onSmsLogin} description={props.smsDescription} />}
                </div>
                {onLogin && (
                  <>
                    {props.disableCreateAccount ? (
                      <>
                        {onSmsLogin && <Divider className={styles.divider} />}
                        <Typography variant="body2" className={styles.hint}>
                          Do you need access?
                          <br /> Please contact your administrator.
                        </Typography>
                      </>
                    ) : props.onSmsLogin === undefined ? (
                      <Typography variant="body2" className={styles.hint}>
                        To continue create an account (Grieg ID) or sign in.
                      </Typography>
                    ) : null}
                  </>
                )}
              </>
            </div>
            <SignUp
              display={showSignUp}
              onClose={closeSignUp}
              termsOfServiceUrl={termsOfServiceUrl}
              privacyPolicyUrl={privacyPolicyUrl}
              signUpFields={signUpFields}
              onSignUp={onSignUp}
            />
          </div>
        </ThemeProvider>
      )}
      {displayOnboarding === false && children}
    </GriegOnboardingContext.Provider>
  )
}

const UsernamePasswordLogin = (props: {
  onLogin: NonNullable<GriegOnboardingProps['onLogin']>
  disableCreateAccount: GriegOnboardingProps['disableCreateAccount']
  onShowSignup: () => void
}) => {
  return (
    <>
      {props.disableCreateAccount ? (
        <Button onClick={() => props.onLogin()} variant="outlined" color="primary">
          Sign in with Grieg ID
        </Button>
      ) : (
        <>
          <Button onClick={() => props.onShowSignup()} variant="outlined" color="primary" sx={{ ml: 1, mr: 1 }}>
            Create account
          </Button>
          <Button id="sign-in" color="primary" variant="outlined" onClick={() => props.onLogin()} sx={{ ml: 1, mr: 1 }}>
            Sign in
          </Button>
        </>
      )}
    </>
  )
}

const SMSLogin = (props: {
  onSmsLogin: NonNullable<GriegOnboardingProps['onSmsLogin']>
  description: GriegOnboardingProps['smsDescription']
}) => {
  return (
    <>
      <Button
        id="sign-in"
        color="primary"
        variant="outlined"
        onClick={() => props.onSmsLogin()}
        sx={{ ml: 1, mr: 1 }}
        startIcon={<Mobile />}
      >
        Sign in with phone
      </Button>
      {props.description && (
        <Typography variant="body2" className={styles.hint} sx={{ mt: 1 }}>
          {props.description}
        </Typography>
      )}
    </>
  )
}

const LoginDivider = () => {
  return (
    <div>
      <div
        style={{
          display: 'inline-block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 6,
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Divider sx={{ minWidth: 62 }} />
          <Box>
            <Typography component="span" sx={{ color: 'text.disabled', fontSize: 12 }}>
              OR
            </Typography>
          </Box>
          <Divider sx={{ minWidth: 62 }} />
        </Stack>
      </div>
    </div>
  )
}
