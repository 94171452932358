import {
  ILonLat,
  IMapConfig,
  IViewParameters,
  KMapLayer,
  KrakenMap,
  colorPalette,
} from '@griegconnect/krakentools-kmap'
import { CircularProgress } from '@mui/material'
import { createContext, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  activeBaseMapsAtom,
  activeMapIdSelector,
  colorPaletteAtom,
  mapBackgroundColorAtom,
  mapCenterSelector,
  mapRotationSelector,
  mapZoomLevelSelector,
  styleTemplatesAtom,
  useLocalStorageAtom,
} from './atoms/mapConfigAtoms'
import { MapContext } from './MapContext'
import { Map } from './Map'
import { MapWrapper } from './MapWrapper'
import { FullscreenWrapper } from './FullscreenWrapper'
import { MonitorApi, MonitorMap } from './apis/MonitorApi'
import { defaultBackgroundLayer } from './utils/defaultBackgroundLayer'
import { useHttp } from '@griegconnect/krakentools-react-http'
import { useImperativeHandle } from 'react'
import { CONSTANTS } from './constants'

export type NotificationHandlerParams = { message: string; type: 'info' | 'error' | 'success' }

export type MapProviderProps = {
  /**
   * A slug identifying a given map instance and map state in recoil atoms
   */
  mapIdentifierSlug: string
  /**
   * In cases where you want to clone a map state by initializing multiple kraken map instances sharing the same state.
   * Provide a unique identifier for each instance.
   */
  targetElementId?: string
  useStorage?: boolean // Defaults to true
  children?: React.ReactNode | React.ReactNode[]
  disablePan?: boolean
  activeTenantId?: string
  monitorApiBaseUrl?: string
  ref?: React.Ref<MapRef>
  getToken: () => Promise<string | undefined>
  onViewUpdated?: (center: ILonLat, zoom: number, rotation: number) => void
  initialState?: {
    view?: Partial<Pick<IViewParameters, 'center' | 'zoom' | 'rotation'>>
  }
  onNotification?: (params: NotificationHandlerParams) => void
}

export const MonitorApiContext = createContext<MonitorApi | null>(null)

export type MapRef = KrakenMap | null

export const MapProvider = forwardRef<MapRef, MapProviderProps>((props: MapProviderProps, ref: React.Ref<MapRef>) => {
  const { mapIdentifierSlug, targetElementId, children, activeTenantId, monitorApiBaseUrl } = props
  const useMonitorApi = activeTenantId && monitorApiBaseUrl
  const [maps, setMaps] = useState<MonitorMap[]>([])
  const [layers, setLayers] = useState<KMapLayer[]>([])
  const [instance, setInstance] = useState<KrakenMap | null>(null)
  const setMapCenter = useSetRecoilState(mapCenterSelector(mapIdentifierSlug))
  const setRotation = useSetRecoilState(mapRotationSelector(mapIdentifierSlug))
  const setZoom = useSetRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
  const [activeMapId, setActiveMapId] = useRecoilState(activeMapIdSelector(mapIdentifierSlug))
  const setLocalStorageState = useSetRecoilState(useLocalStorageAtom(mapIdentifierSlug))
  const setActiveMaps = useSetRecoilState(activeBaseMapsAtom(mapIdentifierSlug))
  const setMapBackgroundColor = useSetRecoilState(mapBackgroundColorAtom(mapIdentifierSlug))
  const isMounted = useRef<boolean>(false)
  const { httpClient: axiosClient } = useHttp()
  const httpClient = useMemo(
    () => (activeTenantId ? axiosClient(`${monitorApiBaseUrl}/tenants/${activeTenantId}`) : undefined),
    [activeTenantId, axiosClient, monitorApiBaseUrl]
  )
  const monitorApi = useMemo(() => (httpClient ? new MonitorApi(httpClient) : null), [httpClient])
  const [colorPalette, setColorPalette] = useState<colorPalette>('light')
  const defaultMapLayer = useMemo(() => defaultBackgroundLayer(colorPalette), [colorPalette])

  /**
   * Handlers
   */

  const refetchMapsAndLayers = useCallback(() => {
    if (monitorApi) {
      monitorApi.getMaps().then(setMaps)
      monitorApi.getLayers().then(setLayers)
    }
  }, [monitorApi])

  const mapInstance = useRecoilCallback(({ snapshot }) => async () => {
    // Init recoil defaults
    let center = await snapshot.getPromise(mapCenterSelector(mapIdentifierSlug))
    let zoom = await snapshot.getPromise(mapZoomLevelSelector(mapIdentifierSlug))
    let rotation = await snapshot.getPromise(mapRotationSelector(mapIdentifierSlug))

    // Initial values from props
    if (props.initialState?.view) {
      if (props.initialState.view.rotation) {
        setRotation(props.initialState.view.rotation)
        rotation = props.initialState.view.rotation
      }
      if (props.initialState.view.center) {
        setMapCenter(props.initialState.view.center)
        center = props.initialState.view.center
      }
      if (props.initialState.view.zoom) {
        setZoom(props.initialState.view.zoom)
        zoom = props.initialState.view.zoom
      }
    }

    const styleTemplates = await snapshot.getPromise(styleTemplatesAtom(mapIdentifierSlug))
    const mapConfig: IMapConfig = {
      center,
      zoom,
      rotation,
      styleTemplates: styleTemplates,
      backgroundLayer: defaultMapLayer,
      disablePan: !!props.disablePan,
      zoomConfig: {
        minZoom: CONSTANTS.MIN_ZOOM,
        maxZoom: CONSTANTS.MAX_ZOOM,
      },
    }
    const kmap = new KrakenMap({ targetId: targetElementId ?? mapIdentifierSlug, mapConfig: mapConfig })
    kmap.on('moveend', (viewParams: IViewParameters) => {
      props.onViewUpdated?.(viewParams.center, viewParams.zoom, viewParams.rotation)
    })
    return kmap
  })

  useImperativeHandle(ref, () => instance)

  const activateMap = (id: KMapLayer['id']) => {
    const mapConfig = maps.find((c) => c.id === id)

    if (mapConfig) {
      const updatedMapLayers = mapConfig.layers.reduce<KMapLayer[]>((maps, config) => {
        const fallbackMapLayer = layers?.find((layer) => layer.id === config.id)
        if (fallbackMapLayer) {
          maps.push({ ...fallbackMapLayer, opacity: config.opacity })
        }
        return maps
      }, [])
      setActiveMaps(updatedMapLayers)
      setMapBackgroundColor(mapConfig.backgroundColor || null)
      setActiveMapId(id)
      setColorPalette(mapConfig.colorPalette ?? 'light')
    }
  }

  /**
   * Effects
   */

  useEffect(() => {
    refetchMapsAndLayers()
  }, [monitorApi])

  useEffect(() => {
    if (useMonitorApi) {
      if (maps && layers && maps.length > 0 && layers.length > 0) {
        if (activeMapId) {
          activateMap(activeMapId)
        } else {
          activateMap(maps[0].id)
        }
      }
    } else {
      setActiveMaps([defaultMapLayer])
    }
  }, [layers, maps, activeMapId])

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setLocalStorageState(props.useStorage ?? true)
    mapInstance()
      .then((map) => {
        if (isMounted.current) {
          setInstance(map)
        }
      })
      .catch((e) => {
        console.error(e)
      })
    return () => {
      setInstance(null)
    }
  }, [])

  return (
    <MapWrapper>
      {instance ? (
        <MapContext.Provider
          value={{
            mapIdentifierSlug: mapIdentifierSlug,
            kmapInstance: instance,
            maps,
            layers,
            refetchMapsAndLayers,
            applyMap: activateMap,
            getToken: props.getToken,
            notify: props.onNotification,
            colorPalette,
          }}
        >
          <FullscreenWrapper>
            <Map>{children}</Map>
          </FullscreenWrapper>
        </MapContext.Provider>
      ) : (
        <CircularProgress />
      )}
    </MapWrapper>
  )
})
