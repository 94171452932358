import { AssetsView as AssetsViewClass, IStyleTemplate } from '@griegconnect/krakentools-kmap'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

import { AssetsApi } from '../apis/AssetsApi'
import {
  allAssetLayersVisibleSelector,
  kindsAtom,
  hightlightedAssetAtom,
  selectedAssetSelector,
  visibleAssetLayersSelector,
  editAssetSelector,
  createAssetSelector,
  refreshAssetViewAtom,
} from '../atoms/assetViewAtoms'
import { styleTemplatesAtom } from '../atoms/mapConfigAtoms'
import { useMapContext } from '../MapContext'
import { ExtendedFeature } from '../atoms/types/ExtendedFeature'
import AssetInfoBox from '../shared/AssetInfoBox'

type InitConfigProps = {
  showAllLayers?: boolean
}

export type AssetsViewProps = {
  assetApiBaseUrl: string
  tenantId: string | null
  enableDialogs?: boolean
  initConfig?: InitConfigProps
  disableInteractions?: boolean
}

/**
 * @param {AssetsViewProps.assetApiBaseUrl} props.assetApiBaseUrl The HTTPS protocol base URL of the asset API
 */
export const AssetsView = (props: AssetsViewProps) => {
  const { mapIdentifierSlug, kmapInstance: instance, getToken } = useMapContext()
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const [view, setView] = useState<AssetsViewClass | null>(null)

  const visibleLayers = useRecoilValue(visibleAssetLayersSelector(mapIdentifierSlug))
  const highlightedAsset = useRecoilValue(hightlightedAssetAtom(mapIdentifierSlug))
  const resetHightlightedAsset = useResetRecoilState(hightlightedAssetAtom(mapIdentifierSlug))
  const [selectedAsset, setSelectedAsset] = useRecoilState(selectedAssetSelector(mapIdentifierSlug))
  const resetSelectedAsset = useResetRecoilState(selectedAssetSelector(mapIdentifierSlug))
  const showAllAssetLayers = useSetRecoilState(allAssetLayersVisibleSelector(mapIdentifierSlug))
  const [assetTypes, setAssetTypes] = useRecoilState(kindsAtom(mapIdentifierSlug))
  const setStyleTemplates = useSetRecoilState(styleTemplatesAtom(mapIdentifierSlug))
  const editAsset = useRecoilValue(editAssetSelector(mapIdentifierSlug))
  const createAsset = useRecoilValue(createAssetSelector(mapIdentifierSlug))
  const refreshAssetView = useRecoilValue(refreshAssetViewAtom(mapIdentifierSlug))
  const isMounted = useRef<boolean>(false)
  const signal = useRef<CancelTokenSource>()
  const assetDialog = useRef<HTMLDivElement>(null)

  // Memoized values
  const baseTileUrl = useMemo(() => {
    if (props.tenantId) {
      return `${props.assetApiBaseUrl}/tenants/${props.tenantId}`
    } else {
      return props.assetApiBaseUrl
    }
  }, [props.assetApiBaseUrl, props.tenantId])

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: baseTileUrl,
    })
    return new AssetsApi(instance, getToken)
  }, [baseTileUrl])

  /**
   *
   * Mounting & initialization effects
   *
   */

  useEffect(() => {
    signal.current = axios.CancelToken.source()
    isMounted.current = true
    return () => {
      signal.current?.cancel()
      isMounted.current = false
      resetSelectedAsset()
      resetHightlightedAsset()
    }
  }, [])

  useEffect(() => {
    if (api) {
      api
        .getKinds(signal.current?.token)
        .then((types) => {
          if (isMounted.current) {
            setAssetTypes(types)
            const templates: IStyleTemplate[] = []
            types.forEach((type) => {
              if (type.style?.dark && type.style?.light) {
                templates.push({
                  ...type.style.dark,
                  colorPalette: 'dark',
                  name: type.name,
                })
                templates.push({
                  ...type.style.light,
                  colorPalette: 'light',
                  name: type.name,
                })
              }
            })
            setStyleTemplates(templates)
          }
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            throw e
          }
        })
    }
    return () => {}
  }, [api])

  useEffect(() => {
    if (props.initConfig?.showAllLayers) {
      showAllAssetLayers(true)
    }
  }, [props.initConfig, assetTypes, showAllAssetLayers])

  useEffect(() => {
    if (props.tenantId) {
      const initializedView = new AssetsViewClass(
        instance,
        getToken,
        baseTileUrl,
        props.tenantId,
        !props.disableInteractions
      )
      setView(initializedView)
      return () => {
        initializedView.destroy()
      }
    } else {
      console.debug('AssetsView: No active tenant')
      setView(null)
    }
  }, [instance, props.assetApiBaseUrl, props.tenantId])

  useEffect(() => {
    if (view) {
      view.updateAssetTileLayers(visibleLayers)
      view.recreateVectorTileLayers()
    }
  }, [view, visibleLayers])

  /**
   *
   * Asset & map interactions
   *
   */

  useEffect(() => {
    if (view) {
      const assetClickListener = view.on('asset-selected', (data: ExtendedFeature | null) => {
        setSelectedAsset(data)
      })
      return () => {
        instance.un(assetClickListener)
      }
    }
  }, [view, setSelectedAsset])

  useEffect(() => {
    if (view && props.enableDialogs && selectedAsset && assetDialog.current && !isMobileOrTablet) {
      const position = 'bottom-center'
      const offset: [number, number] = [0, -35]
      const cursor = instance.cursorPosition
      const dialogId = instance.placeHTMLElement(
        assetDialog.current,
        cursor?.coordinates ?? null,
        position,
        offset,
        true
      )
      return () => {
        instance.removeOverlayById(dialogId)
      }
    }
  }, [view, props.enableDialogs, instance, selectedAsset, isMobileOrTablet])

  useEffect(() => {
    if (view) {
      view.setHighlightedFeature(highlightedAsset?.id.toString() ?? null)
    }
  }, [view, highlightedAsset])

  useEffect(() => {
    if (view && editAsset) {
      view.editFeature(
        editAsset.assetId,
        editAsset.assetGeometry,
        editAsset.callback,
        true,
        4326,
        editAsset.displayCoordinates
      )
      return () => {
        view.stopEditFeature()
      }
    }
  }, [view, editAsset])

  useEffect(() => {
    if (view && createAsset) {
      view.drawAsset(createAsset.geometry, undefined, createAsset.displayCoordinates).then((feature) => {
        createAsset.callback?.(feature)
      })
      return () => {
        view.stopAndCleanDrawing()
      }
    }
  }, [view, createAsset])

  useEffect(() => {
    if (view && refreshAssetView > 0) {
      view.refreshAssetTileLayers()
    }
  }, [view, refreshAssetView])

  return (
    <>
      {selectedAsset && props.enableDialogs && api && assetTypes ? (
        <AssetInfoBox
          onClose={resetSelectedAsset}
          assetId={selectedAsset.id}
          api={api}
          assetTypes={assetTypes}
          ref={assetDialog}
        />
      ) : null}
    </>
  )
}

export default AssetsView
