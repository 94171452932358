import { Backdrop, Box, Hidden, Paper } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useRecoilState, useRecoilValue } from 'recoil'
import { leftDrawerAtom, leftDrawerConfigurationAtom } from '../atoms/leftDrawerAtom'
import AppSwitcher from '../ui/AppSwitcher'
import LeftDrawerButton from '../ui/LeftDrawerButton'
import { Menu } from '../ui/Menu'

export type LeftDrawerProps = {
  disableBottomSafeArea?: boolean
  pixelsHeight?: number
}

type useStylesProps = {
  disableBottomSafeArea: boolean
  pixelsHeight?: number
}

const useStyles = makeStyles<Theme, useStylesProps>((theme: Theme) =>
  createStyles({
    leftDrawer: {
      zIndex: theme.zIndex.drawer,
      paddingBottom: (props) => (props.disableBottomSafeArea ? 'auto' : 'env(safe-area-inset-bottom)'),
      paddingLeft: 'env(safe-area-inset-left)',
      overflowY: 'hidden',
      overflowX: 'hidden',
      scrollbarColor: 'rgba(100,100,100,0.25) transparent',
      scrollbarWidth: 'thin',
      overscrollBehavior: 'contain',
      display: 'grid',
      boxShadow: 'none',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        gridTemplateRows: 'min-content min-content auto',
        paddingTop: 'env(safe-area-inset-top)',
        borderRight: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create('flex-basis', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateRows: 'min-content auto',
        position: 'absolute',
        height: (props) => (props.pixelsHeight ? `${props.pixelsHeight}px` : 'calc(100% - 65px)'),
        width: '320px',
        transition: theme.transitions.create('margin-left', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    closed: {
      [theme.breakpoints.up('md')]: {
        flexBasis: '72px',
        flexShrink: '0',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '-323px',
        '@supports (margin-left: env(safe-area-inset-left))': {
          marginLeft: 'calc(-323px - env(safe-area-inset-left) - env(safe-area-inset-left))',
        },
      },
    },
    open: {
      [theme.breakpoints.up('md')]: {
        flexBasis: '320px',
        flexShrink: '0',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '0px',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.appBar - 1,
    },
    menuWrapper: {
      minHeight: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarColor: 'rgba(100,100,100,0.25) transparent',
      scrollbarWidth: 'thin',
    },
  })
)

export const LeftDrawer = (props: LeftDrawerProps) => {
  const { disableBottomSafeArea, pixelsHeight } = props
  const [leftDrawerState, setLeftDrawerState] = useRecoilState(leftDrawerAtom)
  const leftDrawerConfig = useRecoilValue(leftDrawerConfigurationAtom)
  const showAppSwitcher = leftDrawerConfig.showApplicationSwitcher

  const closeLeftDrawer = () => {
    setLeftDrawerState({ ...leftDrawerState, isOpen: false })
  }

  const classes = useStyles({
    disableBottomSafeArea: disableBottomSafeArea ?? false,
    pixelsHeight: pixelsHeight,
  })
  return leftDrawerState.disable ? null : (
    <>
      <Hidden mdUp>
        <Backdrop className={classes.backdrop} open={leftDrawerState.isOpen} onClick={closeLeftDrawer} />
      </Hidden>
      <Paper
        className={clsx(classes.leftDrawer, leftDrawerState.isOpen ? classes.open : classes.closed)}
        elevation={3}
        square
      >
        <Hidden mdDown>
          <Box>
            <LeftDrawerButton />
          </Box>
        </Hidden>
        {showAppSwitcher && <AppSwitcher />}
        <Box
          className={classes.menuWrapper}
          sx={{
            width: leftDrawerState.isOpen ? 'auto' : '72px',
          }}
        >
          <Menu />
        </Box>
      </Paper>
    </>
  )
}

export default LeftDrawer
