import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { v4 } from 'uuid'

const useStyles = (initialOffset: number) =>
  makeStyles((theme) => ({
    left: {
      position: 'absolute',
      left: 24,
      bottom: 24,
      height: `calc(100% - ${48 + initialOffset}px)`,
      flexDirection: 'column-reverse',
      pointerEvents: 'none',
    },
    right: {
      position: 'absolute',
      right: 24,
      bottom: 24,
      height: `calc(100% - ${48 + initialOffset}px)`,
      flexDirection: 'column-reverse',
      pointerEvents: 'none',
    },
    bottom: {
      position: 'absolute',
      bottom: 24,
      left: 24,
      width: 'calc(100% - 192px)',
      pointerEvents: 'none',
    },
    top: {
      position: 'absolute',
      top: 24,
      left: 24,
      width: 'calc(100% - 192px)',
      pointerEvents: 'none',
    },
    wrapper: {
      transition: theme.transitions.create('bottom', {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  }))

export type MapButtonPanelRegion = 'bottom' | 'left' | 'top' | 'right'

type MapButtonPanelProps = {
  region: MapButtonPanelRegion
  startElements?: React.ReactElement[]
  endElements?: React.ReactElement[]
  bottomOffset?: number
}

export type ItemAlignment = 'start' | 'end'

export const MapButtonPanel: FC<React.PropsWithChildren<MapButtonPanelProps>> = (props) => {
  const { region, startElements, endElements } = props
  const bottomOffset = props.bottomOffset || 0
  const [panelId] = useState(v4())
  const classes = useStyles(bottomOffset)()
  const direction = region === 'left' || region === 'right' ? 'column' : 'row'
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      rowGap={2}
      className={clsx(classes[region], classes.wrapper)}
      marginBottom={bottomOffset + 'px'}
    >
      <Box display="flex" gap={2} flexDirection={direction} style={{ pointerEvents: 'all' }}>
        <>
          {startElements?.map((element, index) =>
            React.cloneElement(element, {
              key: 'panel_' + panelId + '_startElement_' + index,
              region,
              bottomoffset: bottomOffset,
            })
          )}
        </>
      </Box>
      <Box display="flex" gap={2} flexDirection={direction} style={{ pointerEvents: 'all' }}>
        <>
          {endElements?.map((element, index) =>
            React.cloneElement(element, { key: 'panel_' + panelId + '_endElement_' + index, region })
          )}
        </>
      </Box>
    </Box>
  )
}
