import { Box, Typography } from '@mui/material'

export type VesselLabelProps = {
  arrivalTime: string | null
  departureTime: string | null
  vesselName: string | null
  vesselLength: number | null
  vesselWidth: number | null
  vesselDepth?: number | null
}

export default function VesselLabel({
  arrivalTime,
  departureTime,
  vesselName,
  vesselDepth,
  vesselLength,
  vesselWidth,
}: VesselLabelProps) {
  console.log(vesselName)
  return (
    <Box bgcolor="#fff" borderRadius={2} pl={0.5} pr={0.5} display="flex" flexDirection="column">
      {vesselName && (
        <Typography color="black" alignSelf="center" variant="subtitle2">
          {vesselName}
        </Typography>
      )}

      <Box display="flex" alignItems="center" gap={0.5}>
        <>
          {arrivalTime && (
            <Typography color="black" variant="caption">
              {arrivalTime}
            </Typography>
          )}
          <Typography color="black" variant="caption">
            -
          </Typography>
          {departureTime && (
            <Typography color="black" variant="caption">
              {departureTime}
            </Typography>
          )}
        </>
      </Box>
    </Box>
  )
}
