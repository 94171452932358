import { GriegAuthProvider, RedirectLoginResult, useAuth } from '@griegconnect/krakentools-react-auth'
import { AlertDialog } from '@griegconnect/krakentools-react-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { configSelector } from '../atoms/configAtom'
import { isValidUrl } from '../utils/url'
import { useApplicationOptions } from '../ApplicationOptionsContext'

export type AuthWrapperProps = {
  children?: React.ReactNode
}

const initPathName = document.location.pathname
const initQueryParam = document.location.search

export const AuthWrapper = (props: AuthWrapperProps) => {
  const { children } = props
  const config = useRecoilValue(configSelector)
  const navigate = useNavigate()
  const [showChildren, setShowChildren] = useState(
    initPathName === '/' && initQueryParam.indexOf('?code=') === 0 ? false : true
  )

  const redirectCallback = (result: RedirectLoginResult) => {
    if (result?.appState?.targetUrl) {
      if (isValidUrl(result.appState.targetUrl)) {
        document.location.href = result.appState.targetUrl
      } else {
        navigate(result.appState.targetUrl, { replace: true })
      }
    } else {
      navigate(`${process.env.PUBLIC_URL}/`, { replace: true })
    }
    setShowChildren(true)
  }

  return (
    <GriegAuthProvider
      domain={config.auth.domain}
      scope={config.auth.scope}
      client_id={config.auth.clientId}
      redirect_uri={window.location.origin + (config.auth.redirectPath ? config.auth.redirectPath : '/')}
      audience={config.auth.audience}
      connection={config.auth.connection}
      onRedirectCallback={redirectCallback}
      useRefreshTokens={config.auth.rotatingRefreshTokens}
      cacheLocation={config.auth.cacheLocation}
    >
      {showChildren && (
        <>
          <AuthProviderErrorMessage />
          <AuthProviderSuccessMessage />
          {children}
        </>
      )}
    </GriegAuthProvider>
  )
}

const AuthProviderErrorMessage = () => {
  const config = useRecoilValue(configSelector)
  const { isAuthenticated, isInitializing, initError, logout } = useAuth()
  const { notAuthenticated } = useApplicationOptions()
  const [initialized, setInitialized] = useState(notAuthenticated ? false : true)

  useEffect(() => {
    if (!initialized && notAuthenticated && !isInitializing && !isAuthenticated) {
      setInitialized(true)
      notAuthenticated()
    }
  }, [initialized, notAuthenticated, isInitializing, isAuthenticated])

  return (
    <AlertDialog
      title={`An error occurred${initError ? ` (${initError.type})` : ''}`}
      onClose={() =>
        logout({
          client_id: config.auth.clientId,
          federated: true,
          returnTo: window.location.origin + (config.auth.redirectPath ?? '/'),
        })
      }
      closeButtonName="Close"
      closeButtonColor="primary"
      open={initError ? true : false}
      maxWidth="xs"
    >
      {initError ? initError.message : 'An unknown error occurred.'}
      <br />
      Contact your administrator if you need support.
    </AlertDialog>
  )
}

const AuthProviderSuccessMessage = () => {
  const { isAuthenticated, isInitializing, initMessage } = useAuth()
  const [initialized, setInitialized] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (!initialized && !isInitializing && !isAuthenticated && initMessage) {
      setInitialized(true)
      setShowMessage(true)
    }
  }, [initialized, initMessage, isInitializing, isAuthenticated])

  return (
    <AlertDialog
      title="Message from Grieg Connect"
      onClose={() => setShowMessage(false)}
      closeButtonName="Close"
      closeButtonColor="primary"
      open={showMessage}
      maxWidth="xs"
    >
      {initMessage}
    </AlertDialog>
  )
}

export default AuthWrapper
