import React from 'react'

import { Theme, Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type InlineDataGridSelectOptionProps = {
  itemLabel: React.ReactNode
  itemId: React.ReactNode
} & React.HTMLAttributes<HTMLLIElement>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    identifier: {
      color: theme.palette.grey[400],
    },
  })
)

export const InlineDataGridSelectOption = (props: InlineDataGridSelectOptionProps): JSX.Element => {
  const { itemLabel, itemId, ...rest } = props
  const classes = useStyles()

  return (
    <Box component="li" {...rest} key={`item-${itemId}`}>
      <Box className={classes.container}>
        <div>{itemLabel}</div>
        <div className={classes.identifier}>{itemId}</div>
      </Box>
    </Box>
  )
}
