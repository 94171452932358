import { BaseApi } from '../BaseApi'
import { GetQuayVisitResponseQuayVisit } from './dtos/GetQuayVisitResponseQuayVisit'

export default class PortGatewayApi extends BaseApi {
  async getQuayVisit(quayVisitId: number, abortSignal?: AbortSignal) {
    return await this.get<GetQuayVisitResponseQuayVisit>(`port-calls/v2/quay-visit/${quayVisitId}`, {
      signal: abortSignal,
    })
  }
}
