import { useEffect, useState } from 'react'

import { useAuth, useTenants } from '@griegconnect/krakentools-react-kraken-app'

import { MissingAuth } from '../../../components/MissingAuth'
import { MooringPlanMap } from '../../../modules/mooring-plan-map/MooringPlanMap'

export const MooringPlanPage = () => {
  const { getTokenSilently } = useAuth()

  const activeTenant = useTenants()
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const getTokenFunc = () =>
      getTokenSilently()
        .then((token) => {
          if (token) {
            setToken(token)
          }
        })
        .catch((e) => {
          console.error(e)
        })
    getTokenFunc()
    const interval = setInterval(getTokenFunc, 300_000)
    return () => {
      clearInterval(interval)
    }
  }, [getTokenSilently, setToken])

  if (!token || !activeTenant.activeTenant?.id) return <MissingAuth />

  return <MooringPlanMap accessToken={token} tenantId={activeTenant.activeTenant?.id} />
}
