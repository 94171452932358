import { Map as MapIcon } from '@griegconnect/krakentools-react-icons'
import { Navigation } from '@griegconnect/krakentools-react-kraken-app'

import { MapPage } from './MapPage'
import { PATH_TENANTS_BASE } from '../constants'
import { RoutePaths } from '../../../RoutePaths'
import { MooringPlanPage } from './MooringPlanPage'

export const mooringPlanRoute: Navigation.Item = {
  name: 'MooringPlan',
  displayName: 'MooringPlan',
  path: `${PATH_TENANTS_BASE}/${RoutePaths.mooringPlan}`,
  page: <MooringPlanPage />,
  exact: true,
  menu: false,
}

export const mapRoutes: Navigation.Item[] = [
  mooringPlanRoute,
  {
    name: 'Map',
    displayName: 'Map',
    path: '*',
    page: <MapPage />,
    exact: true,
    menu: {
      icon: <MapIcon />,
    },
  },
]
