import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgCompass = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M17 12 12 1 7 12l5 11 5-11Zm-1.667 0L12 19.333 8.667 12l3.333.72 3.333-.72Z"
      clipRule="evenodd"
    />
  )
export default SvgCompass
