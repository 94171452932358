import { AxiosInstance } from 'axios'
import { Lucene } from './types/Lucene'
import { AisStatic } from './types/AisStatic'

export type Observer = {
  id: number
  ref: string
}
export type SearchCategory = 'ports' | 'vessels'

type SearchVesselsArgs = {
  searchTerm: string
  /**
   * Should only be used if you didnt initialize the api with httpClient that have base url set to the correct tenant
   */
  tenantId?: string
  abortSignal?: AbortSignal
}

const fuzzyOptions = {
  fuzziness: 2,
  fuzzy_transpositions: true,
}

export class SearchitApi {
  httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  // Fuzzy search is only supported for single word queries
  fuzzyQueryTerm(query: string) {
    const query_term = query.trim()
    if (query_term.split(' ').length === 1) {
      return `${query_term}~`
    }
    return query_term
  }

  async query(
    term: string,
    searchCategories: SearchCategory[],
    abortSignal?: AbortSignal,
    results?: number,
    offset?: number
  ) {
    let query_term = this.fuzzyQueryTerm(term)
    let query
    if (searchCategories.includes('ports') && searchCategories.includes('vessels')) {
      query = {
        indices: [
          {
            app: 'ais-statics',
            name: 'statics',
          },
          {
            app: 'searchit',
            name: 'ports',
          },
        ],
        indices_boost: [
          {
            searchit_ports: 2.1,
          },
        ],
        must: {
          multi_match: {
            query: query_term,
            operator: 'and',
            type: 'bool_prefix',
            fields: ['name^3', 'payload.callsign', 'payload.imoText^2', 'payload.mmsiText^4', 'locode^2'],
            prefix_length: 2,
            ...fuzzyOptions,
          },
        },
      }
    } else if (searchCategories.includes('ports')) {
      query = {
        indices: [
          {
            app: 'searchit',
            name: 'ports',
          },
        ],
        indices_boost: [
          {
            searchit_ports: 1.1,
          },
        ],
        must: {
          multi_match: {
            query: query_term,
            operator: 'and',
            type: 'bool_prefix',
            fields: ['name^3', 'locode^2'],
            prefix_length: 2,
            ...fuzzyOptions,
          },
        },
      }
    } else if (searchCategories.includes('vessels')) {
      query = {
        indices: [
          {
            app: 'ais-statics',
            name: 'statics',
          },
        ],
        must: {
          multi_match: {
            query: query_term,
            operator: 'and',
            type: 'bool_prefix',
            fields: ['name^3', 'payload.callsign', 'payload.imoText^2', 'payload.mmsiText^4'],
            prefix_length: 2,
            ...fuzzyOptions,
          },
        },
      }
    }
    return await this.httpClient
      .post<SearchResponse>('indices', query, {
        headers: {
          'Content-Type': 'application/json',
        },
        signal: abortSignal,
      })
      .then((response) => response.data.hits.hits)
  }

  async searchVessels({
    searchTerm,
    tenantId,
    abortSignal,
  }: SearchVesselsArgs): Promise<Lucene.Search<AisStatic.Data>['hits']['hits']> {
    const endpointPath = (tenantId ? `/tenants/${tenantId}` : '') + `/indices/ais-statics/statics`
    const query_term = this.fuzzyQueryTerm(searchTerm)

    const resp = await this.httpClient.post<Lucene.Search<AisStatic.Data>>(
      endpointPath,
      {
        must: {
          multi_match: {
            query: query_term,
            operator: 'and',
            type: 'bool_prefix',
            fields: ['name^3', 'payload.callsign', 'payload.imoText^2', 'payload.mmsiText^4'],
            prefix_length: 2,
            ...fuzzyOptions,
          },
        },
      },
      {
        signal: abortSignal,
      }
    )

    return resp.data.hits.hits
  }
}

type SearchResponse = {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
}

type Hits = {
  total: Total
  max_score: number
  hits: Hit[]
}
type Hit = PortHit | StaticHit

export type PortHit = {
  _index: 'searchit_ports'
  _id: string
  _score: number
  _source: PortSource
}

export type StaticHit = {
  _index: 'ais-statics_statics'
  _id: string
  _score: number
  _source: StaticSource
}

export type PortSource = {
  id: string
  name: string
  labels: string[]
  dataType: 'port'
  tenant?: string
  coordinate: number[]
  country?: string
  locode?: string
  website?: string
  email?: string
}
export type StaticSource = {
  id: string
  name: string
  labels: string[]
  dataType: 'ais-static'
  tenant?: string
  coordinate?: number[]
  payload: Payload
}

type Payload = {
  meta: Meta
  mmsi: number
  name: string
  callsign: string
  shipType: number
  dims: number[]
  imo?: number
  destination?: string
  eta?: string
  posType?: number
  draught?: number
  version?: number
  dte?: number
  imoText?: string
  mmsiText?: string
}

type Meta = {
  raw: string
  feed: string
  senderTime: string
  systemTime: string
  aisClass: string
  channel: string
  messageId: number
  part?: any
}

type Total = {
  value: number
  relation: string
}

type Shards = {
  total: number
  successful: number
  skipped: number
  failed: number
}
