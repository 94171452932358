import { GriegThemeProvider } from '@griegconnect/krakentools-react-ui'
import { PaletteMode } from '@mui/material'
import { LocalStorageKeys } from '../../localStorageKeys'

export type ThemeWrapperProps = {
  children?: React.ReactNode
}

const getPaletteType = (theme: string | null): PaletteMode => (theme === 'dark' ? 'dark' : 'light')

export const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const theme = localStorage.getItem(LocalStorageKeys.themePalette)

  return (
    <GriegThemeProvider
      useScopedCssBaseline={false}
      tool={'port'}
      overrideGlobalStyles={true}
      paletteType={getPaletteType(theme)}
    >
      {children}
    </GriegThemeProvider>
  )
}

export default ThemeWrapper
