import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecoilRoot } from 'recoil'

import { LocalStorageKeys } from './localStorageKeys'
import { ApplicationProviderApp } from './setup/application-provider-app/ApplicationProviderApp'
import { LocalStorageApp } from './setup/local-storage-app/LocalStorageApp'

const queryClient = new QueryClient()

function App() {
  const localStorageAuth = localStorage.getItem(LocalStorageKeys.accessToken)

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>{localStorageAuth ? <LocalStorageApp /> : <ApplicationProviderApp />}</RecoilRoot>
    </QueryClientProvider>
  )
}

export default App
