import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, Typography } from '@mui/material'
import { useMapContext } from '../../MapContext'
import { LayerControlCategory } from './LayerControlCategory'
import makeStyles from '@mui/styles/makeStyles'
import { MonitorMap } from '../../apis/MonitorApi'
import { useRecoilState } from 'recoil'
import { activeMapIdSelector } from '../../atoms/mapConfigAtoms'

const useStyles = makeStyles((theme) => ({
  text: {
    paddingLeft: theme.spacing(1.5),
    opacity: 0.6,
    pointerEvents: 'none',
  },
  actions: {
    right: theme.spacing(1),
  },
  radio: {
    padding: theme.spacing(1),
  },
}))

type MapItemProps = {
  mapConfig: MonitorMap
  active: boolean
  onSelectMap: (mapId: string) => void
}

const MapItem = (props: MapItemProps) => {
  const { mapConfig, active, onSelectMap } = props
  const classes = useStyles()
  return (
    <ListItem>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textPrimary',
          component: 'div',
        }}
      >
        <Typography className={classes.text}>{mapConfig.name}</Typography>
      </ListItemText>
      <ListItemSecondaryAction className={classes.actions}>
        <Box display="flex">
          <Radio
            checked={active}
            value={mapConfig.id}
            onChange={() => onSelectMap(mapConfig.id)}
            className={classes.radio}
          />
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export const MapLayerControl = () => {
  const { mapIdentifierSlug, maps, kmapInstance } = useMapContext()
  const [activeMapId, setActiveMapId] = useRecoilState(activeMapIdSelector(mapIdentifierSlug))

  return (
    <LayerControlCategory title="Maps" startCollapsed={true}>
      <List component="div" disablePadding>
        {maps?.map((mapConfig, index) => (
          <MapItem
            key={mapIdentifierSlug + '_' + kmapInstance.mapElementId + '_map_' + mapConfig.id + '_id_' + index}
            mapConfig={mapConfig}
            active={mapConfig.id === activeMapId}
            onSelectMap={setActiveMapId}
          />
        ))}
      </List>
    </LayerControlCategory>
  )
}
