import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgGpsFixedOn = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z" />,
    <path d="M20.945 13A9.004 9.004 0 0 1 13 20.945V22a1 1 0 1 1-2 0v-1.055A9.004 9.004 0 0 1 3.055 13H2a1 1 0 1 1 0-2h1.055A9.004 9.004 0 0 1 11 3.055V2a1 1 0 1 1 2 0v1.055A9.004 9.004 0 0 1 20.945 11H22a1 1 0 1 1 0 2h-1.055ZM12 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z" />
  )
export default SvgGpsFixedOn
