import { atom } from 'recoil'
import { AlertColor } from '@mui/material'

type GlobalAlertAtom = {
  type: GlobalAlertTypes
  message: string
  closeable: boolean
  action?: React.ReactNode
}

export type GlobalAlertTypes = 'info' | 'error'

export const globalAlertAtom = atom<GlobalAlertAtom | null>({
  key: 'globalAlertAtomState',
  default: null,
})
