import { MutableRefObject, ReactNode } from 'react'

import { Edit, Cancel, Save, Delete, WarningRounded } from '@mui/icons-material'
import {
  GridActionsCellItem,
  GridColDef,
  GridEditInputCellProps,
  GridEditRowProps,
  GridEnrichedColDef,
  GridNativeColTypes,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { DateTime } from 'luxon'

import { AsyncSelectOption } from '../autocomplete/AutocompleteAsync'
import {
  InlineDataGridBoolean,
  InlineDataGridEditBoolean,
  RenderBooleanValueProps,
} from './components/InlineDataGridBoolean'
import { InlineDataGridDateTime } from './components/InlineDataGridDateTime'
import { InlineDataGridInput } from './components/InlineDataGridInput'
import InlineDataGridNumberInput, { InlineDataGridNumberInputProps } from './components/InlineDataGridNumberInput'
import { InlineDataGridSelect, InlineDataGridSelectProps } from './components/InlineDataGridSelect'
import InlineEditDataGridActionsMenu, {
  InlineEditDataGridActionsProps,
} from './row-actions/InlineEditDataGridActionsMenu'
import { InlineEditDataGridCommands } from './row-actions/inlineEditDataGridCommands'
import React from 'react'

type SelectType = 'inlineSingleSelect' | 'status'
type NonSelectType = 'string' | 'date' | 'dateTime' | 'singleSelect'
type NumberType = 'number'
type BooleanType = 'boolean'

export type CellEditRenderType<Row extends GridValidRowModel> = GridRenderEditCellParams<any, Row>

type ExtendedGridColDef<Row extends GridValidRowModel> = Omit<GridColDef<Row>, 'renderEditCell'> & {
  /**
   * Allows to override the component rendered in edit cell mode for this column.
   * @param {GridRenderEditCellParams} params Object containing parameters for the renderer.
   * @returns {React.ReactNode} The element to be rendered.
   */
  renderEditCell?: (props: CellEditRenderType<Row>) => ReactNode
}

export type CustomEditComponentProps<Row extends GridValidRowModel = GridValidRowModel> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: CellEditRenderType<Row>
  required?: boolean
  disabled?: boolean
  autoFocus?: boolean
} & Pick<GridEditInputCellProps, 'endAdornment'>

export type InLineEditDataGridColumn<Row extends GridValidRowModel> = ExtendedGridColDef<Row> &
  RequiredColumn &
  InlineDataGridTypes

export type RequiredColumn = {
  required?: boolean
  isZeroInValid?: boolean
}

type InlineDataGridSelectColumnProps = Omit<InlineDataGridSelectProps, 'props'>

type InlineDataGridTypes =
  | { inlineGridType?: SelectType; selectProps: InlineDataGridSelectColumnProps }
  | { inlineGridType?: NonSelectType; selectProps?: null }
  | { inlineGridType?: BooleanType; booleanProps?: RenderBooleanValueProps }
  | { inlineGridType?: NumberType; numberProps?: InlineDataGridNumberInputProps }

export interface CommonInlineGridConfigReturnType<Row extends GridValidRowModel> {
  required?: boolean
  renderEditCell?: (props: CellEditRenderType<Row>) => ReactNode
  renderCell?: (params: GridRenderCellParams) => ReactNode
  valueFormatter?: (params: GridValueFormatterParams) => unknown
}

export type InlineGridSelectConfigType<Row extends GridValidRowModel> = CommonInlineGridConfigReturnType<Row> & {
  selectProps: InlineDataGridSelectColumnProps
}

export function isEmptyObject(obj: Record<string, unknown> | undefined) {
  if (!obj) return true
  let name: string
  for (name in obj) {
    if (obj.hasOwnProperty(name)) {
      return false
    }
  }
  return true
}

export const setEditCellValue = (apiRef: MutableRefObject<GridApiPro>, id: GridRowId, field: string, value: unknown) =>
  apiRef.current.setEditCellValue({ id, field, value })

export const isValueInValid = (value: unknown, isZeroInValid?: boolean) => {
  return DateTime.isDateTime(value)
    ? !value.isValid
    : Array.isArray(value)
    ? value.length === 0
    : value === '' || value === undefined || value === null || (isZeroInValid && value === 0)
}

export const optionTypeValueFormatter = ({ value }: GridValueFormatterParams, options: AsyncSelectOption[]) =>
  options.find((opt) => opt.value === value)?.label || value

export const commonInlineGridSelectConfig = <Row extends GridValidRowModel>(
  params: InlineGridSelectConfigType<Row>
): InlineGridSelectConfigType<Row> => {
  return {
    valueFormatter: params.valueFormatter
      ? params.valueFormatter
      : isFunction(params.selectProps.selectOptionConfig.options)
      ? undefined
      : (props) =>
          optionTypeValueFormatter(props, params.selectProps.selectOptionConfig.options as AsyncSelectOption[]),
    renderEditCell: params.renderEditCell
      ? params.renderEditCell
      : (props) => <InlineDataGridSelect props={props} {...params.selectProps} />,
    renderCell: params.renderCell
      ? params.renderCell
      : (props) => <InlineDataGridSelect props={props} {...params.selectProps} readOnly />,
    selectProps: params.selectProps,
  }
}

interface InlineGridColumns<Row extends GridValidRowModel> {
  rowModesModel: GridRowModesModel
  rowsAffectedList: (string | number)[]
  affectedFieldIdentifier?: string
  disableEdit?: boolean
  disableDelete?: boolean
  handleEditClick: (id: GridRowId, row: Row) => void
  handleSaveClick: (id: GridRowId) => void
  handleCancelClick: (id: GridRowId) => void
  handleDeleteClick: (id: GridRowId) => void
  actionMenuProps?: InlineEditDataGridActionsProps<InlineEditDataGridCommands, Row>
}

export function inlineGridColumns<Row extends GridValidRowModel>({
  rowModesModel,
  rowsAffectedList,
  affectedFieldIdentifier = '',
  disableEdit,
  disableDelete,
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleSaveClick,
  actionMenuProps,
}: InlineGridColumns<Row>): GridEnrichedColDef<Row>[] {
  return [
    {
      field: 'multipleEditedColumn',
      headerName: '',
      renderCell: ({ row }) => {
        const isAffected = rowsAffectedList.includes(row[affectedFieldIdentifier])
        return isAffected ? <WarningRounded color="warning" /> : null
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      cellClassName: 'actions',
      editable: false,
      getActions: ({ row, id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        if (isInEditMode) {
          return [
            <GridActionsCellItem key={`${id}-save`} icon={<Save />} label="Save" onClick={() => handleSaveClick(id)} />,
            <GridActionsCellItem
              key={`${id}-cancel`}
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }
        if (actionMenuProps) {
          return [
            <GridActionsCellItem
              key={`${id}-edit`}
              icon={<Edit />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id, row)}
              color="inherit"
              disabled={disableEdit}
            />,
            <InlineEditDataGridActionsMenu key={`${id}-actions-menu`} row={row} actionMenuProps={actionMenuProps} />,
          ]
        }
        return [
          <GridActionsCellItem
            sx={{
              display: disableEdit ? 'none' : 'inline-flex',
            }}
            key={`${id}-edit`}
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id, row)}
            color="inherit"
            disabled={disableEdit}
          />,
          <GridActionsCellItem
            sx={{
              display: disableDelete ? 'none' : 'inline-flex',
            }}
            key={`${id}-delete`}
            icon={<Delete />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="error"
          />,
        ]
      },
    },
  ]
}

export function columnCreator<Row extends GridValidRowModel>(
  column: InLineEditDataGridColumn<Row>
): InLineEditDataGridColumn<Row> {
  const createdColumn: InLineEditDataGridColumn<Row> = {
    align: 'left',
    headerAlign: 'left',
    editable: true,
    ...column,
  }

  return createdColumn
}

export const getFormattedDate = (date: string | DateTime) => {
  if (date === '') return ''
  const typedDate =
    typeof date == 'string' ? DateTime.fromISO(date) : DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)

  return typedDate.isValid ? typedDate.toLocaleString(DateTime.DATE_SHORT) : ''
}

export const getFormattedDateTime = (date: string | DateTime) => {
  if (date === '') return ''
  const typedDate =
    typeof date == 'string' ? DateTime.fromISO(date) : DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date)

  return typedDate.isValid ? typedDate.toLocaleString(DateTime.DATETIME_SHORT) : ''
}

export function getDefaultColumnConfig<Row extends GridValidRowModel>(
  column: InLineEditDataGridColumn<Row>
): (CommonInlineGridConfigReturnType<Row> & { type: GridNativeColTypes }) | undefined {
  switch (column.inlineGridType) {
    case 'boolean':
      return {
        type: 'boolean',
        renderCell: column.renderCell
          ? column.renderCell
          : ({ row }) => (
              <InlineDataGridBoolean
                tooltipTitle={column.booleanProps?.tooltipTitle}
                labels={column.booleanProps?.labels}
                value={row[column.field]}
              />
            ),
        renderEditCell: column.renderEditCell
          ? column.renderEditCell
          : (props) => <InlineDataGridEditBoolean props={props} />,
      }
    case 'date':
      return {
        type: 'date',
        valueFormatter: column.valueFormatter ? column.valueFormatter : ({ value }) => getFormattedDate(value),
        renderEditCell: column.renderEditCell
          ? column.renderEditCell
          : (props) => <InlineDataGridDateTime props={props} type="Date" required={column.required} />,
      }
    case 'dateTime':
      return {
        type: 'dateTime',
        valueFormatter: column.valueFormatter ? column.valueFormatter : ({ value }) => getFormattedDateTime(value),
        renderEditCell: column.renderEditCell
          ? column.renderEditCell
          : (props) => <InlineDataGridDateTime props={props} type="DateTime" required={column.required} />,
      }
    case 'inlineSingleSelect':
      return {
        type: 'singleSelect',
        ...commonInlineGridSelectConfig({
          ...column,
          selectProps: {
            ...column.selectProps,
            required: column.required,
            isZeroInvalid: column.isZeroInValid,
          },
        }),
      }
    case 'number':
      return {
        type: 'number',
        renderEditCell: column.renderEditCell
          ? column.renderEditCell
          : (props) => (
              <InlineDataGridNumberInput props={props} locale={column.numberProps?.locale} required={column.required} />
            ),
      }
    case 'string':
      return {
        type: 'string',
        renderEditCell: column.renderEditCell
          ? column.renderEditCell
          : (props) => <InlineDataGridInput props={props} required={column.required} />,
      }
    case 'singleSelect':
      return {
        type: 'singleSelect',
      }
    default:
      return undefined
  }
}

export const isNewItem = (id: string | number) => id.toString().startsWith('-')

export const reWriteNullOrEmptyValues = <T,>(obj: T, type: 'fromNullToEmpty' | 'fromEmptyToNull'): T => {
  if (type === 'fromNullToEmpty') return JSON.parse(JSON.stringify(obj, (_k, v) => (v === null ? '' : v)))

  return JSON.parse(JSON.stringify(obj, (_k, v) => (v === '' ? null : v)))
}

export const mapEditStateToRow = <Row,>(editState: GridEditRowProps, defaultRow: Row): Row => {
  let row = { ...defaultRow }
  Object.keys(editState).forEach((field) => (row[field] = editState[field].value))

  row = reWriteNullOrEmptyValues(row, 'fromEmptyToNull')
  return row
}

export const isFunction = (v: any) => {
  if (v instanceof Function) {
    return true
  } else {
    return false
  }
}
