import { Navigation, useTenants } from '@griegconnect/krakentools-react-kraken-app'

import { mapRoutes } from '../modules/MapRoutes'

export const portNavigation = (): Navigation.Item[] => [...mapRoutes]

const NavigationRoutes = () => {
  const { activeTenant } = useTenants()

  if (!activeTenant) return null

  return <Navigation navigation={portNavigation()} dependencies={{ activeTenant: activeTenant }} />
}

export default NavigationRoutes
