import { ApplicationProvider, TenantContextWrapper } from '@griegconnect/krakentools-react-kraken-app'

import { PATH_TENANTS_BASE, PATH_TENANTS_MATCH_PARAM, TENANTS_APP_REF } from './constants'
import NavigationRoutes from './navigation/NavigationRoutes'

import { Application, ConfigType, ITenantAccess, Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { ApiServiceProviderWrapper } from './ApiServiceProviderWrapper'

export const filterAccess = (
  userTenantAccess: ITenantAccess[],
  _config: ConfigType,
  applications: Application.Data[]
) => {
  const application = applications.find((as) => as.ref === TENANTS_APP_REF)
  if (application) {
    return userTenantAccess.reduce<Tenant.Type[]>((acc, access) => {
      if (
        application.accessThrough.length === 0 ||
        application.accessThrough.find((at) => at.ref === access.tenant.ref)
      ) {
        const allAccessHintsFulfilled = application.accessHints.reduce<boolean>((bool, hint) => {
          if (bool === false) {
            return bool
          }

          const directFulfillment = access.permissions?.find((p) => p.domain === hint.domain && p.name === hint.name)
            ? true
            : false

          const behalfOfFulfillment = access.companies.reduce<boolean>((bool, company) => {
            if (company.permissions?.find((p) => p.domain === hint.domain && p.name === hint.name) || bool === true) {
              return true
            }
            return bool
          }, false)

          return directFulfillment || behalfOfFulfillment
        }, true)

        if (allAccessHintsFulfilled) {
          acc.push({
            id: access.tenant.id,
            name: access.tenant.name,
            permissions: access.permissions ?? [],
            ref: access.tenant.ref,
            companies: access.companies.map((c) => ({ ...c.company, permissions: c.permissions ?? [] })),
          })
        }
      }
      return acc
    }, [])
  } else {
    return []
  }
}

export const ApplicationProviderApp = () => {
  return (
    <ApplicationProvider
      name="Port"
      tool={TENANTS_APP_REF}
      appRef={TENANTS_APP_REF}
      confirmInvitation
      tenantsUrlMatchPathPrefix={PATH_TENANTS_BASE}
      tenantsUrlMatchPathParam={PATH_TENANTS_MATCH_PARAM}
      reloadOnTenantsChange
      applicationSwitcher
      filterUserAccess={{ type: 'app-spec', callback: filterAccess }}
      i18n={{ languages: ['en-GB', 'nb-NO', 'sv-SE', 'fi-FI'] }}
      paletteMode="light"
      onboarding={{
        disableCreateAccount: true,
        carouselElements: [
          {
            component: null,
            title: 'Port Go',
            description: 'Visit our map',
          },
        ],
      }}
    >
      <TenantContextWrapper autoSelect selectTenantType="page" force>
        <ApiServiceProviderWrapper>
          <NavigationRoutes />
        </ApiServiceProviderWrapper>
      </TenantContextWrapper>
    </ApplicationProvider>
  )
}
