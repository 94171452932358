import { PaletteMode } from '@mui/material'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { themeConfigurationAtom } from '../atoms/themeAtom'

type UseUserInterfaceType = {
  hideLeftDrawer: () => void
  showLeftDrawer: () => void
  palette: PaletteMode
  setPalette: (palette: PaletteMode) => void
}

export const useUserInterface = (): UseUserInterfaceType => {
  const setLeftDrawerState = useSetRecoilState(leftDrawerAtom)
  const [themeConfiguration, setThemeConfiguration] = useRecoilState(themeConfigurationAtom)

  const hideLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: true }))
  }

  const showLeftDrawer = () => {
    setLeftDrawerState((currVal) => ({ ...currVal, disable: false }))
  }

  const setPalette = (palette: PaletteMode) => {
    setThemeConfiguration((currVal) => ({ ...currVal, palette }))
  }

  return {
    hideLeftDrawer,
    showLeftDrawer,
    palette: themeConfiguration.palette,
    setPalette,
  }
}
