import React from 'react'

import { ListItemIcon, ListItemText, MenuItem, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

type Props = {
  label: string
  icon?: React.ReactNode
  onClick: () => void
  className?: string
  testid?: string
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  shortCut: {
    marginLeft: theme.spacing(4),
  },
}))

export const PopperMenuItem: React.FC<React.PropsWithChildren<Props>> = ({ label, icon, onClick, className, testid, disabled }) => {
  const classes = useStyles()

  let shortCut: string | null = null

  const shortCutIndex = label.indexOf('$')
  if (shortCutIndex !== -1) {
    label = label.substring(0, shortCutIndex)
    shortCut = label.substring(shortCutIndex).replace(/\$/g, '')
  }

  return (
    <MenuItem
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      className={className}
      data-testid={testid}
      disabled={disabled}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{label}</ListItemText>
      {shortCut && (
        <Typography variant="body2" color="text.secondary" className={classes.shortCut}>
          {shortCut}
        </Typography>
      )}
    </MenuItem>
  )
}
