import { Applications as ApplicationsIcon } from '@griegconnect/krakentools-react-icons'
import { Button, Fade, Hidden, IconButton, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { leftDrawerAtom } from '../atoms/leftDrawerAtom'
import { useApplicationsQuery } from '../hooks'
import ApplicationLogo from './ApplicationLogo'
import AppSwitcherDialog from './AppSwitcherDialog'
import AppSwitcherGrid from './AppSwitcherGrid'
import AppSwitcherPopper from './AppSwitcherPopper'

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      width: '320px',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'stretch',
      justifyContent: 'space-between',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
      },
    },
    button: {
      flexBasis: theme.spacing(6),
      flexShrink: 0,
      margin: theme.spacing(2),
    },
  })
)

const AppSwitcher = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const leftDrawerState = useRecoilValue(leftDrawerAtom)
  const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLDivElement | HTMLElement>(null)
  const appSwitcherButtonRef = useRef(null)
  const hiddenLogoSymbolElmRef = useRef(null)
  const { data, isLoading, isError, refetch } = useApplicationsQuery()
  const handleClose = (_event: MouseEvent | TouchEvent | {}) => {
    setPopperAnchorEl(null)
    setOpen(false)
  }

  const openAppSwitcher = (event: React.MouseEvent<HTMLDivElement | HTMLElement, MouseEvent>) => {
    setPopperAnchorEl(leftDrawerState.isOpen ? appSwitcherButtonRef!.current : hiddenLogoSymbolElmRef!.current)
    setOpen(true)
  }

  const QueryErrorUi = (!isLoading || isError) && data === undefined && (
    <>
      <Typography color="error" variant="body2">
        Could not fetch applications. Try again?
      </Typography>
      <Button onClick={() => refetch()} variant="outlined" color="primary" size="small">
        Try again
      </Button>
    </>
  )

  return (
    <>
      <div className={classes.wrapper} onClick={openAppSwitcher}>
        <ApplicationLogo ref={hiddenLogoSymbolElmRef} />
        <div className={classes.button} ref={appSwitcherButtonRef}>
          <Fade in={leftDrawerState.isOpen} timeout={{ enter: 1000, exit: 0 }}>
            <IconButton size="large">
              <ApplicationsIcon />
            </IconButton>
          </Fade>
        </div>
      </div>
      <Hidden mdUp>
        <AppSwitcherDialog open={open} onClose={handleClose}>
          <AppSwitcherGrid applications={data}>{QueryErrorUi}</AppSwitcherGrid>
        </AppSwitcherDialog>
      </Hidden>
      <Hidden mdDown>
        <AppSwitcherPopper anchorEl={popperAnchorEl} onClickAway={handleClose}>
          <AppSwitcherGrid applications={data}>{QueryErrorUi}</AppSwitcherGrid>
        </AppSwitcherPopper>
      </Hidden>
    </>
  )
}

export default AppSwitcher
