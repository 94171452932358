import { AssetsView, DeferredMapComponents, LiveView, MapProvider } from '@griegconnect/krakentools-react-kmap'
import { useConfig } from '@griegconnect/krakentools-react-kraken-app'

import { LiveMapController } from './LiveMapController'
import { RouterParamsHandler } from './RouterParamsHandler'

type MapProps = {
  accessToken: string
  tenantId: string
}

export const Map = ({ accessToken, tenantId }: MapProps) => {
  const config = useConfig()
  const mapIdentifierSlug = `port-go-map-${tenantId}`

  return (
    <MapProvider
      mapIdentifierSlug={mapIdentifierSlug}
      activeTenantId={tenantId}
      useStorage={false}
      monitorApiBaseUrl={config.api.monitorApiBaseUrl}
      getToken={async () => accessToken}
    >
      <LiveMapController tenantId={tenantId} />
      <RouterParamsHandler mapIdentifierSlug={mapIdentifierSlug} />
      <DeferredMapComponents>
        <LiveView
          liveApiBaseUrl={config.api.positionsApiBaseUrl}
          tenantId={tenantId}
          defaultLayersToVisible
          enableDialogs
        />

        <AssetsView assetApiBaseUrl={config.api.assetsApiBaseUrl} tenantId={tenantId ?? null} enableDialogs />
      </DeferredMapComponents>
    </MapProvider>
  )
}
