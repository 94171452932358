import { PaletteMode } from '@mui/material'
import { ThemeOptions } from '@mui/material/styles'

const getPortalContainer = () => document.fullscreenElement ?? document.body

const sharedThemeOptions = (palette: PaletteMode, color: string, linkColor: string): ThemeOptions => {
  const rgbWhiteOrBlack = palette === 'light' ? '0, 0, 0' : '255, 255, 255'
  const muiButtonSecondary = {
    color: `rgba(${rgbWhiteOrBlack}, 0.87)`,
    borderColor: `rgba(${rgbWhiteOrBlack}, 0.08)`,
    '&:hover': {
      borderColor: `rgba(${rgbWhiteOrBlack}, 0.87)`,
    },
  }

  return {
    shadows: [
      'none',
      '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 7%), 0px 1px 3px 0px rgb(0 0 0 / 6%)',
      '0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 6%)',
      '0px 3px 3px -2px rgb(0 0 0 / 10%), 0px 3px 4px 0px rgb(0 0 0 / 7%), 0px 1px 8px 0px rgb(0 0 0 / 6%)',
      '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
      '0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 6%)',
      '0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 7%), 0px 1px 18px 0px rgb(0 0 0 / 6%)',
      '0px 4px 5px -2px rgb(0 0 0 / 10%), 0px 7px 10px 1px rgb(0 0 0 / 7%), 0px 2px 16px 1px rgb(0 0 0 / 6%)',
      '0px 5px 5px -3px rgb(0 0 0 / 10%), 0px 8px 10px 1px rgb(0 0 0 / 7%), 0px 3px 14px 2px rgb(0 0 0 / 6%)',
      '0px 5px 6px -3px rgb(0 0 0 / 10%), 0px 9px 12px 1px rgb(0 0 0 / 7%), 0px 3px 16px 2px rgb(0 0 0 / 6%)',
      '0px 6px 6px -3px rgb(0 0 0 / 10%), 0px 10px 14px 1px rgb(0 0 0 / 7%), 0px 4px 18px 3px rgb(0 0 0 / 6%)',
      '0px 6px 7px -3px rgb(0 0 0 / 10%), 0px 11px 15px 1px rgb(0 0 0 / 7%), 0px 4px 20px 3px rgb(0 0 0 / 6%)',
      '0px 7px 8px -4px rgb(0 0 0 / 10%), 0px 12px 17px 2px rgb(0 0 0 / 7%), 0px 5px 22px 4px rgb(0 0 0 / 6%)',
      '0px 7px 8px -4px rgb(0 0 0 / 10%), 0px 13px 19px 2px rgb(0 0 0 / 7%), 0px 5px 24px 4px rgb(0 0 0 / 6%)',
      '0px 7px 9px -4px rgb(0 0 0 / 10%), 0px 14px 21px 2px rgb(0 0 0 / 7%), 0px 5px 26px 4px rgb(0 0 0 / 6%)',
      '0px 8px 9px -5px rgb(0 0 0 / 10%), 0px 15px 22px 2px rgb(0 0 0 / 7%), 0px 6px 28px 5px rgb(0 0 0 / 6%)',
      '0px 8px 10px -5px rgb(0 0 0 / 10%), 0px 16px 24px 2px rgb(0 0 0 / 7%), 0px 6px 30px 5px rgb(0 0 0 / 6%)',
      '0px 8px 11px -5px rgb(0 0 0 / 10%), 0px 17px 26px 2px rgb(0 0 0 / 7%), 0px 6px 32px 5px rgb(0 0 0 / 6%)',
      '0px 9px 11px -5px rgb(0 0 0 / 10%), 0px 18px 28px 2px rgb(0 0 0 / 7%), 0px 7px 34px 6px rgb(0 0 0 / 6%)',
      '0px 9px 12px -6px rgb(0 0 0 / 10%), 0px 19px 29px 2px rgb(0 0 0 / 7%), 0px 7px 36px 6px rgb(0 0 0 / 6%)',
      '0px 10px 13px -6px rgb(0 0 0 / 10%), 0px 20px 31px 3px rgb(0 0 0 / 7%), 0px 8px 38px 7px rgb(0 0 0 / 6%)',
      '0px 10px 13px -6px rgb(0 0 0 / 10%), 0px 21px 33px 3px rgb(0 0 0 / 7%), 0px 8px 40px 7px rgb(0 0 0 / 6%)',
      '0px 10px 14px -6px rgb(0 0 0 / 10%), 0px 22px 35px 3px rgb(0 0 0 / 7%), 0px 8px 42px 7px rgb(0 0 0 / 6%)',
      '0px 11px 14px -7px rgb(0 0 0 / 10%), 0px 23px 36px 3px rgb(0 0 0 / 7%), 0px 9px 44px 8px rgb(0 0 0 / 6%)',
      '0px 11px 15px -7px rgb(0 0 0 / 10%), 0px 24px 38px 3px rgb(0 0 0 / 7%), 0px 9px 46px 8px rgb(0 0 0 / 6%)',
    ],
    components: {
      MuiDataGrid: {
        defaultProps: {
          headerHeight: 34,
        },
        styleOverrides: {
          root: {
            border: 'none',
            padding: '24px 24px 0',
          },
          columnSeparator: {
            display: 'none',
            color: 'inherit',
          },
          cell: {
            borderBottom: `1px solid rgba(${rgbWhiteOrBlack}, 0.08)`,
          },
          columnHeaders: {
            borderBottom: `1px solid rgba(${rgbWhiteOrBlack}, 0.08)`,
            paddingBottom: 4,
            '.MuiDataGrid-withBorder': {
              borderRight: 'none',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '2.125rem',
            fontWeight: 400,
            lineHeight: 1.235,
            letterSpacing: '0.00735em',
          },
          h2: {
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.334,
            letterSpacing: '0em',
          },
          h3: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
          },
          h4: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
          },
          h5: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
          },
          h6: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: color,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:after': {
              borderBottomColor: color,
            },
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
              borderBottomColor: color,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          underline: {
            '&:after': {
              borderBottomColor: color,
            },
            '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
              borderBottomColor: color,
            },
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          container: getPortalContainer,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: getPortalContainer,
        },
      },
      MuiDrawer: {
        defaultProps: {
          container: getPortalContainer,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: linkColor,
            textDecoration: 'none',
            '&.MuiTypography-colorPrimary': {
              color: linkColor,
            },
            '&.MuiTypography-colorSecondary': {
              color: linkColor,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlinedInherit: muiButtonSecondary,
          outlinedSecondary: muiButtonSecondary,
          outlinedSizeSmall: {
            textTransform: 'initial',
            fontWeight: 400,
            padding: '0px 8px',
            '& .MuiButton-startIcon': {
              marginRight: 4,
              marginLeft: -6,
            },
            '& .MuiButton-endIcon': {
              marginLeft: 4,
              marginRight: -6,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiFilledInput-root:before': {
              borderBottom: `1px solid rgba(${rgbWhiteOrBlack}, 0.08) `,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          disableUnderline: true,
          variant: 'filled',
          sx: {
            borderRadius: 1,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          container: {
            paddingTop: 32,
            alignItems: 'flex-start',
          },
        },
        defaultProps: {
          container: getPortalContainer,
        },
      },
    },
  }
}

export default sharedThemeOptions
