import { Error as ErrorIcon } from '@griegconnect/krakentools-react-icons'
import { Alert } from '@mui/material'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { globalAlertAtom } from '../../atoms/globalInfoAtom'

export const GlobalAlert = () => {
  const [globalAlert, setGlobalAlert] = useRecoilState(globalAlertAtom)

  const onCloseHandler = useCallback(() => {
    setGlobalAlert(null)
  }, [setGlobalAlert])

  return globalAlert ? (
    <Alert
      variant="filled"
      severity={globalAlert.type}
      icon={<ErrorIcon />}
      onClose={globalAlert.closeable ? onCloseHandler : undefined}
      sx={{ borderRadius: 0 }}
      action={globalAlert.action}
    >
      {globalAlert.message}
    </Alert>
  ) : null
}

export default GlobalAlert
