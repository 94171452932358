import React, { MutableRefObject, useCallback } from 'react'

import { styled } from '@mui/styles'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'

import { CustomEditComponentProps, isValueInValid, setEditCellValue } from '../Utils'
import NumberInput, { ILocalizedNumberInputProps } from '../../input/LocalizedNumberInput'

const StyledNumberInput = styled(NumberInput)({
  '& .Mui-disabled': {
    backgroundColor: '#EAEAEA',
  },
})

export type InlineDataGridNumberInputProps = CustomEditComponentProps & {
  precision?: number
  minValue?: number
  maxValue?: number
  overrideOnChange?: (apiRef: MutableRefObject<GridApiPro>, newValue: number | null) => void | Promise<void>
} & Pick<ILocalizedNumberInputProps, 'locale'>

const InlineDataGridNumberInput = ({
  props,
  required,
  disabled,
  autoFocus,
  precision,
  minValue,
  maxValue,
  endAdornment,
  locale,
  overrideOnChange,
}: InlineDataGridNumberInputProps) => {
  const apiRef = useGridApiContext()

  const handleValueChange = useCallback(
    async (newValue: number | null) => {
      if (overrideOnChange) await overrideOnChange(apiRef, newValue)
      else setEditCellValue(apiRef, props.id, props.field, newValue)
    },
    [apiRef, overrideOnChange, props.field, props.id]
  )
  const error = required ? isValueInValid(props.value) : false

  return (
    <StyledNumberInput
      {...props}
      value={props.value}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      precision={precision}
      maxValue={maxValue}
      minValue={minValue}
      error={error}
      onChange={handleValueChange}
      locale={locale}
      endAdornment={endAdornment}
    />
  )
}

export default InlineDataGridNumberInput
