import { useEffect } from 'react'

import { mapCenterSelector, mapZoomLevelSelector } from '@griegconnect/krakentools-react-kmap'
import { Route, Routes } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { useMapSearchParams } from '../../hooks/useMapSearchParams'
import { LocalStorageKeys } from '../../localStorageKeys'

export enum MapUrlParams {
	lat = 'lat',
	lon = 'lon',
	zoom = 'zoom',
}

type RouterParamsHandlerProps = {
	mapIdentifierSlug: string
}
export const RouterParamsHandler = ({ mapIdentifierSlug }: RouterParamsHandlerProps) => {
	return (
		<Routes>
			<Route
				path='/:lat/:lon/:zoom/'
				element={<RouterLatLonZoomHandler mapIdentifierSlug={mapIdentifierSlug} />}
			/>
			<Route path='*' element={<RouterLatLonZoomHandler mapIdentifierSlug={mapIdentifierSlug} />} />
		</Routes>
	)
}

const RouterLatLonZoomHandler = ({ mapIdentifierSlug }: RouterParamsHandlerProps) => {
	const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelSelector(mapIdentifierSlug))
	const [center, setCenter] = useRecoilState(mapCenterSelector(mapIdentifierSlug))

	const { getParams, setParams } = useMapSearchParams()

	useEffect(() => {
		const localStorageLat = Number(localStorage.getItem(LocalStorageKeys.lat))
		const localStorageLon = Number(localStorage.getItem(LocalStorageKeys.lon))
		const localStorageZoom = Number(localStorage.getItem(LocalStorageKeys.zoom))

		if (!isNaN(localStorageLat) || !isNaN(localStorageLon) || !isNaN(localStorageZoom)) {
			if (!isNaN(localStorageLat) && !isNaN(localStorageLon))
				setCenter({ lat: localStorageLat, lon: localStorageLon })
			if (!isNaN(localStorageZoom)) setZoomLevel(localStorageZoom)
		} else {
			const { lat, lon, zoom } = getParams()
			if ((!isNaN(lat) && !isNaN(lon)) || (!localStorageLat && !localStorageLon)) setCenter({ lat, lon })
			if (!isNaN(zoom)) setZoomLevel(zoom)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!center || !zoomLevel) return

		setParams({ lat: center.lat, lon: center.lon, zoom: zoomLevel })
	}, [center, setParams, zoomLevel])

	return null
}
