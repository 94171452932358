import { BaseApi } from '../BaseApi'
import { MooringPlanDto } from './dtos/MooringPlanDto'

export default class MooringPlanApi extends BaseApi {
  /**
   * @param mooringPlanId - id of the mooring plan to fetch
   * @returns the requested mooring plan
   */
  async getMooringPlan(mooringPlanId: string, abortSignal?: AbortSignal) {
    return await this.get<MooringPlanDto>(`mooringplan/${mooringPlanId}`, {
      signal: abortSignal,
    })
  }
}
