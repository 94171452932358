export const TENANTS_APP_DOMAIN = 'port'
export const TENANTS_APP_REF = 'port'
export const PATH_TENANTS_BASE = '/companies/:tenantRef'
export const PATH_TENANTS_MATCH_PARAM = 'tenantRef'

export enum PortContextTypes {
  Internal = 'INTERNAL',
  Agent = 'AGENT',
  TerminalOperator = 'TERMINAL_OPERATOR',
}
