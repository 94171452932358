import { Add as AddIcon, Remove as RemoveIcon } from '@griegconnect/krakentools-react-icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTheme } from '@mui/material/styles'
import { ButtonGroup, useMediaQuery } from '@mui/material'
import MapButton from '../shared/MapButton'
import { MapButtonPanelRegion } from '../shared/MapButtonPanel'
import { useMapContext } from '../MapContext'
import { followedVesselSelector } from '../atoms/liveViewAtoms'
import { mapZoomLevelSelector } from '../atoms/mapConfigAtoms'
import MapButtonDivider from '../shared/MapButtonDivider'
import { CONSTANTS } from '../constants'
import { MapTranslucentPaper } from '../shared/MapTranslucentPaper'

type ZoomControlProp = {
  bottom?: number
  orientation?: 'vertical' | 'horizontal'
  region?: MapButtonPanelRegion
  align?: string
}

export const ZoomControl = ({ bottom = 24, orientation = 'vertical', align = 'start', region }: ZoomControlProp) => {
  const { kmapInstance: instance } = useMapContext()
  const theme = useTheme()
  const isMobilOrTablet = useMediaQuery(theme.breakpoints.down('xl'))
  const followedVessel = useRecoilValue(followedVesselSelector(instance.mapElementId))
  const [zoomLevel, setZoomLevel] = useRecoilState(mapZoomLevelSelector(instance.mapElementId))

  const onZoomInHandler = () => {
    setZoomLevel((prev) => prev + 1)
  }

  const onZoomOutHandler = () => {
    setZoomLevel((prev) => prev - 1)
  }
  const tooltipDirection =
    region === 'left' ? 'right' : region === 'right' ? 'left' : region === 'bottom' ? 'top' : 'bottom'
  return followedVessel === null || !isMobilOrTablet ? (
    <ButtonGroup orientation={orientation} component={MapTranslucentPaper} elevation={24}>
      <MapButton
        iconButtonOnly
        onClick={onZoomInHandler}
        icon={<AddIcon />}
        disabled={zoomLevel >= CONSTANTS.MAX_ZOOM}
        toolTipText="Zoom in"
        toolTipPlacement={tooltipDirection}
        keepFocus
      />
      <MapButtonDivider orientation={orientation} />
      <MapButton
        iconButtonOnly
        onClick={onZoomOutHandler}
        icon={<RemoveIcon />}
        disabled={zoomLevel <= CONSTANTS.MIN_ZOOM}
        toolTipText="Zoom out"
        toolTipPlacement={tooltipDirection}
        keepFocus
      />
    </ButtonGroup>
  ) : null
}

export default ZoomControl
