import { MooringPlanMap } from '../../../modules/mooring-plan-map/MooringPlanMap'
import { useLocalStorageAuth } from '../LocalStorageAuthProvider'

export const MooringPlan = () => {
  const { accessToken, tenantId } = useLocalStorageAuth()

  if (!accessToken || !tenantId) return null

  return <MooringPlanMap tenantId={tenantId} accessToken={accessToken} />
}
