import { LayerControlCategory } from './LayerControlCategory'
import { useRecoilValue, useRecoilState } from 'recoil'
import List from '@mui/material/List'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useMapContext } from '../../MapContext'
import { assetLayersSelector } from '../../atoms/assetViewAtoms'
import { visibleLayersSelector } from '../../atoms/mapConfigAtoms'
import LayerListItem from '../../shared/LayerListItem'
import { Box, TextField } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(0.5),
  },
}))

const AssetLayerControl: React.FC<React.PropsWithChildren<{ startCollapsed?: boolean }>> = ({ startCollapsed }) => {
  const { mapIdentifierSlug: slug } = useMapContext()
  const assetLayers = useRecoilValue(assetLayersSelector(slug))
  const [visibleLayers, setVisibleLayers] = useRecoilState(visibleLayersSelector(slug))
  const [filter, setFilter] = useState('')
  const classes = useStyles()
  const [allVisible, setAllVisible] = useState(false)

  const handleToggleAll = () => {
    if (allVisible) {
      setVisibleLayers(visibleLayers.filter((l) => !assetLayers.map((al) => al.key).includes(l)))
      setAllVisible(false)
    } else {
      setVisibleLayers(
        assetLayers
          .filter((l) => !visibleLayers.includes(l.key))
          .map((l) => l.key)
          .concat(visibleLayers)
      )
      setAllVisible(true)
    }
  }

  useEffect(() => setAllVisible(assetLayers.filter((l) => !visibleLayers.includes(l.key)).length === 0), [
    visibleLayers,
    assetLayers,
  ])

  return (
    <LayerControlCategory
      title="Assets"
      startCollapsed={startCollapsed}
      secondaryAction={
        <IconButton className={classes.button} onClick={handleToggleAll} size="large">
          {allVisible ? <VisibilityOutlinedIcon color="primary" /> : <VisibilityOffOutlinedIcon color="disabled" />}
        </IconButton>
      }
    >
      <Box sx={{ marginLeft: 3, paddingRight: 8 }}>
        <TextField
          variant="filled"
          label="Filter"
          fullWidth
          size="small"
          value={filter}
          onChange={(evt) => {
            setFilter(evt.target.value)
          }}
        />
      </Box>
      <List component="div" disablePadding>
        {assetLayers
          .filter((l) => l.name.toLowerCase().includes(filter.toLowerCase()))
          .map((layer) => {
            return <LayerListItem key={layer.key} layer={layer} filterTerm={filter} />
          })}
      </List>
    </LayerControlCategory>
  )
}

export default AssetLayerControl
