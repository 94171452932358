import { Collection, Feature } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { EventsKey } from 'ol/events'
import OlFeature, { FeatureLike } from 'ol/Feature'
import { Point } from 'ol/geom'
import { Modify } from 'ol/interaction'
import { unByKey } from 'ol/Observable'
import { Fill, Icon, Stroke, Style } from 'ol/style'
import { FC, useCallback, useEffect, useMemo } from 'react'

import { colorPalette, KrakenMap, labelStyle } from '@griegconnect/krakentools-kmap'

import { useMapContext } from '../../MapContext'
import { useMeasuringContext } from '../../MeasuringContext'
import { fromExtent } from 'ol/geom/Polygon'

export type MeasureCoordinateDef = {
  type: 'coordinate'
  id: string
  point: Coordinate
}

type MeasureCoordinateProps = {
  point: MeasureCoordinateDef
  selected: boolean
  onChange: (line: MeasureCoordinateDef) => void
}

export const pinStyle = (cdnUrl: string, colorPalette: colorPalette) =>
  new Style({
    image: new Icon({
      crossOrigin: 'anonymous',
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      anchor: [0.5, 60],
      src: cdnUrl + '/' + colorPalette + '/icons/MapPin-Normal.png',
    }),
    zIndex: 3500,
  })

export const measureCoordinateStyleFn = (kmap: KrakenMap, selected: boolean = false) => {
  return (feature: FeatureLike, resolution: number) => {
    const style = labelStyle(kmap.getCurrentColorPalette())
    const pStyle = pinStyle(kmap.cdnUrl, kmap.getCurrentColorPalette())
    const geom = kmap.transformFeature(feature as OlFeature<Point>, 'EPSG:4326', 'EPSG:3857').geometry as GeoJSON.Point
    let text = pStyle.getText()
    if (!text) {
      const t = style.getText()?.clone()
      if (!t) throw new Error('No text style in labelStyle')
      t.setOffsetY(20)
      text = t
    }
    text.setText('Lat: ' + geom.coordinates[1].toFixed(6) + '\n' + 'Lng: ' + geom.coordinates[0].toFixed(6))
    pStyle.setText(text)
    pStyle.setZIndex(3500)
    const styles = [pStyle]

    if (selected) {
      const rectStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(24, 160, 251, 1)',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(24, 160, 251, 0.08)',
        }),
        zIndex: 3500,
        geometry: (feature) => {
          const geom = feature.getGeometry() as Point
          const coordinate = geom.getCoordinates()
          return fromExtent([
            coordinate[0] - (120 * resolution) / 2,
            coordinate[1] - (100 * resolution) / 2,
            coordinate[0] + (120 * resolution) / 2,
            coordinate[1] + (150 * resolution) / 2,
          ])
        },
      })
      styles.push(rectStyle)
    }

    return styles
  }
}

export const MeasureCoordinate: FC<MeasureCoordinateProps> = ({ point, selected, onChange }) => {
  const { measuringLayer } = useMeasuringContext()
  const { kmapInstance } = useMapContext()
  const styleFn = useCallback(measureCoordinateStyleFn(kmapInstance, selected), [selected])

  const measureCoordinateFeature = useMemo(() => {
    const feature = new Feature({
      geometry: new Point([]),
    })
    feature.set('type', 'measureCoordinate')
    feature.set('selectable', true)
    feature.set('measureId', point.id)
    feature.set('snappable', true)
    feature.set('zIndex', 3500)
    feature.setStyle(styleFn)
    measuringLayer.getSource()?.addFeature(feature)
    return feature
  }, [])

  const modifyInteraction = useMemo(() => {
    const modify = new Modify({
      features: new Collection([measureCoordinateFeature]),
      insertVertexCondition: () => false,
      deleteCondition: () => false,
      style: labelStyle(kmapInstance.getCurrentColorPalette()).clone(),
      pixelTolerance: 90,
      snapToPointer: false,
    })
    return modify
  }, [])

  const colorPaletteEventKey = useMemo(
    () => kmapInstance.on('colorPalette-changed', () => measureCoordinateFeature.setStyle(styleFn)),
    []
  )

  let changeEventKey: EventsKey | undefined
  let modifyEndEventKey: EventsKey | undefined

  const enableEdit = () => {
    kmapInstance.enableSnap(true)
    kmapInstance.addToSnapCollection(measureCoordinateFeature)
    kmapInstance.resetSnapCollection()
    measuringLayer.setZIndex(3000)
    modifyEndEventKey = modifyInteraction.on('modifyend', () => {
      const coords = measureCoordinateFeature.getGeometry()?.getCoordinates()
      if (coords) {
        onChange({ ...point, point: coords })
      }
    })
    kmapInstance.addInteraction(modifyInteraction)
  }

  const disableEdit = () => {
    kmapInstance.removeInteraction(modifyInteraction)
    measuringLayer.setZIndex(100)
    if (changeEventKey) unByKey(changeEventKey)
    if (modifyEndEventKey) unByKey(modifyEndEventKey)
  }

  useEffect(() => {
    const coordinateGeometry = measureCoordinateFeature.getGeometry() as Point
    if (point && coordinateGeometry) {
      coordinateGeometry.setCoordinates(point.point)
    }
  }, [point])

  useEffect(() => {
    return () => {
      disableEdit()
      measuringLayer.getSource()?.removeFeature(measureCoordinateFeature)
      kmapInstance.un(colorPaletteEventKey)
    }
  }, [])

  useEffect(() => {
    if (selected) {
      enableEdit()
    } else {
      disableEdit()
    }
    measureCoordinateFeature.setStyle(styleFn)
  }, [selected, modifyInteraction])

  return null
}

export default MeasureCoordinate
