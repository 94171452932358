import { useAuth } from '@griegconnect/krakentools-react-auth'
import {
  CarouselElement,
  GriegOnboardingProps,
  GriegOnboardingProvider,
  signUpFieldNames,
  SignUpFormState,
} from '@griegconnect/krakentools-react-onboarding'
import React from 'react'
import { useUsersApi } from '../hooks'
import { httpErrorsAsString } from '../utils/krakenHttpProblem'

export type OnboardingWrapperProps = {
  children: React.ReactNode
  carouselElements?: CarouselElement[]
  termsOfServiceUrl?: string
  privacyPolicyUrl?: string
  disableCreateAccount?: boolean
  /**
   * This option should only be used if you need to override the defaults
   */
  signInMethods?: GriegOnboardingProps['signInMethods']
  smsDescription?: string
}

const signUpPath = '/sign-up'
const forceSignup = document.location.pathname === signUpPath
const searchParams = new URLSearchParams(document.location.search)
/** Used by external services/applications like the ISPS course, need to be included in REDIRECT_WHITELIST */
const redirectUrl = searchParams.get('redirectUrl')
const externalSignUp = forceSignup && redirectUrl !== null

export const OnboardingWrapper: React.FC<React.PropsWithChildren<OnboardingWrapperProps>> = ({
  children,
  carouselElements,
  termsOfServiceUrl,
  privacyPolicyUrl,
  disableCreateAccount,
  signInMethods,
  smsDescription,
}) => {
  const { loginWithRedirect, isAuthenticated, isInitializing } = useAuth()
  const usersApi = useUsersApi(false)

  const onLogin = () => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        appState: {
          targetUrl: redirectUrl
            ? redirectUrl
            : window.location.pathname + window.location.search + window.location.hash,
        },
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onLoginSMS = () => {
    return new Promise<void>((resolve) => {
      const redirectOptions = {
        appState: {
          targetUrl: redirectUrl
            ? redirectUrl
            : window.location.pathname + window.location.search + window.location.hash,
        },
        connection: 'sms',
      }
      loginWithRedirect(redirectOptions)
      resolve()
    })
  }

  const onSignUp = (formState: SignUpFormState) => {
    const returnToUrl = externalSignUp
      ? redirectUrl
      : `${window.location.origin}/?redirectUrl=${encodeURIComponent(redirectUrl ?? window.location.pathname)}`

    return new Promise<boolean>((resolve, reject) => {
      usersApi
        .signup(
          formState.email.value,
          formState.name.value,
          formState.password.value,
          formState.phone_number.value,
          returnToUrl
        )
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          let errorMessage = 'An unknown error occurred, please try again, or contact your administrator.'
          if (error?.response?.data) {
            const krakenError = httpErrorsAsString(error.response.data)
            if (krakenError) {
              errorMessage = krakenError
            }
          }
          reject(errorMessage)
        })
    })
  }

  if (isAuthenticated || !carouselElements) {
    return <>{children}</>
  }

  if (isInitializing && carouselElements) {
    return null
  }

  const smsSiginEnabled = signInMethods?.includes('sms') ? 'sms' : false
  const defaultSignInEnabled =
    signInMethods === undefined || signInMethods.length === 0 || signInMethods?.includes('username-password')
      ? true
      : false

  return (
    <GriegOnboardingProvider
      carouselElements={carouselElements}
      termsOfServiceUrl={termsOfServiceUrl ?? 'https://krakentools.com/latest-kraken-app-terms-of-service/'}
      privacyPolicyUrl={privacyPolicyUrl ?? 'https://krakentools.com/latest-kraken-app-privacy-policy/'}
      signUpFields={[
        signUpFieldNames.EMAIL,
        signUpFieldNames.NAME,
        signUpFieldNames.PASSWORD,
        signUpFieldNames.PASSWORD_REPEAT,
        signUpFieldNames.PHONE_NUMBER,
      ]}
      onLogin={defaultSignInEnabled ? onLogin : undefined}
      onSmsLogin={smsSiginEnabled ? onLoginSMS : undefined}
      onSignUp={onSignUp}
      smsDescription={smsDescription}
      onClose={
        externalSignUp
          ? () => {
              document.location.href = redirectUrl
            }
          : undefined
      }
      initVisible={true}
      disableCreateAccount={disableCreateAccount}
      forceSignup={forceSignup}
    >
      {children}
    </GriegOnboardingProvider>
  )
}

export default OnboardingWrapper
