import { Page } from '@griegconnect/krakentools-react-ui'
import { AppLoader, supportedLanguages, LanguageTag } from '@griegconnect/krakentools-react-ui'
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { InviteeInfo } from '@griegconnect/krakentools-react-tenants'
import { useAuth } from '@griegconnect/krakentools-react-auth'
import { useInviteApi } from '../hooks/useInviteApi'
import { useApplicationOptions } from '../ApplicationOptionsContext'
import { z } from 'zod'
import countries, { Country } from 'country-list-with-dial-code-and-flag'
import { httpErrorsAsString } from '../utils/krakenHttpProblem'
import axios from 'axios'
import { useAlerts } from '../hooks'

export const schema = z
  .object({
    fullName: z.string({ description: 'Full name' }).min(3),
    password: z.string().min(8),
    confirmPassword: z.string(),
    dialCode: z.string().optional(),
    mobile: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password, dialCode, mobile }, ctx) => {
    const hasLowerCaseRule = /[a-z]/
    const hasUpperCaseRule = /[A-Z]/
    const hasNumberRule = /[0-9]/

    // Password policy need to follow Auth0 rules
    if (!hasLowerCaseRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one lower case letter (a-z)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (!hasUpperCaseRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one upper case letter (A-Z)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (!hasNumberRule.test(password)) {
      ctx.addIssue({
        message: 'Password must contain at least one number (0-9)',
        code: 'custom',
        path: ['password'],
      })
    }
    if (password !== confirmPassword) {
      ctx.addIssue({
        message: 'Password and confirm password need to be equal',
        code: 'custom',
        path: ['confirmPassword'],
      })
    }
    if (dialCode && dialCode.length > 0 && mobile && mobile.length !== 1 && mobile.length < 4) {
      ctx.addIssue({
        message: 'Please enter an valid mobile number (must contain at least 5 numbers)',
        code: 'custom',
        path: ['mobile'],
      })
    }
  })

type FormData = z.infer<typeof schema>

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

type ConfirmInvitationRedirectType = 'login' | 'external'

export type ConfirmInvitationProps = {
  redirectUrl?: string
  redirectType?: ConfirmInvitationRedirectType
  confirmBtnText?: string
}

const ConfirmInvitation = (props: ConfirmInvitationProps) => {
  const { i18n } = useApplicationOptions()
  const [inviteeInfo, setInviteeInfo] = useState<InviteeInfo>()
  const [data, setData] = useState<FormData>({
    fullName: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState<z.ZodFormattedError<FormData>>()
  const [token, setToken] = useState<string>('')
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const [userLanguage, setUserLanguage] = useState<LanguageTag | undefined>(
    i18n?.languages ? i18n.languages[0] : undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { loginWithRedirect } = useAuth()
  const inviteApi = useInviteApi()
  const query = useQuery()
  const { enqueue } = useAlerts()

  useEffect(() => {
    ;(async () => {
      try {
        const paramToken = query.get('token')
        const inviteData = await inviteApi.invitation(paramToken!)
        const emailParam = query.get('email')!
        setToken(paramToken!)
        setInviteeInfo(inviteData)
        setData((c) => ({
          ...c,
          fullName: inviteData.invitee.name.includes('@') ? '' : inviteData.invitee.name,
        }))
        setIsLoading(false)
      } catch (error) {
        console.error(`Expired token`, error)
        setIsExpired(true)
      }
      setIsLoading(false)
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = async () => {
    const validationResult = schema.safeParse(data)
    setErrors(undefined)

    if (validationResult.success) {
      try {
        const mobile = data.dialCode && data.mobile ? `${data.dialCode}${data.mobile}` : undefined
        const completeInvitationRes = await inviteApi.updateInvitation(
          token,
          data.fullName,
          data.password,
          mobile,
          userLanguage ? { locale: userLanguage } : undefined
        )
        const redirectUrl = props.redirectUrl ? props.redirectUrl : query.get('redirectUrl')
        if (props.redirectType && props.redirectType === 'external') {
          document.location.href = redirectUrl ? redirectUrl : '/'
        } else {
          const redirectOptions = {
            appState: {
              targetUrl: redirectUrl ? redirectUrl : '/',
            },
          }
          await loginWithRedirect(redirectOptions)
        }
      } catch (error) {
        let errorMessage = 'Unknown error, please contact your administrator or support.'
        if (axios.isAxiosError(error)) {
          const serverError = error.response ? httpErrorsAsString(error.response.data) : undefined
          errorMessage =
            serverError && error.response
              ? `Server error: ${serverError} (${error.response.status})`
              : `Unknown server error: ${error.message}, please contact your administrator or support.`
        }
        enqueue(errorMessage, 'error')
      }
    } else {
      const formatted = validationResult.error.format()
      setErrors(formatted)
    }
  }

  return isLoading ? (
    <div id="apploader">
      <AppLoader />
    </div>
  ) : isExpired ? (
    <Page.Wrapper maxWidth="md" title="Token not valid">
      <Box my={2}>
        <Typography gutterBottom>The invitation has expired or an error occurred.</Typography>
        <Typography gutterBottom>
          You may have completed the invitation confirmation earlier, if this is the case you can go to the main page,
          and sign in. If you have problems signing in, please contact your administrator.
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        href="#"
        sx={{ mr: 2 }}
        onClick={(e) => {
          e.preventDefault()
          window.location.reload()
        }}
      >
        Reload page
      </Button>
      <Button variant="outlined" color="primary" href="/">
        Go to main page
      </Button>
    </Page.Wrapper>
  ) : (
    <Page.Wrapper maxWidth="sm" title={`Welcome!`}>
      <Box mt={2}>
        <Typography>
          {inviteeInfo && `You have been invited by ${inviteeInfo.inviter.name}.`} Complete registration by filling in
          the form.
        </Typography>
      </Box>
      <Box mt={2}>
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Full name"
            placeholder="Ola Nordmann"
            value={data.fullName}
            onChange={(e) => setData((c) => ({ ...c, fullName: e.target.value }))}
            helperText={errors && errors.fullName?._errors ? errors.fullName?._errors.join('. ') : undefined}
            error={errors?.fullName?._errors ? true : false}
          />
          {/* <Stack direction="row" alignItems="stretch" spacing={2}> */}
          <CountrySelect
            value={data.dialCode}
            onChange={(_e, value) => setData((c) => ({ ...c, dialCode: value ? value.dialCode : undefined }))}
          />
          <TextField
            label="Phone number"
            variant="filled"
            type="tel"
            sx={{ display: 'flex' }}
            fullWidth
            value={data.mobile ?? ''}
            onChange={(e) => setData((c) => ({ ...c, mobile: e.target.value.replace(/[^0-9]/g, '') }))}
            helperText={errors && errors.mobile?._errors ? errors.mobile?._errors.join('. ') : undefined}
            error={errors?.mobile?._errors ? true : false}
            disabled={data.dialCode === undefined}
          />
          {/* </Stack> */}
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Password"
            type="password"
            value={data.password}
            onChange={(e) => setData((c) => ({ ...c, password: e.target.value }))}
            helperText={
              errors && errors.password?._errors
                ? errors.password?._errors.map((error, index) => (
                    <span style={{ display: 'inline-block' }} key={index + '-' + error}>
                      {error}
                    </span>
                  ))
                : undefined
            }
            error={errors?.password?._errors ? true : false}
          />
          <TextField
            variant="filled"
            required
            fullWidth={true}
            label="Confirm password"
            type="password"
            value={data.confirmPassword}
            onChange={(e) => setData((c) => ({ ...c, confirmPassword: e.target.value }))}
            helperText={
              errors && errors.confirmPassword?._errors ? errors.confirmPassword?._errors.join('. ') : undefined
            }
            error={errors?.confirmPassword?._errors ? true : false}
          />
          {i18n && (
            <FormControl required fullWidth variant="filled">
              <InputLabel id="select-language-label">Language</InputLabel>
              <Select
                labelId="select-language-label"
                id="select-language"
                label="Language"
                required
                value={userLanguage}
                onChange={(e) => setUserLanguage(e.target.value as LanguageTag)}
              >
                {i18n.languages &&
                  i18n.languages.map((lang) => (
                    <MenuItem value={lang} key={lang}>
                      {supportedLanguages[lang]}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                If the selected language is not supported by an application, english will be used.
              </FormHelperText>
            </FormControl>
          )}
        </Stack>
        {errors && (
          <Box my={2}>
            <FormHelperText error={true}>Form contains errors, please fix errors before you proceed.</FormHelperText>
          </Box>
        )}
        <Box mt={1}>
          <Button variant="contained" color="primary" onClick={submitHandler}>
            {props.confirmBtnText ? props.confirmBtnText : 'Save and sign in'}
          </Button>
        </Box>
      </Box>
    </Page.Wrapper>
  )
}

type CountrySelectProps = {
  value?: string
  onChange: (event: React.SyntheticEvent<Element, Event>, value: Country | null) => void
}
const CountrySelect = (props: CountrySelectProps) => {
  return (
    <Autocomplete
      fullWidth
      options={countries.getAll()}
      value={props.value ? countries.findOneByCountryCode(props.value) : undefined}
      onChange={props.onChange}
      isOptionEqualToValue={(option, value) => option.dialCode === value.dialCode}
      autoHighlight
      getOptionLabel={(option) => `${option.dialCode} (${option.name})`}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name} ({option.dialCode})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          label="Select country code"
          variant="filled"
        />
      )}
    />
  )
}

export default ConfirmInvitation
