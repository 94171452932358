import React, { useEffect, useMemo, useState } from 'react'

import { MoreVertical } from '@griegconnect/krakentools-react-icons'
import { ButtonProps, IconButton, Menu, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { PopperMenuItem } from './PopperMenuItem'
import { PopperMenuItemLoading } from './PopperMenuItemLoading'
import { PopperMenuNoItems, PopperMenuNoItemsProps } from './PopperMenuNoItems'

export type PopperMenuProps = {
  sections: MenuSectionProps[]
  onOpened?: () => void
  toggleButtonTestId?: string
  disabled?: boolean
} & Partial<ButtonProps> &
  Pick<PopperMenuNoItemsProps, 'noActionsLabel'>

export type MenuSectionProps = {
  items: MenuItemProps[]
}

export type GroupedMenuItems = Record<string, (MenuItemProps & { sort: string })[]>

export const MenuLoadingContent: MenuSectionProps[] = [{ items: ['LoadingPlaceholder'] }]

export type MenuItemProps =
  | 'LoadingPlaceholder'
  | {
      icon?: React.ReactNode
      label: string
      onClick: () => void
      testid?: string
    }

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    // borderTop: "1px solid",
    // borderTopColor: theme.palette.divider,
    // Using marginTop+boxShadow to get a divider with extra space, compared to just using borderTop
    marginTop: '9px',
    boxShadow: `0 -4px ${theme.palette.background.paper}, 0 -5px ${theme.palette.divider}`,
  },
  invisible: {
    opacity: `${0} !important`,
  },
}))

export const PopperMenu: React.FC<React.PropsWithChildren<PopperMenuProps>> = ({
  sections,
  onOpened,
  toggleButtonTestId,
  disabled,
  noActionsLabel,
  ...other
}) => {
  const classes = useStyles()
  const [anchorElPopper, setAnchorElPopper] = useState<null | HTMLElement>(null)
  const [isTriggeredOnOpened, setIsTriggeredOnOpened] = useState(false)

  const isOpen = !!anchorElPopper

  useEffect(() => {
    const isTrigger = isOpen && !isTriggeredOnOpened && !!onOpened
    if (isOpen) {
      if (isTrigger) {
        onOpened()
        setIsTriggeredOnOpened(true)
      }
    } else {
      setIsTriggeredOnOpened(false)
    }
  }, [anchorElPopper, isOpen, isTriggeredOnOpened, onOpened])

  const handleCloseMenu = () => setAnchorElPopper(null)

  const menuItems = useMemo(() => {
    if (sections.length === 0) {
      return <PopperMenuNoItems noActionsLabel={noActionsLabel} />
    }

    let index = 0

    let isFirstItem = true
    return sections.flatMap((section) => {
      let isNewSection = true
      return section.items.map((item) => {
        const itemKey = `item-${index++}`
        const showDivider = isNewSection && !isFirstItem
        const menuItemClassName = showDivider ? classes.divider : undefined
        isNewSection = false
        isFirstItem = false

        if (item === 'LoadingPlaceholder') {
          return <PopperMenuItemLoading key={itemKey} className={menuItemClassName} />
        }

        const { icon, label, onClick, testid } = item
        const handleClickedMenuItem = () => {
          handleCloseMenu()
          onClick()
        }

        return (
          <PopperMenuItem
            label={label}
            icon={icon}
            onClick={handleClickedMenuItem}
            className={menuItemClassName}
            key={itemKey}
            testid={testid}
            disabled={disabled}
          />
        )
      })
    })
  }, [sections, classes.divider, disabled])

  return (
    <>
      <IconButton
        {...other}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorElPopper(e.currentTarget)
        }}
        data-test-id={toggleButtonTestId}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        anchorEl={anchorElPopper}
        open={Boolean(anchorElPopper)}
        onClose={(e) => {
          const event = e as { preventDefault: () => void; stopPropagation: () => void }
          event.preventDefault()
          event.stopPropagation()
          handleCloseMenu()
        }}
        BackdropProps={{ invisible: true, classes: { invisible: classes.invisible } }}
        data-testid="menu"
      >
        {menuItems}
      </Menu>
    </>
  )
}
