import { Compass } from '@griegconnect/krakentools-react-icons'
import { Box } from '@mui/material'
import { createRef, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useMapContext } from '../MapContext'
import { mapRotationSelector } from '../atoms/mapConfigAtoms'
import MapButton from '../shared/MapButton'
import { MapButtonPanelRegion } from '../shared/MapButtonPanel'

type RotateControlProps = {
  bottom?: number
  region?: MapButtonPanelRegion
  align?: string
}

export const RotateControl = ({ bottom = 24, align = 'start' }: RotateControlProps) => {
  const { kmapInstance, mapIdentifierSlug } = useMapContext()
  const ref = createRef<SVGSVGElement>()
  const rotation = useRecoilValue(mapRotationSelector(mapIdentifierSlug))
  const resetRotationHandler = () => {
    kmapInstance.getView().animate({ rotation: 0 })
  }

  useEffect(() => {
    const key = kmapInstance.on('rotate', (rad: number) => {
      if (ref.current) {
        ref.current.style.transform = `rotate(${(rad * 180) / Math.PI}deg)`
      }
    })
    return () => {
      kmapInstance.un(key)
    }
  }, [ref])

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `rotate(${rotation}deg)`
    }
  }, [])

  return (
    <MapButton
      isActive={false}
      toolTipText="Hold shift while dragging the map to rotate"
      toolTipPlacement="left"
      icon={
        <Box style={{ height: 24, width: 24, fontSize: '1.5rem' }} ref={ref}>
          <Compass sx={{ height: 32, width: 32, marginTop: '-4px', marginLeft: '-4px' }} />
        </Box>
      }
      onClick={resetRotationHandler}
    />
  )
}
